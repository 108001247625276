<template>
  <div class="debutPageEffort">
    <label>
      {{ $t("partner") }}
      <b-icon
        id="info-member-icon"
        icon="info-circle"
        variant="info"
        class="icon"
      ></b-icon>
    </label>
    <b-form-group>
      <multiselect
        v-model="form.memberSelected"
        :options="Membres"
        :placeholder="$t('select-choice')"
        track-by="value"
        :label="titleSelection"
        :show-labels="false"
        :searchable="false"
      >
        <template slot="selection" slot-scope="{ values, isOpen }"
          ><span class="multiselect__single" v-if="values.length && !isOpen"
            >{{ values.length }} partenaire(s) sélectionnée(s)</span
          ></template
        >
      </multiselect>
      <b-tooltip
        triggers="click"
        placement="top"
        ref="tooltipMMSI"
        target="info-member-icon"
      >
        {{ $t("tooltipPartner") }}
      </b-tooltip>
      <div class="error" v-if="!$v.form.memberSelected.required">
        {{ $t("fields-required") }}
      </div>
    </b-form-group>
    <label>
      {{ $t("plateforme") }}
      <b-icon
        id="info-plateform-icon"
        icon="info-circle"
        variant="info"
        class="icon"
      ></b-icon>
    </label>
    <b-form-group>
      <b-form-select
        id="input-plateform"
        v-model="form.plateformSelected"
        :options="Plateforme"
        :text-field="textSelection"
        class="mr-3 b-components-size"
      >
      </b-form-select>
      <div class="error" v-if="!$v.form.plateformSelected.required">
        {{ $t("field-required") }}
      </div>
    </b-form-group>
    <b-tooltip
      triggers="click"
      ref="tooltipPlateforme"
      target="info-plateform-icon"
    >
      {{ $t("tooltipPlateforme") }}
    </b-tooltip>
    <label
      v-if="
        isMember &&
        (form.plateformSelected == 'bateau-peche' ||
          form.plateformSelected == 'croisiere-baleine' ||
          form.plateformSelected == 'naviere-commercial' ||
          form.plateformSelected == 'traversier' ||
          form.plateformSelected == 'ope-maritime' ||
          form.plateformSelected == 'croisiere-internationale' ||
          form.plateformSelected == 'croisiere-expedition')
      "
    >
      {{ $t("ship") }}
      <b-icon
        id="info-ship-icon"
        icon="info-circle"
        variant="info"
        class="icon"
      ></b-icon>
    </label>
    <b-form-group
      v-if="
        isMember &&
        (form.plateformSelected == 'bateau-peche' ||
          form.plateformSelected == 'croisiere-baleine' ||
          form.plateformSelected == 'naviere-commercial' ||
          form.plateformSelected == 'traversier' ||
          form.plateformSelected == 'ope-maritime' ||
          form.plateformSelected == 'croisiere-internationale' ||
          form.plateformSelected == 'croisiere-expedition')
      "
    >
      <b-form-input
        id="input-ship"
        v-model="form.ship"
        class="mr-3 b-components-size"
      >
      </b-form-input>
      <b-tooltip
        triggers="click"
        placement="top"
        ref="tooltipMMSI"
        target="info-ship-icon"
      >
        {{ $t("tooltipShip") }}
      </b-tooltip>
      <div class="error" v-if="!$v.form.ship.requiredIf">
        {{ $t("fields-required") }}
      </div>
      <div class="error" v-if="!$v.form.ship.NameWithSpace">
        {{ $t("ship-error") }}
      </div>
    </b-form-group>
    <label v-if="isMember && form.plateformSelected == 'naviere-commercial'">
      {{ $t("mmsi") }}
      <b-icon
        id="info-mmsi-icon"
        icon="info-circle"
        variant="info"
        class="icon"
      ></b-icon>
    </label>
    <b-form-group
      v-if="isMember && form.plateformSelected == 'naviere-commercial'"
    >
      <b-form-input
        id="input-mmsi"
        v-model="form.mmsi"
        class="mr-3 b-components-size"
      >
      </b-form-input>
      <b-tooltip
        triggers="click"
        placement="top"
        ref="tooltipMMSI"
        target="info-mmsi-icon"
      >
        {{ $t("tooltipMMSI") }}
      </b-tooltip>
    </b-form-group>
    <label v-if="isMember && form.plateformSelected == 'croisiere-baleine'">
      {{ $t("nb_passengers") }}
      <b-icon
        id="info-nb-passengers-icon"
        icon="info-circle"
        variant="info"
        class="icon"
      ></b-icon>
    </label>
    <b-form-group
      v-if="isMember && form.plateformSelected == 'croisiere-baleine'"
    >
      <b-form-input
        type="number"
        min="0"
        step="1"
        @keydown="filterKey"
        id="input-nb-passengers"
        class="mr-3 b-components-size"
        required
        v-model="form.nbPassengers"
      ></b-form-input>
      <b-tooltip
        triggers="click"
        placement="top"
        ref="tooltipNbPassengers"
        target="info-nb-passengers-icon"
      >
        {{ $t("tooltipNbPassengers") }}
      </b-tooltip>
      <!-- <div class="error" v-if="!$v.form.nbPassengers.requiredIf">
            {{ $t("nbPassengersnotnull") }}
          </div> -->
      <div class="error" v-if="$v.form.nbPassengers.$invalid">
        {{ $t("nbIndividu-error") }}
      </div>
      <div class="error" v-if="!$v.form.nbPassengers.minValue">
        {{ $t("nbPassengersvalue-error") }}
      </div>
    </b-form-group>
    <label>
      {{ $t("initial") }}
      <b-icon
        id="info-initial-icon"
        icon="info-circle"
        variant="info"
        class="icon"
      ></b-icon>
    </label>
    <b-form-group>
      <b-form-input
        id="input-initial"
        v-model="form.initial"
        class="mr-3 b-components-size"
      >
      </b-form-input>
      <b-tooltip
        triggers="click"
        placement="top"
        ref="tooltipInitial"
        target="info-initial-icon"
      >
        {{ $t("tooltipInitial") }}
      </b-tooltip>
      <div class="error" v-if="!$v.form.initial.required">
        {{ $t("fields-required") }}
      </div>
    </b-form-group>
    <TimeFormFields
      @dateUTC-changed="
        (newDate) => {
          this.dateUTC = newDate;
        }
      "
    />
    <label>
      {{ $t("visibility") }}
      <b-icon
        id="info-visibility-icon"
        icon="info-circle"
        variant="info"
        class="icon"
      ></b-icon>
    </label>
    <b-form-group>
      <b-form-select
        id="input-visibility"
        v-model="form.visibility"
        :options="visibiliteOptions"
        :text-field="textSelection"
        class="mr-3 b-components-size"
      ></b-form-select>
      <div class="error" v-if="!$v.form.visibility.required">
        {{ $t("field-required") }}
      </div>
    </b-form-group>
    <b-tooltip
      triggers="click"
      ref="tooltipVisibility"
      target="info-visibility-icon"
    >
      {{ $t("tooltipVisibility") }}
    </b-tooltip>
    <label>
      {{ $t("wind") }}
      <b-icon
        id="info-wind-icon"
        icon="info-circle"
        variant="info"
        class="icon"
      ></b-icon>
    </label>
    <b-form-group>
      <b-form-select
        id="input-wind"
        v-model="form.wind"
        :options="ventOptions"
        :text-field="textSelection"
        class="mr-3 b-components-size"
      ></b-form-select>
      <div class="error" v-if="!$v.form.wind.required">
        {{ $t("field-required") }}
      </div>
    </b-form-group>
    <b-tooltip triggers="click" ref="tooltipWind" target="info-wind-icon">
      {{ $t("tooltipWind") }}
    </b-tooltip>
    <LocationFormFields
      @location-changed="
        (newLatitude, newLongitude) => {
          this.form.latitude = newLatitude;
          this.form.longitude = newLongitude;
        }
      "
      :useOfflineMap="!$store.state.pwa.isOnline"
    />
    <b-button
      @click="debutEffort()"
      :disabled="$v.$invalid || dateUTC == 'Invalid date'"
      variant="primary"
      class="button-color"
      >{{ $t("send-observation") }}</b-button
    >
    <b-button @click="back()" class="mt-2 ml-2 mb-2 button-color">{{
      $t("cancel")
    }}</b-button>
  </div>
</template>

<script>
import { guid } from "@/utils/guidGenerator.js";

import {
  required,
  requiredIf,
  helpers,
  minValue,
  maxValue,
  numeric,
} from "vuelidate/lib/validators";
import store from "@/store";
import { Plateforme, Visibilite, Vent, Membres } from "@/constants";
import LocationFormFields from "@/components/LocationFormFields";
import TimeFormFields from "@/components/TimeFormFields";
import {
  getCurrentSession,
  startObservationSession,
} from "@/storage/session-management";
import { getCookie } from "tiny-cookie";

export default {
  name: "StartEffortForm",
  components: { LocationFormFields, TimeFormFields },
  data() {
    return {
      Membres: Membres,
      Plateforme: Plateforme,
      ventOptions: Vent,
      visibiliteOptions: Visibilite,
      dateUTC: null,
      form: {
        ship: "",
        mmsi: "",
        initial: "",
        latitude: "",
        longitude: "",
        memberSelected: null,
        plateformSelected: null,
        nbPassengers: null,
        visibility: null,
        wind: null,
      },
    };
  },
  async beforeRouteEnter(to, from, next) {
    const isUserLoggedData = Boolean(store.getters.ogslUserData);
    if (
      (isUserLoggedData || !store.state.pwa.isOnline) &&
      !(await getCurrentSession())
    ) {
      next();
    } else {
      next("/");
    }
  },
  mounted() {},
  validations() {
    return {
      form: {
        memberSelected: {
          required,
        },
        plateformSelected: {
          required,
        },
        nbPassengers: {
          // requiredIf: requiredIf(() => {
          //   return (this.form.plateformSelected == 'croisiere-baleine' &&
          //     (this.form.nbPassengers == null || this.form.nbPassengers == ""))
          // }),
          numeric,
          minValue: minValue(0),
        },
        ship: {
          requiredIf: requiredIf(() => {
            return (
              this.form.plateformSelected == "bateau-peche" ||
              this.form.plateformSelected == "naviere-commercial" ||
              this.form.plateformSelected == "traversier" ||
              this.form.plateformSelected == "ope-maritime" ||
              this.form.plateformSelected == "croisiere-internationale" ||
              this.form.plateformSelected == "croisiere-expedition"
            );
          }),
          NameWithSpace: helpers.regex("NameWithSpace", /^.*\S.*[a-zA-Z_ ]*$/i),
        },
        initial: {
          required,
        },
        latitude: {
          required,
          maxValue: maxValue(90),
          minValue: minValue(0),
        },
        longitude: {
          required,
          maxValue: maxValue(0),
          minValue: minValue(-180),
        },
        wind: {
          required,
        },
        visibility: {
          required,
        },
      },
    };
  },
  methods: {
    filterKey(e) {
      const key = e.key;
      if (key === "." || key === "," || key === "-" || key === "+") {
        return e.preventDefault();
      }
      if (key === "e" || e.keyCode === 13) {
        return e.preventDefault();
      }
    },
    back() {
      this.$router.push("/");
    },
    async debutEffort() {
      this.$store.commit("isSendObservationInProgress", true);
      const formattedDate = this.dateUTC;
      const generatedGuid = guid();

      let extraData = {};

      if (this.form.plateformSelected)
        extraData.plateform = this.form.plateformSelected;

      for (const fieldName of [
        "ship",
        "mmsi",
        "initial",
        "nbPassengers",
        "visibility",
        "wind",
      ]) {
        if (this.form[fieldName]) extraData[fieldName] = this.form[fieldName];
      }

      if (this.$store.getters.ogslUserData)
        extraData.userName = this.$store.getters.ogslUserData.name;
      extraData.effortclosed = false;

      if (!this.form.memberSelected)
        throw new Error("cannot-start-session-if-not-member");

      const start = {
        datasetCode: "ROMM-MM-OBS-WB",
        hubId: generatedGuid,
        location: {
          latitude: parseFloat(this.form.latitude),
          longitude: parseFloat(this.form.longitude),
          isFixed: false,
        },
        date: formattedDate,
        dateFormatCode: "minute",
        samplingProtocolCode: "visual",
        samplingEffort: "",
        dwcSamplingProtocol: "On the platform " + this.form.plateformSelected,
        extradata: {
          data: {},
          simplifiedGroups: [
            {
              groupCode: "romm_saisie",
            },
          ],
        },
        organizationCode: this.form.memberSelected.value,
      };

      if (this.form.nbPassengers != null && this.form.nbPassengers != "") {
        start.samplingEffort =
          "There are " + this.form.nbPassengers + " passengers";
      }

      start.extradata.data = JSON.stringify(extraData);
      try {
        await startObservationSession(start, getCookie("sessionId"));
      } catch (err) {
        await this.$router.push("/");
        this.$bvToast.toast(this.$t("errorEffortStart"), {
          title: this.$t(err.message),
          variant: "danger",
          solid: true,
        });
        return;
      } finally {
        this.$store.commit("isSendObservationInProgress", false);
      }

      await this.$router.push("/");
      this.$bvToast.toast(this.$t("startEffortSuccess"), {
        title: this.$t("success"),
        variant: "success",
        solid: true,
      });
    },
  },
  computed: {
    isMember() {
      return (
        this.$store.getters.ogslUserData || !this.$store.state.pwa.isOnline
      );
    },
    titleSelection() {
      return `name${this.$i18n.locale}`;
    },
    textSelection() {
      return `text${this.$i18n.locale}`;
    },
  },
};
</script>

<style scoped>
.debutPageEffort {
  flex: 1 1 auto;
  min-height: 1px;
  padding: 1.25rem;
  flex-grow: 1;
  flex-shrink: 0;
}

.b-components-size {
  width: 100%;
}

.icon {
  align-self: center;
}

.button-color {
  background-color: #2147af;
  border-color: #2147af;
}
.error {
  color: red;
}

span.multiselect__option {
  padding-left: 30px !important;
}

@media screen and (min-width: 1140px) {
  .debutPageEffort {
    width: 1140px;
    margin: 0 auto;
  }
}

@media screen and (max-width: 1139px) {
  .debutPageEffort {
    width: 100%;
  }
}

@media screen and (min-width: 768px) {
  .debutPageEffort {
    padding: 1.25rem;
  }
}

@media screen and (max-width: 767px) {
  .debutPageEffort {
    padding: 0.5rem !important;
  }
}
</style>
