<template>
  <div>
    <h1 style="text-align: center">
      Liste des observations en attente de publication
    </h1>
    <h2>Séances d'observation:</h2>
    <div v-if="sessionsWithThingsToPublish.length == 0">Pas de session</div>
    <div v-for="session in sessionsWithThingsToPublish" :key="session.id">
      <div>
        Début: {{ formatUTCDateToLocalTimezone(session.sessionObject.date) }}
        <span v-if="session.sessionObject.endDate">
          / Fin:
          {{
            formatUTCDateToLocalTimezone(session.sessionObject.endDate)
          }}</span
        >
        <b-button
          v-if="!$store.state.pwa.isOnline"
          variant="primary"
          disabled
          >{{ $t("network-connection-required") }}</b-button
        >
        <b-button
          v-else-if="session.sessionObject.endDate && !session.sessionObject.id"
          style="float: right"
          variant="primary"
          @click="handleClickPublishSession(session)"
          >{{ $t("publish-session") }}</b-button
        >
        <b-button
          v-else-if="!session.sessionObject.endDate"
          style="float: right"
          variant="primary"
          @click="$router.push('/fin-effort?offlineSessionId=' + session.id)"
          >{{ $t("end-effort") }}</b-button
        >
      </div>
      <ObservationsPendingObservationList
        :observations="
          unpublishedObservations.filter(
            (observation) =>
              observation.observationObject.offlineSessionId == session.id
          )
        "
        :showPublishButton="false"
      />
    </div>
    <h2>Observations ponctuelles:</h2>
    <ObservationsPendingObservationList
      :observations="singleObservations"
      :showPublishButton="true"
    />
    <div style="text-align: center; padding: 10px">
      <b-button variant="primary" @click="$router.push('/')"
        >Retourner à l'accueil
      </b-button>
    </div>
  </div>
</template>

<script>
import { db } from "@/storage/indexedDB";
import { liveQuery } from "dexie";
import {
  pushObservationToBiohub,
  publishNewObservationSession,
  getObservationSession,
} from "@/storage/slgoServices";
import { getCookie } from "tiny-cookie";
import ObservationsPendingObservationList from "@/components/ObservationsPendingObservationList";
import { formatUTCDateToLocalTimezone } from "@/utils/date";

export default {
  name: "ObservationsPending",
  components: { ObservationsPendingObservationList },
  data() {
    return { unpublishedObservations: [], sessions: [] };
  },
  async mounted() {
    liveQuery(() => db.observations.toArray()).subscribe({
      next: (result) => (this.unpublishedObservations = result),
      error: (error) => console.error(error),
    });
    liveQuery(() => db.sessions.toArray()).subscribe({
      next: (result) => (this.sessions = result),
      error: (error) => console.error(error),
    });
    this.removePublishedEmptySessions();
  },
  computed: {
    singleObservations: function () {
      return this.unpublishedObservations.filter(
        (observation) => !observation.observationObject.offlineSessionId
      );
    },
    sessionsWithThingsToPublish: function () {
      const out = [];
      for (const session of this.sessions) {
        if (
          session.sessionObject.id &&
          !this.unpublishedObservations.find(
            (observation) =>
              observation.observationObject.offlineSessionId == session.id
          )
        )
          continue;
        out.push(session);
      }
      return out;
    },
  },
  methods: {
    async removePublishedEmptySessions() {
      for (const session of this.sessions) {
        if (
          session.sessionObject.id &&
          session.sessionObject.endDate &&
          !this.unpublishedObservations.find(
            (observation) =>
              observation.observationObject.offlineSessionId == session.id
          )
        )
          await db.sessions.delete(session.id);
      }
    },
    async handleClickPublishSession(session) {
      let response = null;
      let onlineSessionId = null;
      try {
        response = await publishNewObservationSession(
          session.sessionObject,
          getCookie("sessionId")
        );
        onlineSessionId = response.data.id;
        session.sessionObject.id = onlineSessionId;
        await db.observations.update(session.id, {
          timestamp: Date.now(),
          sessionObject: session.sessionObject,
        });
      } catch (error) {
        if (error.response.status == 400) {
          response = await getObservationSession(session.sessionObject.hubId);
          onlineSessionId = response.id;
          session.sessionObject.id = onlineSessionId;
        }
      }

      const observations = this.unpublishedObservations.filter(
        (observation) =>
          observation.observationObject.offlineSessionId == session.id
      );
      if (observations.length == 0) {
        await db.sessions.delete(session.id);
      } else {
        for (const observation of observations) {
          observation.observationObject.parentEventId = onlineSessionId;
          await db.observations.update(observation.id, {
            timestamp: Date.now(),
            observationObject: observation.observationObject,
          });
        }
      }
    },
    formatUTCDateToLocalTimezone(dateUTC) {
      return formatUTCDateToLocalTimezone(dateUTC);
    },
    needToLoginToPublish(observation) {
      return (
        !this.$store.getters.ogslUserData &&
        observation.observationObject.whenWhere.memberSelected
      );
    },
    async handleClickPublishObservation(observation) {
      this.$store.commit("isSendObservationInProgress", true);

      observation.observationObject.offlineObservation = true;

      try {
        await pushObservationToBiohub(
          observation.observationObject.guid,
          observation.observationObject,
          this.$store.getters.userName,
          getCookie("sessionId")
        );
      } catch (error) {
        this.$bvToast.toast(this.$t("observationError"), {
          title: this.$t("error"),
          variant: "danger",
          solid: true,
        });
        return;
      } finally {
        this.$store.commit("isSendObservationInProgress", false);
      }
      await db.observations.delete(observation.id);
      this.$bvToast.toast(this.$t("observationSuccess"), {
        title: this.$t("success"),
        variant: "success",
        solid: true,
      });
    },
  },
  watch: {
    unpublishedObservations: async function () {
      this.removePublishedEmptySessions();
    },
  },
};
</script>

<style scoped>
</style>
