<template>
  <div>
    <label>
      {{ $t("add-photo") }}
      <b-icon
        id="info-photo-icon"
        icon="info-circle"
        variant="info"
        class="icon"
      ></b-icon>
    </label>
    <form>
      <b-row id="preview_photo">
        <b-col md="6">
          <div class="input-file-container">
            <input
              id="photo_sans_effort"
              class="input-file"
              method="post"
              type="file"
              @change="onFileChange"
              enctype="multipart/form-data"
              accept=".jpg, .jpeg, .png, .bmp"
            />
            <label
              tabindex="0"
              for="photo_sans_effort"
              class="input-file-trigger"
            >
              {{ $t("add-photo-dots") }}
            </label>
          </div>
          <div>
            <p v-html="$t('photo-desc-p1')"></p>
            <p v-html="$t('photo-desc-p2')"></p>
            <p v-html="$t('photo-desc-p3')"></p>
          </div>
          <div v-if="photo">
            <b-button
              id="btn_remove_image"
              variant="danger"
              @click="removeImage"
              ><b-icon-trash></b-icon-trash
            ></b-button>
            <div id="name_photo">{{ fileName }}</div>
          </div>
        </b-col>
        <b-col md="6" v-if="photo">
          <img :src="photo" />
        </b-col>
      </b-row>
    </form>
    <b-tooltip triggers="click" ref="tooltipPhoto" target="info-photo-icon">
      {{ $t("tooltipPhoto") }}
    </b-tooltip>
  </div>
</template>

<script>
export default {
  name: "ObservationFormObservationPhoto",
  components: {},
  props: {},
  data() {
    return {
      photo: null,
      photoFile: null,
    };
  },
  methods: {
    removeImage: function () {
      this.photo = "";
      this.photoFile = null;
      // BETTER: utiliser refs au lieu d un id
      document.getElementById("photo_sans_effort").value = "";
    },
    createImage(file) {
      const reader = new FileReader();

      reader.onload = (e) => {
        this.photo = e.target.result;
      };
      reader.readAsDataURL(file);
    },

    onFileChange(e) {
      const files = e.target.files || e.dataTransfer.files;
      if (!files.length) {
        this.photoFile = null;
        return;
      }

      this.photoFile = files[0];

      const ext = files[0].name.match(/\.(.+)$/)[1].toLowerCase();
      switch (ext) {
        case "jpg":
        case "jpeg":
        case "bmp":
        case "png":
          this.createImage(files[0]);
          break;
        default:
          this.form.photo = "";
          document.getElementById("photo_sans_effort").value = "";
          this.$bvToast.toast(this.$t("errorValidImage"), {
            title: this.$t("error"),
            variant: "danger",
            solid: true,
          });
      }
    },
  },
  computed: {
    fileName() {
      if (!this.photoFile) return null;

      return this.photoFile.name;
    },
  },
  watch: {
    photoFile(newValue) {
      this.$emit("photo-file-changed", newValue);
    },
    photo(newValue) {
      this.$emit("photo-data-changed", newValue);
    },
  },
};
</script>

<style scoped>
#species_desc_img,
#preview_photo {
  width: 100%;
}

#name_photo {
  display: inline-block;
  padding-left: 5px;
  padding-bottom: 10px;
}

#species_desc_img img,
#preview_photo img {
  width: 100%;
}

#preview_photo img {
  padding-bottom: 10px;
}

#preview_photo p,
#textEspeceFormation {
  margin-bottom: 10px !important;
}

.input-file {
  width: 0.1px;
  height: 0.1px;
  opacity: 0;
  overflow: hidden;
  position: absolute;
  z-index: -1;
}

.input-file-container {
  position: relative;
  width: 100%;
}

.input-file-trigger {
  display: block;
  padding: 14px 45px;
  background: #17a2b8;
  color: #fff;
  border: 1px solid #17a2b8;
  border-radius: 16px;
  font-size: 1em;
  transition: all 0.4s;
  cursor: pointer;
  text-align: center;
  outline: none;
}

.input-file {
  position: absolute;
  top: 0;
  left: 0;
  width: 225px;
  opacity: 0;
  padding: 14px 0;
  cursor: pointer;
}
.input-file:hover + .input-file-trigger,
.input-file:focus + .input-file-trigger,
.input-file-trigger:hover,
.input-file-trigger:focus {
  background: #fff;
  color: #17a2b8;
  border: 1px solid #17a2b8;
  border-radius: 16px;
}
</style>
