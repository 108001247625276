<template>
  <div class="content">
    <h1 class="big-title" style="text-align: center" v-html="$t('welcome-title')"></h1>
    <div class="introduction" style="float: right">
      <img src="../../public/assets/images/general/Vigie_marine_logo.png" alt="Vigie marine logo"/>
      <p v-html="$t('home-p2')"></p>
    </div>
    <EffortCourantSummary
      v-if="currentEffortData"
      :currentEffortData="currentEffortData"
    />
    <HomeButtonsContainer v-if="!ogslUserData">
      <template v-slot:column1>
        <div id="titreButtonChoice">
          {{ $t("co-btn") }}
        </div>
        <b-button
          id="buttonChoice"
          @click="$router.push('/nouvelle-observation')"
          variant="secondary"
          >{{ $t("submit-observation") }}</b-button
        ></template
      >
      <template v-slot:column2
        ><div id="titreButtonChoice">
          {{ $t("om-btn") }}
          <b-icon
            id="info-observer-member-icon"
            icon="info-circle"
            variant="info"
            class="icon"
          ></b-icon>
          <b-tooltip
            triggers="click"
            placement="top"
            ref="tooltipObserverMember"
            target="info-observer-member-icon"
          >
            {{ $t("tooltipObserverMember") }}
          </b-tooltip>
        </div>
        <b-button
          id="buttonChoice"
          class="button-color mb-2"
          @click="handleLoginClick"
          >{{ $t("log-in") }}</b-button
        ></template
      ></HomeButtonsContainer
    >
    <HomeButtonsContainer v-if="ogslUserData && !currentEffortData">
      <template v-slot:column1
        ><div id="titreButtonChoice">
          {{ $t("op-btn") }}
          <b-icon
            id="info-observer-one-time-icon"
            icon="info-circle"
            variant="info"
            class="icon"
          ></b-icon>
          <b-tooltip
            triggers="click"
            placement="top"
            ref="tooltipObserverOneTime"
            target="info-observer-one-time-icon"
          >
            {{ $t("tooltipObserverOneTime") }}
          </b-tooltip>
        </div>
        <b-button
          id="buttonChoice"
          @click="$router.push('/nouvelle-observation')"
          variant="primary"
          >{{ $t("submit-observation") }}</b-button
        ></template
      ><template v-slot:column2
        ><div id="titreButtonChoice">
          {{ $t("ds-btn") }}
          <b-icon
            id="info-start-session-icon"
            icon="info-circle"
            variant="info"
            class="icon"
          ></b-icon>
          <b-tooltip
            triggers="click"
            placement="top"
            ref="tooltipStartSession"
            target="info-start-session-icon"
          >
            {{ $t("tooltipStartSession") }}
          </b-tooltip>
        </div>
        <b-button
          v-if="!hasOpenUnpublishedSession"
          id="buttonChoice"
          class="mb-2"
          variant="danger"
          @click="$router.push('/debut-effort')"
          >{{ $t("start-effort") }}</b-button
        >
        <b-button
          v-else
          id="buttonChoice"
          class="mb-2"
          variant="danger"
          @click="$router.push('/debut-effort')"
          disabled
          >{{ $t("end-offline-session-before-new-session") }}</b-button
        ></template
      >
    </HomeButtonsContainer>
    <HomeButtonsContainer v-if="ogslUserData && currentEffortData">
      <template v-slot:column1
        ><span class="span-space bold">
          <div id="titreButtonChoice">
            {{ $t("so-btn") }}
            <b-icon
              id="info-observer-session-icon"
              icon="info-circle"
              variant="info"
              class="icon"
            ></b-icon>
            <b-tooltip
              triggers="click"
              placement="top"
              ref="tooltipObserverSession"
              target="info-observer-session-icon"
            >
              {{ $t("tooltipObserverSession") }}
            </b-tooltip>
          </div>
          <b-button
            id="buttonChoice"
            @click="$router.push('/nouvelle-observation')"
            variant="danger"
            >{{ $t("submit-observation-login") }}</b-button
          >
        </span></template
      ><template v-slot:column2
        ><div id="titreButtonChoice">
          {{ $t("ds-btn") }}
          <b-icon
            id="info-end-session-icon"
            icon="info-circle"
            variant="info"
            class="icon"
          ></b-icon>
          <b-tooltip
            triggers="click"
            placement="top"
            ref="tooltipEndSession"
            target="info-end-session-icon"
          >
            {{ $t("tooltipEndSession") }}
          </b-tooltip>
        </div>
        <b-button
          id="buttonChoice"
          class="mb-2"
          variant="danger"
          @click="$router.push('/fin-effort')"
          >{{ $t("end-effort") }}</b-button
        ></template
      >
    </HomeButtonsContainer>

    <div class="photos_observateurs_container">
      <b-row>
        <HomeContentVideoColumn
          v-if="!ogslUserData && this.$i18n.locale == 'fr'"
          url="https://player.vimeo.com/video/721206011?h=6d9f4e304f&title=0&byline=0&portrait=0"
        />
        <HomeContentVideoColumn
          v-if="!ogslUserData && this.$i18n.locale == 'en'"
          url="https://player.vimeo.com/video/724531414?h=4e311426d9&title=0&byline=0&portrait=0"
        />
        <b-col
          id="imgCopyright"
          sm="6"
          v-if="!ogslUserData && this.$i18n.locale == 'fr'"
        >
          <img :src="`./assets/images/general/Membre observateur.png`" />
        </b-col>
        <b-col
          id="imgCopyright"
          sm="6"
          v-if="!ogslUserData && this.$i18n.locale == 'en'"
        >
          <img :src="`./assets/images/general/Observer member.png`" />
        </b-col>
        <HomeContentVideoColumn
          v-if="ogslUserData && this.$i18n.locale == 'fr'"
          url="https://player.vimeo.com/video/721194263?h=f7a4e5f911&title=0&byline=0&portrait=0"
        />
        <HomeContentVideoColumn
          v-if="ogslUserData && this.$i18n.locale == 'fr'"
          url="https://player.vimeo.com/video/722719556?h=155a16024e&title=0&byline=0&portrait=0"
        />
        <HomeContentVideoColumn
          v-if="ogslUserData && this.$i18n.locale == 'en'"
          url="https://player.vimeo.com/video/724537707?h=601941bd4f&title=0&byline=0&portrait=0"
        />
        <HomeContentVideoColumn
          v-if="ogslUserData && this.$i18n.locale == 'en'"
          url="https://player.vimeo.com/video/724573285?h=e5868247d8&title=0&byline=0&portrait=0"
        />
      </b-row>
    </div>
    <div style="float: right">
      <p v-html="$t('home-p1')"></p>
      <p v-html="$t('home-p4')"></p>
      <p v-html="$t('home-p3')"></p>
    </div>
  </div>
</template>

<script>
import HomeButtonsContainer from "@/components/HomeButtonsContainer.vue";
import HomeContentVideoColumn from "@/components/HomeContentVideoColumn.vue";
import EffortCourantSummary from "@/components/EffortCourantSummary.vue";
import * as ogslAuth from "@/commons/ogsl-auth.js";
import { getCurrentSession } from "@/storage/session-management";
import { db } from "@/storage/indexedDB";
import { liveQuery } from "dexie";

// @ is an alias to /src
export default {
  name: "HomeContentOnline",
  components: {
    EffortCourantSummary,
    HomeButtonsContainer,
    HomeContentVideoColumn,
  },
  data() {
    return { currentEffortData: null, sessions: null };
  },
  methods: {
    handleLoginClick() {
      ogslAuth.openLoginWindow(this.$i18n.locale);
    },
    async updateCurrentEffortData() {
      this.currentEffortData = await getCurrentSession();
    },
  },
  computed: {
    ogslUserData() {
      return this.$store.getters.ogslUserData;
    },
    hasOpenUnpublishedSession() {
      if (!this.sessions) return false;
      return (
        this.sessions.filter(
          (session) =>
            !session.sessionObject.id && !session.sessionObject.endDate
        ).length > 0
      );
    },
  },
  async mounted() {
    liveQuery(() => db.sessions.toArray()).subscribe({
      next: (result) => (this.sessions = result),
      error: (error) => console.error(error),
    });
    this.updateCurrentEffortData();
  },

  watch: {
    ogslUserData: function () {
      this.updateCurrentEffortData();
    },
  },
};
</script>

<style scoped>
.content {
  margin: 0 auto;
  flex-grow: 1;
  flex-shrink: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 1140px;
  padding: 1.25rem;
  justify-content: space-evenly;
}
.introduction {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.introduction img {
  height: 180px;
}
.buttons-container,
.photos_observateurs_container {
  display: flex;
  flex-direction: column;
  width: 100%;
  text-align: center;
}
.span-space {
  margin-top: 10px;
  font-size: 13pt;
}
.span-space .btn {
  font-size: 13pt;
}
.button-color {
  background-color: #2147af;
  border-color: #2147af;
}
.button-color:hover {
  background-color: #9ba8b0;
  border-color: #9ba8b0;
  color: #fff !important;
}
.bold {
  font-weight: bold;
}
.photos_observateurs_container img {
  width: 100%;
}
#copyright_photo {
  font-size: 10pt;
  font-style: italic;
  padding-bottom: 10px;
}
@media screen and (max-width: 768px) {
  .photos_observateurs_container {
    width: 100%;
  }
}

#titreButtonChoice,
#buttonChoice,
#imgCopyright {
  margin-bottom: 20px;
}
</style>
