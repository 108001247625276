<template>
  <div class="buttons-container">
    <b-row>
      <b-col id="imgCopyright" sm="6">
        <span class="span-space bold">
          <slot name="column1"></slot>
        </span>
      </b-col>
      <b-col id="imgCopyright" sm="6">
        <span class="span-space bold">
          <slot name="column2"></slot>
        </span>
      </b-col>
    </b-row>
  </div>
</template>

<script>
// @ is an alias to /src
export default {
  name: "Home",
  components: {},
  data() {
    return {};
  },
};
</script>

<style scoped>
.buttons-container,
.photos_observateurs_container {
  display: flex;
  flex-direction: column;
  width: 100%;
  text-align: center;
}
.span-space {
  margin-top: 10px;
  font-size: 13pt;
}
.span-space .btn {
  font-size: 13pt;
}

.bold {
  font-weight: bold;
}

#titreButtonChoice,
#buttonChoice,
#imgCopyright {
  margin-bottom: 20px;
}
</style>
