<template>
  <div>
    <EffortCourantSummary
      style="max-width: 1140px; margin: 0 auto"
      v-if="currentEffortData"
      :currentEffortData="currentEffortData"
    />
    <ObservationForm
      :isOffline="false"
      :isMember="Boolean($store.getters.ogslUserData)"
      :currentEffortData="currentEffortData"
    />
    <b-modal id="modal-deconnection" hide-footer title="No connectivity">
      <p class="my-4">
        Connectivity is lost. You can just wait and continue typing your
        observation when the app is online again.
      </p>
      <p class="my-4">
        Or you can enter a new observation with an offline form
      </p>
      <b-button variant="primary" block @click="$router.push('/')">
        Go to offline form
      </b-button>
    </b-modal>
  </div>
</template>

<script>
import ObservationForm from "@/components/ObservationForm";
import EffortCourantSummary from "@/components/EffortCourantSummary";

import { getCurrentSession } from "@/storage/slgoServices";

import { getCookie } from "tiny-cookie";

// @ is an alias to /src
export default {
  name: "NewObservationOnline",
  components: {
    ObservationForm,
    EffortCourantSummary,
  },
  data() {
    return {
      currentEffortData: null,
    };
  },
  async mounted() {
    this.currentEffortData = await getCurrentSession(getCookie("sessionId"));
  },
  methods: {},
  computed: {},
  watch: {
    "$store.state.pwa.isOnline": async function (newValue) {
      if (newValue) {
        this.currentEffortData = await getCurrentSession(
          getCookie("sessionId")
        );
        this.$bvModal.hide("modal-deconnection");
      } else {
        this.$bvModal.show("modal-deconnection");
      }
    },
  },
};
</script>

<style scoped>
</style>
