<template>
  <div>
    <table v-if="observations.length > 0">
      <thead>
        <tr>
          <th>Type</th>
          <th>Date</th>
          <th>Latitude</th>
          <th>Longitude</th>
          <th>Espece</th>
          <th>Nb individus</th>
          <th></th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="observation in observations" :key="observation.id">
          <td data-label="Type">
            {{
              observation.observationObject.whenWhere.memberSelected
                ? "Membre"
                : "Citoyenne"
            }}
          </td>
          <td data-label="Date">
            {{
              formatUTCDateToLocalTimezone(
                observation.observationObject.whenWhere.dateUTC
              )
            }}
          </td>
          <td data-label="Latitude">
            {{
              parseFloat(
                observation.observationObject.whenWhere.longitude
              ).toFixed(4)
            }}
          </td>
          <td data-label="Longitude">
            {{
              parseFloat(
                observation.observationObject.whenWhere.latitude
              ).toFixed(4)
            }}
          </td>
          <td data-label="Espece">
            {{
              observation.observationObject.observation.speciesSelected.nameen
            }}
          </td>
          <td data-label="Nb indivus">
            {{ observation.observationObject.observation.nbIndividuals }}
          </td>
          <td>
            <b-button
              v-if="!$store.state.pwa.isOnline"
              variant="primary"
              disabled
              >{{ $t("network-connection-required") }}</b-button
            >
            <b-button
              v-else-if="needToLoginToPublish(observation)"
              variant="primary"
              disabled
              >{{ $t("need-login-button-text") }}</b-button
            >
            <b-button
              v-else-if="
                observation.observationObject.offlineSessionId &&
                !observation.observationObject.parentEventId
              "
              variant="primary"
              disabled
              >{{ $t("publish-session-first") }}</b-button
            >
            <b-button
              v-else
              variant="primary"
              @click="handleClickPublishObservation(observation)"
              >{{ $t("publish-observation") }}</b-button
            >
          </td>
        </tr>
      </tbody>
    </table>
    <div v-else>{{ $t("no-observation") }}</div>
  </div>
</template>

<script>
import { db } from "@/storage/indexedDB";
import { pushObservationToBiohub } from "@/storage/slgoServices";
import { getCookie } from "tiny-cookie";
import { formatUTCDateToLocalTimezone } from "@/utils/date";

export default {
  name: "ObservationsPendingObservationList",
  props: ["observations"],
  components: {},
  computed: {},
  data() {
    return {};
  },
  mounted() {},
  methods: {
    formatUTCDateToLocalTimezone(dateUTC) {
      return formatUTCDateToLocalTimezone(dateUTC);
    },
    needToLoginToPublish(observation) {
      return (
        !this.$store.getters.ogslUserData &&
        observation.observationObject.whenWhere.memberSelected
      );
    },
    async handleClickPublishObservation(observation) {
      this.$store.commit("isSendObservationInProgress", true);

      observation.observationObject.offlineObservation = true;

      try {
        await pushObservationToBiohub(
          observation.observationObject.guid,
          observation.observationObject,
          this.$store.getters.userName,
          getCookie("sessionId")
        );
      } catch (error) {
        this.$bvToast.toast(this.$t("observationError"), {
          title: this.$t("error"),
          variant: "danger",
          solid: true,
        });
        return;
      } finally {
        this.$store.commit("isSendObservationInProgress", false);
      }
      await db.observations.delete(observation.id);
      this.$bvToast.toast(this.$t("observationSuccess"), {
        title: this.$t("success"),
        variant: "success",
        solid: true,
      });
    },
  },
};
</script>

<style scoped>
table {
  border: 1px solid #ccc;
  border-collapse: collapse;
  margin: 0;
  padding: 0;
  width: 100%;
  table-layout: fixed;
}

table caption {
  font-size: 1.5em;
  margin: 0.5em 0 0.75em;
}

table tr {
  background-color: #f8f8f8;
  border: 1px solid #ddd;
  padding: 0.35em;
}

table th,
table td {
  padding: 0.625em;
  text-align: center;
}

table th {
  font-size: 0.85em;
  letter-spacing: 0.1em;
  text-transform: uppercase;
}

@media screen and (max-width: 600px) {
  table {
    border: 0;
  }

  table caption {
    font-size: 1.3em;
  }

  table thead {
    border: none;
    clip: rect(0 0 0 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px;
  }

  table tr {
    border-bottom: 3px solid #ddd;
    display: block;
    margin-bottom: 0.625em;
  }

  table td {
    border-bottom: 1px solid #ddd;
    display: block;
    font-size: 0.8em;
    text-align: right;
  }

  table td::before {
    content: attr(data-label);
    float: left;
    font-weight: bold;
    text-transform: uppercase;
  }

  table td:last-child {
    border-bottom: 0;
  }
}
</style>
