<template>
  <b-modal
    :id="id"
    hide-footer
    :title="$t('reconnection-modal-connectivity-has-returned')"
  >
    <p class="my-4">
      {{ $t("reconnection-modal-continue-offline-form") }}
    </p>
    <b-button
      variant="primary"
      block
      @click="$bvModal.hide('modal-reconnection')"
    >
      {{ $t("reconnection-modal-continue-offline-button") }}
    </b-button>
    <p class="my-4">{{ $t("reconnection-modal-go-online-form-text") }}</p>
    <b-button variant="primary" block @click="$router.push('/')">
      {{ $t("reconnection-modal-go-online-form-button") }}
    </b-button>
  </b-modal>
</template>

<script>
export default {
  props: ["id"],
  data() {
    return {};
  },
  watch: {
    "$store.state.pwa.isOnline": async function (newValue) {
      if (newValue) {
        this.$bvModal.show(this.id);
      } else {
        this.$bvModal.hide(this.id);
      }
    },
  },
};
</script>

<style>
</style>
