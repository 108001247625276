<template>
  <div>
    <div>
      <strong>2023-01-17</strong>
      <p>
        - Ajout des nouveaux champs associés aux croisière aux baleines dans le module de rapport<br/>
        - Correction de l'extraction incluant les observations de la journée courante<br/>
        - Correction du code associé aux baleines noires dans la génération de rapport<br/>
        - Limitation de la latitude et longitude à Nord-Ouest
      </p>
      <strong>## 2022-12-14</strong>
      <p>
        - Ajout de différent champs pour la saisie aux croisière au baleines (nombre de passagers/Type d'observation/Durée de l'observation)<br/>
        - Ajout champs nombre de jeune individus<br/>
        - Modification d'une observation en 2 sections<br/>
        - Ajout d'un récapitulatif d'une observation
      </p>
      <strong>## 2022-09-20</strong>
      <p>
        - Ajout du partenaire Croisière Baie de Gaspé<br/>
        - Retrait du partenaire Croisière Julien Cloutier  
      </p>
      <strong>## 2022-09-01</strong>
      <p>
        - Ajout du partenaire Cap Aventures
      </p>
      <strong>## 2022-08-15</strong>
      <p>
        - Optimisation des données
      </p>
      <strong>## 2022-08-08</strong>
      <p>
        - Changement de photo
      </p>
      <strong>## 2022-08-04</strong>
      <p>
        - Ajout des vidéos tutoriels Vimeo
      </p>
      <strong>## 2022-07-27</strong>
      <p>
        - Changement du protocole
      </p>
      <strong>## 2022-07-11</strong>
      <p>
        - Ajout du partenaire Atlantic Towing
      </p>
      <strong>## 2022-06-21</strong>
      <p>
        - Ajout du partenaire Saguenay Aventures
        - Ajout de la plateforme pour la saisie des observations membre
      </p>
      <strong>## 2022-06-08</strong>
      <p>
        - Ajout de 2 partenaires Parcs Canada (PC) et Parcs Canada (Cap-de-bon-désir)
      </p>
      <strong>## 2022-05-25</strong>
      <p>
        - Mise à jour des packages
      </p>
      <strong>## 2022-03-28</strong>
      <p>
        - Ajout du portail pêcheur dans le footer<br />
        - Mise à jour des packages
      </p>
      <strong>## 2021-11-11</strong>
      <p>
        - Ajout du popup de succès ou erreur lors du téléchargement du rapport + Visible lorsque connecté + CSS<br />
        - Modification du fichier d'environnement<br />
        - Ajout de la sécurité de l'affichage du bouton de download rapport
      </p>
      <strong>## 2021-11-09</strong>
      <p>
        - Ajout du télchargement du rapport
      </p>
      <strong>## 2021-09-28</strong>
      <p>
        - Fix nom Requin sp.
      </p>
      <strong>## 2021-09-16</strong>
      <p>
        - Ajout de deux espèces Tortue sp. et Requin spp.
      </p>
      <strong>## 2021-08-09</strong>
      <p>
        - Changement de texte + lien d'accueil
      </p>
      <strong>## 2021-08-04</strong>
      <p>
        - Bug Fix langague Auth
      </p>
      <strong>## 2021-07-26</strong>
      <p>
        - Traduction
      </p>
      <strong>## 2021-07-23</strong>
      <p>
        - Traduction
      </p>
       <strong>## 2021-07-22</strong>
      <p>
        - Changement des choix dans la position<br />
        - Ajout des pages de résumé d'observation<br />
        - Gestion de la langue pour l'identification
      </p>
      <strong>## 2021-06-22</strong>
      <p>- Changement des choix dans la position</p>
      <strong>## 2021-06-01</strong>
      <p>- Ajout des choix derrière et tout autour dans la position</p>
      <strong>## 2021-05-28</strong>
      <p>
        - Séletion par défaut de l'hémisphère Ouest pour la saisie en
        degrés/minute/seconde
      </p>
      <strong>## 2021-05-17</strong>
      <p>- Correction liens version visualisation dans le header et footer</p>
      <strong>## version 2021-05-14</strong>
      <p>
        - Ajout Google Analytics<br />
        - Ajustement de texte<br />
      </p>
      <strong>## version 2021-05-12</strong>
      <p>
        - Validation initiale et nombre d'invidu<br />
        - Modification CSS et texte<br />
      </p>
      <strong>## version 2021-05-11</strong>
      <p>
        - Déplacement du changelog<br />
        - Limitation des lettres dans initiale<br />
        - Traduction<br />
        - Modification de l'envoie<br />
        - Validation longitude/latitude<br />
        - Pages sommaire d'observation avec/sans effort<br />
        - Fix affichage de la plateforme
      </p>
    </div>
    <div>
      <strong>## version 2021-05-10</strong>
      <p>
        - CSS + Texte<br />
        - Bouton annulé dans la début d'effort et fin d'effort<br />
        - Déplacement du menu déroulant de la plateforme
      </p>
    </div>
    <div>
      <strong>## version 2021-05-06</strong>
      <p>
        - Modification visuel de la page d'accueil<br />
        - Ajout de plateforme dans le formulaire
      </p>
    </div>
    <div>
      <strong>## version 2021-05-04</strong>
      <p>- Correction d'un appel vers un service d'ingestion de données</p>
    </div>
    <div>
      <strong>## version 2021-05-03</strong>
      <p>- CSS + Traduction</p>
    </div>
    <div>
      <strong>## version 2021-04-30</strong>
      <p>
        - Ajout favicon + titre du site<br />
        - Correction de la date d'affichage de l'effort, du loading et des
        appels effort
      </p>
    </div>
    <div>
      <strong>## version 2021-04-29</strong>
      <p>
        - Gestion de format d'image<br />
        - Correction d'affichage de la date du début d'effort<br />
        - Correction du nombre d'individus<br />
        - Ajout de différent loading
      </p>
    </div>
    <div>
      <strong>## version 2021-04-28</strong>
      <p>
        - Ajout de validation d'effort commencé lors d'un envoie d'une
        observation
      </p>
    </div>
    <div>
      <strong>## version 2021-04-26</strong>
      <p>
        - Ajout initial de l'observateur<br />
        - Ajout visuel d'effort commencé<br />
        - ajout des validations dynamiques<br />
      </p>
    </div>
    <div>
      <strong>## version 2021-04-14</strong>
      <p>
        - Connection avec nom/mot de passe d’usager<br />
        - Ajout d’une observation sans effort pour usager connecté<br />
        - Ajout d’un début d’effort pour usager connecté<br />
        - Ajout d’une observation lié a un effort pour l’usager connecté<br />
        - Fermeture d’un effort pour l’usager connecté - Traduction
      </p>
    </div>
    <div>
      <strong>## version 2021-03-25</strong>
      <p>- Liaison entre biohub et l'envoie d'une observation</p>
    </div>
    <div>
      <strong>## version 2021-02-16</strong>
      <p>
        - Ajout de la traduction<br />
        - Traduction selon le browser
      </p>
    </div>
    <div>
      <strong>## version 2021-02-15</strong>
      <p>- Ajout de la traduction</p>
    </div>
    <div>
      <strong>## version 2021-01-08</strong>
      <p>- Optimisation de l'application (Moins de JS chargé)</p>
    </div>
    <div>
      <strong>## version 2020-12-17</strong>
      <p>
        - Mise en place de la liaison de la gestion d'effort avec la base de
        données<br />
        - Affichage du résumé des efforts
      </p>
    </div>
    <div>
      <strong>## version 2020-12-10</strong>
      <p>
        - Mise en place de la liaison de la gestion d'effort avec la base de
        données<br />
        - Désactivation du login
      </p>
    </div>
    <div>
      <strong>## version 2020-12-04</strong>
      <p>- Dropdown du menu de connexion</p>
    </div>
    <div>
      <strong>## version 2020-12-03</strong>
      <p>- Ajout de la page de fin de l'effort</p>
    </div>
    <div>
      <strong>## version 2020-12-02</strong>
      <p>- Ajout de la page de début de l'effort</p>
    </div>
    <div>
      <strong>## version 2020-12-01</strong>
      <p>
        - Ajout de la page de l'observation avec effort<br />
        - Ajout de la gestion des champs optionnels lorsque connecté<br />
        - Amélioration des logos du footer<br />
        - Bug fix sur le cookie de connexion qui ne se supprimais pas
      </p>
    </div>
    <div>
      <strong>## version 2020-11-26</strong>
      <p>- Bug Fix login</p>
    </div>
    <div>
      <strong>## version 2020-11-24</strong>
      <p>- Implemente Login</p>
    </div>
    <div>
      <strong>## version 2020-11-19</strong>
      <p>
        - Amélioration CSS des images sur la page d'accueil<br />
        - Changement du texte sur la page d'accueil<br />
        - Mise en place des modification des textes/photos fournis par le ROMM
      </p>
    </div>
    <div>
      <strong>## version 2020-11-13</strong>
      <p>
        - Bug fix désactiver le bouton "envoyer" si modification de
        l'observation et quand on a pas fini l'observation<br />
        - Amélioration CSS des champs qui prennent tout l'espace
      </p>
    </div>
    <div>
      <strong>## version 2020-11-11</strong>
      <p>
        - Mise en place des configurations d'environnement pour le déploiement
      </p>
    </div>
    <div>
      <strong>## version 2020-11-10</strong>
      <p>
        - Déplacer l'emplacement des (i) à coté du label<br />
        - Ajout de label sur la limitation d'image<br />
        - Fix les images dans le footer se superposant<br />
        - Ajout d'une page d'information sur l'information du développement<br />
        - Mise en place des modification des textes/photos fournis par le ROMM
      </p>
    </div>
    <div>
      <strong>## version 2020-11-06</strong>
      <p>
        - Bug fix entre local et preprod pour biohub lors de l’envoie<br />
        - Ajout d'un overlay de chargement avec spinner lors de l'envoie de
        l'observation
      </p>
    </div>

    <div>
      <strong>## version 2020-11-04</strong>
      <p>
        - Ajout photo page d'accueil<br />
        - CSS sur upload photo<br />
        - Mise en place des modification des textes/photos fournis par le
        ROMM<br />
      </p>
    </div>
    <div>
      <strong>## version 2020-10-30</strong>
      <p>
        - Mise en place des modification des textes/photos fournis par le ROMM
      </p>
    </div>
    <div>
      <strong>## version 2020-10-29</strong>
      <p>
        - Ajout de label Longitude/Latitude<br />
        - Fix suppression photo<br />
        - Filtrage png/jpg/bmp pour l'upload d'image
      </p>
    </div>
    <div>
      <strong> ## version 2020-10-27</strong>
      <p>
        - Liaison avec MetaDataBase pour l'ajout de l'image<br />
        - Ajout de Jquery<br />
        - Gestion de l'image dans l'observation
      </p>
    </div>
    <div>
      <strong>## version 2020-10-22</strong>
      <p>
        - Ajout coordonnées en degré minute seconde<br />
        - Gestion du choix entre degré décimale et degré minute seconde<br />
        - CSS sur la localisation
      </p>
    </div>
    <div>
      <strong>## version 2020-10-14</strong>
      <p>
        - Gestion des coordonnées sur la map par le bouton de la localisation<br />
        - Ajout du nom scientifique dans les espèces
      </p>
    </div>
    <div>
      <strong>## version 2020-10-13</strong>
      <p>
        - Configuration du loader d'image<br />
        - Ajout de la description et de l'image associé à l'espèce
        sélectionné<br />
        - Gestion des coordonnées sur la map avec les inputs
      </p>
    </div>
    <div>
      <strong>## version 2020-10-07</strong>
      <p>
        - Ajout de VueLayers<br />
        - Ajout de la map avec gestion des coordonnées selon le clique souris
        sur la map
      </p>
    </div>
    <div>
      <strong>## version 2020-10-06</strong>
      <p>- Mise a jour de la sélection des espèces</p>
    </div>
    <div>
      <strong>## version 2020-09-30</strong>
      <p>
        - Ajout des catégories d'espèces<br />
        - Ajout du bandeau d'avertissement et affichage de la version
      </p>
    </div>
    <div>
      <strong>## version 2020-09-29</strong>
      <p>- Mise en place des modification des textes fournis par le ROMM</p>
    </div>
    <div>
      <strong>## version 2020-09-14</strong>
      <p>
        - Changement et réaménagement des sections<br />
        - Mise en place des textes fournis par le ROMM<br />
        - Amélioration du CSS sur mobile<br />
        - Traductions
      </p>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Changelog'
}
</script>
