<template>
  <div class="endPageEffort">
    <EffortCourantSummary
      v-if="currentEffortData"
      :currentEffortData="currentEffortData"
    />
    <TimeFormFields
      :defaultDate="currentEffortData?.startDate"
      @dateUTC-changed="
        (newDate) => {
          this.dateUTC = newDate;
        }
      "
    />
    <LocationFormFields
      @location-changed="
        (newLatitude, newLongitude) => {
          this.form.latitude = newLatitude;
          this.form.longitude = newLongitude;
        }
      "
      :useOfflineMap="!$store.state.pwa.isOnline"
    />
    <b-button
      @click="finEffort()"
      :disabled="$v.$invalid || dateUTC == 'Invalid date'"
      variant="primary"
      class="button-color"
      >{{ $t("send-observation") }}</b-button
    >
    <b-button @click="back()" class="mt-2 ml-2 mb-2 button-color">{{
      $t("cancel")
    }}</b-button>
  </div>
</template>

<script>
import EffortCourantSummary from "@/components/EffortCourantSummary.vue";

import { required, minValue, maxValue } from "vuelidate/lib/validators";
import { Timezone } from "@/constants";
import {
  getCurrentSession,
  endObservationSession,
  getOfflineSession,
} from "@/storage/session-management";
import LocationFormFields from "@/components/LocationFormFields";
import TimeFormFields from "@/components/TimeFormFields";

export default {
  name: "EffortEndForm",
  components: {
    EffortCourantSummary,
    LocationFormFields,
    TimeFormFields,
  },
  async beforeRouteEnter(to, from, next) {
    let currentEffortData = null;
    if (to.query.offlineSessionId) {
      currentEffortData = await getOfflineSession(to.query.offlineSessionId);
    } else {
      currentEffortData = await getCurrentSession();
    }

    if (currentEffortData) {
      next((vm) => {
        vm.currentEffortData = currentEffortData;
      });
    } else {
      next("/");
    }
  },
  computed: {},
  data() {
    return {
      currentEffortData: null,
      Timezone: Timezone,
      end: null,
      extraData: {},
      dateUTC: null,
      form: {
        latitude: "",
        longitude: "",
      },
    };
  },
  mounted() {},
  validations: {
    form: {
      latitude: {
        required,
        maxValue: maxValue(90),
        minValue: minValue(0),
      },
      longitude: {
        required,
        maxValue: maxValue(0),
        minValue: minValue(-180),
      },
    },
  },
  methods: {
    back() {
      this.$router.push("/");
    },

    async finEffort() {
      this.$store.commit("isSendObservationInProgress", true);
      const formattedDate = this.dateUTC;
      this.end = {
        endDate: formattedDate,
        extradata: {
          data: {
            endlatitude: parseFloat(this.form.latitude),
            endlongitude: parseFloat(this.form.longitude),
            closedeffort: true,
          },
        },
      };

      try {
        await endObservationSession(this.currentEffortData, this.end);
      } catch (err) {
        this.$bvToast.toast(this.$t(err.message), {
          title: this.$t("error"),
          variant: "danger",
          solid: true,
        });
        return;
      } finally {
        this.$store.commit("isSendObservationInProgress", false);
      }

      if (this.$route.query.offlineSessionId) {
        await this.$router.push("/observations-en-attente");
      } else {
        await this.$router.push("/");
      }
      this.$bvToast.toast(this.$t("EndEffortSuccess"), {
        title: this.$t("success"),
        variant: "success",
        solid: true,
      });
    },
  },
};
</script>

<style scoped>
.endPageEffort {
  flex: 1 1 auto;
  min-height: 1px;
  padding: 1.25rem;
  flex-grow: 1;
  flex-shrink: 0;
}

.button-color {
  background-color: #2147af;
  border-color: #2147af;
}

@media screen and (min-width: 1140px) {
  .endPageEffort {
    width: 1140px;
    margin: 0 auto;
  }
}

@media screen and (max-width: 1139px) {
  .endPageEffort {
    width: 100%;
  }
}

@media screen and (min-width: 768px) {
  .endPageEffort {
    padding: 1.25rem;
  }
}

@media screen and (max-width: 767px) {
  .endPageEffort {
    padding: 0.5rem !important;
  }
}
</style>
