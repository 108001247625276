// import axios from 'axios'

const state = {
    isOnline: true,
}

const mutations = {
    setIsOnline(state, isOnline) {
        state.isOnline = isOnline
    },
}

export default {
    state: state,
    mutations: mutations
}
