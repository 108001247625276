import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import Changelog from '../views/Changelog.vue'
import NewObservationOnline from '@/views/NewObservationOnline.vue'
import EffortStartForm from '@/components/EffortStartForm.vue'
import EffortEndForm from '@/components/EffortEndForm.vue'
import ObservationOfflineCitizen from '@/views/ObservationOfflineCitizen.vue'
import ObservationOfflineMember from '@/views/ObservationOfflineMember.vue'
import ObservationsPending from '@/views/ObservationsPending.vue'


Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/nouvelle-observation',
    name: 'Observation',
    component: NewObservationOnline
  },
  {
    path: '/nouvelle-observation-citoyenne-hors-ligne',
    name: 'ObservationOfflineCitizen ',
    component: ObservationOfflineCitizen
  },
  {
    path: '/nouvelle-observation-membre-hors-ligne',
    name: 'ObservationOfflineMember ',
    component: ObservationOfflineMember
  },
  {
    path: '/observations-en-attente',
    name: 'ObservationsPending',
    component: ObservationsPending
  },
  {
    path: '/changelog',
    name: 'Changelog',
    component: Changelog
  },
  {
    path: '/debut-effort',
    name: 'EffortStartForm',
    component: EffortStartForm
  },
  {
    path: '/fin-effort',
    name: 'EffortEndForm',
    component: EffortEndForm
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
