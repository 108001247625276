<template>
  <div id="summary">
    <b-card
      header="Résumé de l'effort en cours"
      border-variant="info"
      class="text-center"
    >
      <b-card-text
        ><strong>{{ $t("start-date") }} : </strong
        >{{ formatEffortDate() }}</b-card-text
      >
      <b-card-text
        ><strong>{{ $t("plateforme") }} : </strong
        >{{ plateformeSummary() }}</b-card-text
      >
      <b-card-text v-if="currentEffortData.ship"
        ><strong>{{ $t("ship") }} : </strong
        >{{ currentEffortData.ship }}</b-card-text
      >
      <b-card-text v-if="currentEffortData.nbPassengers"
        ><strong>{{ $t("nb_passengers") }} : </strong
        >{{ currentEffortData.nbPassengers }}</b-card-text
      >
      <b-card-text
        ><strong>{{ $t("initial") }} : </strong
        >{{ currentEffortData.initial }}</b-card-text
      >
      <b-card-text
        ><strong>{{ $t("partner") }} : </strong
        >{{ membreSummary() }}</b-card-text
      >
    </b-card>
  </div>
</template>

<script>
import moment from "moment";
import { Membres, Plateforme, getConstantName } from "@/constants";
export default {
  props: ["currentEffortData"],
  data() {
    return {};
  },
  methods: {
    plateformeSummary() {
      return getConstantName(
        Plateforme,
        this.currentEffortData.plateformeSelected,
        "text",
        this.$i18n.locale
      );
    },
    membreSummary() {
      return getConstantName(
        Membres,
        this.currentEffortData.organizationCode,
        "name",
        this.$i18n.locale
      );
    },
    formatEffortDate() {
      const dateStart = moment(this.currentEffortData.startDate, [
        "YYYY-MM-DDTHH:mm:ssZ",
      ])
        .local()
        .format();
      return (
        dateStart.substring(0, 10) +
        " " +
        dateStart.substring(11, 19) +
        " UTC" +
        dateStart.substring(19)
      );
    },
  },
  mounted() {},
  computed: {},
};
</script>

<style>
#summary {
  width: 100%;
  padding: 1.25rem 0 1.25rem 0;
}

#summary p {
  margin-bottom: 5px !important;
}
</style>
