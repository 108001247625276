<template>
  <b-form>
    <div v-if="isMember">
      <label>
        {{ $t("partner") }}
        <b-icon
          id="info-member-icon"
          icon="info-circle"
          variant="info"
          class="icon"
        ></b-icon>
      </label>
      <b-form-group>
        <multiselect
          data-cy="member-multiselect"
          v-model="formWhenWhere.memberSelected"
          :options="membreOptions"
          :placeholder="$t('select-choice')"
          track-by="value"
          :label="nameSelection"
          :show-labels="false"
          :searchable="false"
          :disabled="isFieldReadonly('organizationCode')"
        >
          <template slot="selection" slot-scope="{ values, isOpen }">
            <span class="multiselect__single" v-if="values.length && !isOpen">
              {{ values.length }} partenaire(s) sélectionnée(s)
            </span>
          </template>
        </multiselect>
        <b-tooltip
          triggers="click"
          placement="top"
          ref="tooltipMember"
          target="info-member-icon"
        >
          {{ $t("tooltipPartner") }}
        </b-tooltip>
        <div class="error" v-if="!$v.formWhenWhere.memberSelected.required">
          {{ $t("fields-required") }}
        </div>
      </b-form-group>
    </div>
    <label>
      {{ $t("plateforme") }}
      <b-icon
        id="info-plateform-icon"
        icon="info-circle"
        variant="info"
        class="icon"
      ></b-icon>
    </label>
    <b-form-group>
      <b-form-select
        id="input-plateform"
        v-model="formWhenWhere.plateformeSelected"
        :options="plateformeOptions"
        :text-field="textSelection"
        class="mr-3 b-components-size"
        :disabled="isFieldReadonly('plateformeSelected')"
      >
      </b-form-select>
      <div class="error" v-if="!$v.formWhenWhere.plateformeSelected.required">
        {{ $t("field-required") }}
      </div>
    </b-form-group>
    <b-tooltip
      triggers="click"
      ref="tooltipPlateforme"
      target="info-plateform-icon"
    >
      {{ $t("tooltipPlateforme") }}
    </b-tooltip>
    <div v-if="fieldSpecs.ship.shouldAddToForm()">
      <label>
        {{ $t("ship") }}
        <b-icon
          id="info-ship-icon"
          icon="info-circle"
          variant="info"
          class="icon"
        ></b-icon>
      </label>
      <b-form-group>
        <b-form-input
          id="input-ship"
          v-model="formWhenWhere.ship"
          class="mr-3 b-components-size"
          :disabled="isFieldReadonly('ship')"
          :readonly="isFieldReadonly('ship')"
        >
        </b-form-input>
        <b-tooltip
          triggers="click"
          placement="top"
          ref="tooltipShip"
          target="info-ship-icon"
        >
          {{ $t("tooltipShip") }}
        </b-tooltip>
        <div class="error" v-if="!$v.formWhenWhere.ship.required">
          {{ $t("fields-required") }}
        </div>
        <div class="error" v-if="!$v.formWhenWhere.ship.nameWithSpace">
          {{ $t("ship-error") }}
        </div>
      </b-form-group>
    </div>
    <div v-if="fieldSpecs.nbPassengers.shouldAddToForm()">
      <label>
        {{ $t("nb_passengers") }}
        <b-icon
          id="info-nb-passengers-icon"
          icon="info-circle"
          variant="info"
          class="icon"
        ></b-icon>
      </label>
      <b-form-group>
        <b-form-input
          type="number"
          min="0"
          step="1"
          id="input-nb-passengers"
          class="mr-3 b-components-size"
          required
          v-model="formWhenWhere.nbPassengers"
          :disabled="isFieldReadonly('nbPassengers')"
          :readonly="isFieldReadonly('nbPassengers')"
        ></b-form-input>
        <b-tooltip
          triggers="click"
          placement="top"
          ref="tooltipNbPassengers"
          target="info-nb-passengers-icon"
        >
          {{ $t("tooltipNbPassengers") }}
        </b-tooltip>
        <div class="error" v-if="$v.formWhenWhere.nbPassengers.$invalid">
          {{ $t("nbIndividu-error") }}
        </div>
        <div class="error" v-if="!$v.formWhenWhere.nbPassengers.minValue">
          {{ $t("nbPassengersvalue-error") }}
        </div>
      </b-form-group>
    </div>
    <div v-if="isMMSIFieldAdded()">
      <label>
        {{ $t("mmsi") }}
        <b-icon
          id="info-mmsi-icon"
          icon="info-circle"
          variant="info"
          class="icon"
        ></b-icon>
      </label>
      <b-form-group>
        <b-form-input
          id="input-mmsi"
          v-model="formWhenWhere.mmsi"
          class="mr-3 b-components-size"
          :disabled="isFieldReadonly('mmsi')"
          :readonly="isFieldReadonly('mmsi')"
        >
        </b-form-input>
        <b-tooltip
          triggers="click"
          placement="top"
          ref="tooltipMMSI"
          target="info-mmsi-icon"
        >
          {{ $t("tooltipMMSI") }}
        </b-tooltip>
      </b-form-group>
    </div>
    <div v-if="isMember">
      <label>
        {{ $t("initial") }}
        <b-icon
          id="info-initial-icon"
          icon="info-circle"
          variant="info"
          class="icon"
        ></b-icon>
      </label>
      <b-form-group>
        <b-form-input
          id="input-initial"
          v-model="formWhenWhere.initial"
          @keypress="filterJustLetter($event)"
          class="mr-3 b-components-size"
        >
        </b-form-input>
        <b-tooltip
          triggers="click"
          placement="top"
          ref="tooltipInitial"
          target="info-initial-icon"
        >
          {{ $t("tooltipInitial") }}
        </b-tooltip>
        <div class="error" v-if="!$v.formWhenWhere.initial.required">
          {{ $t("fields-required") }}
        </div>
        <div class="error" v-if="!$v.formWhenWhere.initial.alpha">
          {{ $t("initial-error") }}
        </div>
      </b-form-group>
    </div>
    <TimeFormFields
      @dateUTC-changed="
        (newDate) => {
          this.dateUTC = newDate;
        }
      "
    />
    <div v-if="!hideVisibilityAndWind">
      <label>
        {{ $t("visibility") }}
        <b-icon
          id="info-visibility-icon"
          icon="info-circle"
          variant="info"
          class="icon"
        ></b-icon>
      </label>
      <b-form-group>
        <b-form-select
          id="input-visibility"
          v-model="formWhenWhere.visibility"
          :options="visibiliteOptions"
          :text-field="textSelection"
          class="mr-3 b-components-size"
        ></b-form-select>
        <div class="error" v-if="!$v.formWhenWhere.visibility.required">
          {{ $t("field-required") }}
        </div>
      </b-form-group>
      <b-tooltip
        triggers="click"
        ref="tooltipVisibility"
        target="info-visibility-icon"
      >
        {{ $t("tooltipVisibility") }}
      </b-tooltip>
      <label>
        {{ $t("wind") }}
        <b-icon
          id="info-wind-icon"
          icon="info-circle"
          variant="info"
          class="icon"
        ></b-icon>
      </label>
      <b-form-group>
        <b-form-select
          id="input-wind"
          v-model="formWhenWhere.wind"
          :options="ventOptions"
          :text-field="textSelection"
          class="mr-3 b-components-size"
        ></b-form-select>
        <div class="error" v-if="!$v.formWhenWhere.wind.required">
          {{ $t("field-required") }}
        </div>
      </b-form-group>
      <b-tooltip triggers="click" ref="tooltipWind" target="info-wind-icon">
        {{ $t("tooltipWind") }}
      </b-tooltip>
    </div>
    <LocationFormFields
      @location-changed="
        (newLatitude, newLongitude) => {
          this.formWhenWhere.latitude = newLatitude;
          this.formWhenWhere.longitude = newLongitude;
        }
      "
      :useOfflineMap="useOfflineMap"
    />

    <b-button
      data-cy="whenwhere-next-button"
      @click="handleClickNext"
      :disabled="$v.$invalid || dateUTC == 'Invalid date'"
      variant="primary"
      class="button-color"
      >{{ $t("next") }}</b-button
    >
  </b-form>
</template>

<script>
import { Plateforme, Visibilite, Vent, Membres } from "@/constants";
import {
  required,
  minValue,
  maxValue,
  requiredIf,
  helpers,
  numeric,
  alpha,
} from "vuelidate/lib/validators";

import LocationFormFields from "@/components/LocationFormFields";
import TimeFormFields from "@/components/TimeFormFields";

// @ is an alias to /src
export default {
  name: "ObservationFormWhenWhere",
  components: { LocationFormFields, TimeFormFields },
  props: ["useOfflineMap", "isMember", "staticData", "hideVisibilityAndWind"],
  data() {
    return {
      membreOptions: Membres,
      ventOptions: Vent,
      visibiliteOptions: Visibilite,
      plateformeOptions: Plateforme,
      dateUTC: null,
      formWhenWhere: {
        mmsi: null,
        initial: null,
        memberSelected: null,
        ship: null,
        nbPassengers: 0,
        latitude: "",
        longitude: "",
        plateformeSelected: null,
        visibility: null,
        wind: null,
      },
    };
  },
  validations() {
    return {
      formWhenWhere: {
        nbPassengers: {
          required: requiredIf(this.fieldSpecs.nbPassengers.isRequired),
          numeric,
          minValue: minValue(0),
        },
        ship: {
          required: requiredIf(this.fieldSpecs.ship.isRequired),
          nameWithSpace: helpers.regex("nameWithSpace", /^.*\S.*[a-zA-Z_ ]*$/i),
        },
        memberSelected: { required: requiredIf(() => this.isMember) },
        initial: {
          required: requiredIf(() => this.isMember),
          alpha,
        },
        wind: {
          required,
        },
        visibility: {
          required,
        },
        plateformeSelected: {
          required,
        },
        latitude: {
          required,
          maxValue: maxValue(90),
          minValue: minValue(0),
        },
        longitude: {
          required,
          maxValue: maxValue(0),
          minValue: minValue(-180),
        },
      },
    };
  },
  created() {
    this.fieldSpecs = {
      ship: {
        shouldAddToForm: () => {
          if (!this.formWhenWhere.memberSelected) return false;

          return [
            "bateau-peche",
            "croisiere-baleine",
            "naviere-commercial",
            "traversier",
            "ope-maritime",
            "croisiere-internationale",
            "croisiere-expedition",
          ].includes(this.formWhenWhere.plateformeSelected);
        },
        isRequired: () => {
          if (!this.fieldSpecs.ship.shouldAddToForm()) return false;

          return [
            "bateau-peche",
            "naviere-commercial",
            "traversier",
            "ope-maritime",
            "croisiere-internationale",
            "croisiere-expedition",
          ].includes(this.formWhenWhere.plateformeSelected);
        },
      },
      nbPassengers: {
        shouldAddToForm: () => {
          if (!this.formWhenWhere.memberSelected) return false;

          return ["croisiere-baleine"].includes(
            this.formWhenWhere.plateformeSelected
          );
        },
        isRequired: () => {
          return false;
        },
      },
    };
  },
  mounted() {
    this.assignStaticData();
  },
  methods: {
    isFieldReadonly(fieldName) {
      return this.staticData && Object.hasOwn(this.staticData, fieldName);
    },
    filterJustLetter(e) {
      const char = String.fromCharCode(e.keyCode);
      if (/^[A-Za-z]+$/.test(char)) return true;
      else e.preventDefault();
    },
    cleanFormData() {
      // Reset conditional fields if they are not needed anymore
      if (!this.fieldSpecs.nbPassengers.shouldAddToForm())
        this.formWhenWhere.nbPassengers = 0;
      if (!this.fieldSpecs.ship.shouldAddToForm())
        this.formWhenWhere.ship = null;
      if (!this.isMMSIFieldAdded()) this.formWhenWhere.mmsi = null;
    },
    handleClickNext() {
      this.$emit("go-next-step");
    },
    isMMSIFieldAdded() {
      return (
        this.formWhenWhere.plateformeSelected === "naviere-commercial" &&
        this.isMember
      );
    },

    assignStaticData() {
      if (this.staticData) {
        for (const fieldName of [
          "initial",
          "plateformeSelected",
          "nbPassengers",
          "ship",
          "mmsi",
          "wind",
          "visibility",
        ])
          if (Object.hasOwn(this.staticData, fieldName))
            this.formWhenWhere[fieldName] = this.staticData[fieldName];

        if (Object.hasOwn(this.staticData, "organizationCode")) {
          const memberOption = this.membreOptions.find(
            (membre) => membre.value === this.staticData.organizationCode
          );
          this.formWhenWhere.memberSelected = memberOption;
        }
      }
    },
  },
  computed: {
    textSelection() {
      return `text${this.$i18n.locale}`;
    },
    nameSelection() {
      return `name${this.$i18n.locale}`;
    },
  },
  watch: {
    staticData() {
      this.assignStaticData();
    },
  },
};
</script>

<style scoped>
#utcDate {
  flex: 0 1 100%;
}

.button-color {
  background-color: #2147af;
  border-color: #2147af;
}
.error {
  color: red;
}
</style>
