<template>
  <div>
    <b-overlay :show="isSendObservationInProgress" rounded="sm">
      <div id="app">
        <Header></Header>
        <span
          v-if="this.showTest === 'true'"
          style="
            color: black;
            background-color: red;
            font-weight: bold;
            text-align: center;
          "
          >Site en développement - Version test : 2023-01-17</span
        >
        <span
          v-if="this.showTest === 'true'"
          style="
            color: black;
            background-color: red;
            font-weight: bold;
            text-align: center;
          "
          >Toutes les observations faites vont être supprimées à la fin</span
        >
        <div v-if="newContentDownloading">
          Nouvelle version en cours de téléchargement.
        </div>
        <div style="text-align: right; padding: 5px"><ConnectionBadge /></div>

        <router-view />
        <Footer></Footer>
      </div>
    </b-overlay>
  </div>
</template>
<script>
import Header from "@/views/Header.vue";
import Footer from "@/views/Footer.vue";

import * as ogslAuth from "@/commons/ogsl-auth.js";
import ConnectionBadge from "@/components/ConnectionBadge.vue";

require("../public/assets/custom-fonts/stylesheet.css");
export default {
  components: {
    // eslint-disable-next-line vue/no-reserved-component-names
    Header,
    // eslint-disable-next-line vue/no-reserved-component-names
    Footer,
    ConnectionBadge,
  },
  data() {
    return {
      show: false,
      showTest: process.env.VUE_APP_SHOW_TEST,
      newContentDownloading: false,
    };
  },
  computed: {
    isSendObservationInProgress() {
      return this.$store.getters.isSendObservationInProgress;
    },
  },
  created() {
    document.addEventListener(
      "swNewContentDownloading",
      () => {
        this.newContentDownloading = true;
      },
      {
        once: true,
      }
    );
    document.addEventListener(
      "swNewContentCached",
      () => {
        this.newContentDownloading = false;
      },
      {
        once: true,
      }
    );
    document.addEventListener(
      "swNewContentReady",
      () => {
        this.newContentDownloading = false;
      },
      {
        once: true,
      }
    );

    this.$store.commit("setIsOnline", navigator.onLine);

    window.addEventListener("offline", () => {
      this.$store.commit("setIsOnline", false);
      this.$bvToast.toast(this.$t("app-no-connection"), {
        title: this.$t("warning"),
        variant: "warning",
        solid: true,
      });
    });

    window.addEventListener("online", () => {
      this.$store.commit("setIsOnline", true);
      this.$bvToast.toast(this.$t("app-connection-deteted"), {
        title: this.$t("app-connection-detected-title"),
        variant: "success",
        solid: true,
      });
    });
  },
  mounted() {
    ogslAuth.eventEmitter.addListener(
      ogslAuth.EVENT_USER_DATA_CHANGED,
      (data) => {
        this.$store.commit("setOgslUserData", data);
      }
    );
    ogslAuth.getUserData();
  },
  destroyed() {
    ogslAuth.eventEmitter.removeAllListeners(ogslAuth.EVENT_USER_DATA_CHANGED);
  },
  methods: {},
  watch: {
    "$store.state.pwa.isOnline": function (newValue) {
      // we only update use data when the connection is back
      // we keep user data when the app goes offline
      if (newValue) {
        ogslAuth.getUserData();
      }
    },
  },
};
</script>
<style>
h1 {
  font-size: 30pt !important;
  font-family: "geomanistregular";
  margin-top: 0.8em !important;
  margin-bottom: 8px !important;
}

h2 {
  font-size: 22pt;
  font-family: "Muli";
}

h3 {
  font-size: 16pt;
  font-family: "Muli";
  font-weight: bold;
}

p {
  font-size: 13pt;
  margin-bottom: 33px !important;
  line-height: 1.6;
}

p.card-text {
  margin-bottom: 5px !important;
}

#app {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  align-items: stretch;
  /* Le rendering de Muli n'est pas le même que sur le site navigationbaleine. Il est aussi en gras par défaut. Jamais trouvé pourquoi.*/
  /* font-family: "Muli", Sans-serif; */
}
.toUppercase {
  text-transform: uppercase;
}

a:hover {
  color: #9ba8b0 !important;
}
</style>
