const Membres = [
  {
    value: "ROMM-AUTRE",
    namefr: "Autre membre ( Spécifiez dans la section des remarques )",
    nameen: "Other member ( Specify in remarks section )"
  },
  {
    value: "ROMM-ALGOMA",
    namefr: "Algoma",
    nameen: "Algoma",
  },
  {
    value: "ROMM-ATLTOW",
    namefr: "Atlantic Towing",
    nameen: "Atlantic Towing"
  },
  {
    value: "ROMM-AURORAEXP",
    namefr: "Aurora Expeditions",
    nameen: "Aurora Expeditions"
  },
  {
    value: "ROMM-BAFF",
    namefr: "Baffinland",
    nameen: "Baffinland"
  },
  {
    value: "ROMM-CSL",
    namefr: "Canada Steamship Line",
    nameen: "Canada Steamship Line"
  },
  {
    value: "ROMM-CAPAVE",
    namefr: "Cap Aventure",
    nameen: "Cap Aventure"
  },
  {
    value: "ROMM-LAPOC",
    namefr: "Cégep de La Pocatière",
    nameen: "Cégep de La Pocatière"
  },
  {
    value: "ROMM-BASQ",
    namefr: "Compagnie de navigation des Basques",
    nameen: "Compagnie de navigation des Basques"
  },
  {
    value: "ROMM-CPBSL",
    namefr: "Corporation des pilotes du Bas Saint-Laurent",
    nameen: "Corporation des pilotes du Bas Saint-Laurent"
  },
  {
    value: "ROMM-AML",
    namefr: "Croisières AML",
    nameen: "AML Cruises"
  },
  {
    value: "ROMM-BGASP",
    namefr: "Croisières Baie de Gaspé",
    nameen: "Croisières Baie de Gaspé"
  },
  {
    value: "ROMM-CROI-ESSIP",
    namefr: "Croisières Essipit",
    nameen: "Croisières Essipit"
  },
  {
    value: "ROMM-CROFOR",
    namefr: "Croisière Forillon",
    nameen: "Croisière Forillon",
  },
  {
    value: "ROMM-BJC",
    namefr: "Croisières Julien Cloutier",
    nameen: "Croisières Julien Cloutier"
  },
  {
    value: "ROMM-ESCOUMINS",
    namefr: "Croisière Les Escoumins",
    nameen: "Croisière Les Escoumins"
  },
  {
    value: "ROMM-SEPT",
    namefr: "Croisières Sept-Îles",
    nameen: "Croisières Sept-Îles"
  },
  {
    value: "ROMM-CTMA",
    namefr: "CTMA",
    nameen: "CTMA"
  },
  {
    value: "ROMM-DHM",
    namefr: "Destination Haute-Mer",
    nameen: "Destination Haute-Mer"
  },
  {
    value: "ROMM-ECOMARIS",
    namefr: "Écomaris",
    nameen: "Écomaris"
  },
  {
    value: "ROMM-GWD",
    namefr: "Excursion G.W.D.",
    nameen: "Excursion G.W.D."
  },
  {
    value: "ROMM-EIAB",
    namefr: "Excursion Île aux Basques (Société Provancher)",
    nameen: "Excursion Île aux Basques (Société Provancher)"
  },
  {
    value: "ROMM-EDLP",
    namefr: "Excursions de la Providence",
    nameen: "Excursions de la Providence"
  },
  {
    value: "ROMM-FEDNAV",
    namefr: "Fednav",
    nameen: "Fednav"
  },
  {
    value: "ROMM-GFG",
    namefr: "Gare fluviale Godbout",
    nameen: "Gare fluviale Godbout"
  },
  {
    value: "ROMM-GFSJR",
    namefr: "Gare fluviale St-Joseph-de-la-Rive",
    nameen: "Gare fluviale St-Joseph-de-la-Rive"
  },
  {
    value: "ROMM-GD",
    namefr: "Groupe Desgagnés",
    nameen: "Groupe Desgagnés",
  },
  {
    value: "ROMM-GRIAV",
    namefr: "Griffon Aventure",
    nameen: "Griffon Aventure",
  },
  {
    value: "ROMM-ILEGB",
    namefr: "Île Grande Basque (Sept-Îles)",
    nameen: "Île Grande Basque (Sept-Îles)",
  },
  {
    value: "ROMM-M-EM",
    namefr: "M – Expertise Marine",
    nameen: "M – Expertise Marine",
  },
  {
    value: "ROMM-MARATLANTIC",
    namefr: "Marine Atlantic",
    nameen: "Marine Atlantic",
  },
  {
    value: "ROMM-MEME",
    namefr: "Mer et monde Écotours",
    nameen: "Mer et monde Écotours",
  },
  {
    value: "ROMM-NEAS",
    namefr: "NEAS",
    nameen: "NEAS"
  },
  {
    value: "ROMM-NEMOISMER",
    namefr: "Némo ISMER",
    nameen: "Nemo ISMER"
  },
  {
    value: "ROMM-NORAVE",
    namefr: "Noryak Aventures",
    nameen: "Noryak Aventures",
  },
  {
    value: "ROMM-OCEANEX",
    namefr: "Oceanex",
    nameen: "Oceanex"
  },
  {
    value: "ROMM-PC",
    namefr: "Parcs Canada (PC)",
    nameen: "Parcs Canada (PC)"
  },
  {
    value: "ROMM-PCCDBD",
    namefr: "Parcs Canada (Cap-de-bon-désir)",
    nameen: "Parcs Canada (Cap-de-bon-désir)"
  },
  {
    value: "ROMM-REFORMAR",
    namefr: "Reformar",
    nameen: "Reformar"
  },
  {
    value: "ROMM-ADMIN",
    namefr: "ROMM Admin",
    nameen: "ROMM Admin"
  },
  {
    value: "ROMM-EMPLOYES",
    namefr: "ROMM Employés",
    nameen: "ROMM Employés"
  },
  {
    value: "ROMM-SAG-AVE",
    namefr: "Saguenay Aventures",
    nameen: "Saguenay Aventures"
  },
  {
    value: "ROMM-SEABOURN",
    namefr: "Seabourn",
    nameen: "Seabourn"
  },
  {
    value: "ROMM-SEBKA",
    namefr: "Société d'écologie de la batture du Kamouraska",
    nameen: "Société d'écologie de la batture du Kamouraska"
  },
  {
    value: "ROMM-SPM-F",
    namefr: "SPM Ferries",
    nameen: "SPM Ferries"
  },
  {
    value: "ROMM-TIAC",
    namefr: "Traverse de L'Isle-aux-Coudres",
    nameen: "Traverse de L'Isle-aux-Coudres"
  },
  {
    value: "ROMM-TMBCG",
    namefr: "Traverse Matane-Baie-Comeau-Godbout",
    nameen: "Traverse Matane-Baie-Comeau-Godbout"
  },
  {
    value: "ROMM-RDLSS",
    namefr: "Traverse Rivière-du-Loup-Saint-Siméon",
    nameen: "Rivière-du-Loup-St.-Siméon ferry"
  },
  {
    value: "ROMM-HE",
    namefr: "Hurtigruten Expeditions",
    nameen: "Hurtigruten Expeditions",
  },
  {
    value: "ROMM-SCENIC",
    namefr: "Scenic",
    nameen: "Scenic"
  }
]

const Certitude = [
  {
    value: null,
    textfr: "Sélectionnez une option...",
    texten: "Select an option..."
  },
  {
    value: 1,
    textfr: "Certitude absolue",
    texten: "Absolute certainty"
  },
  {
    value: 2,
    textfr: "Incertitude",
    texten: "Uncertainty"
  },
]

const Distance = [
  {
    value: "nd",
    textfr: "Non disponible",
    texten: "None Available"
  },
  {
    value: "<100",
    textfr: "< 100 mètres",
    texten: "< 100 meters"
  },
  {
    value: "100-200m",
    textfr: "100 - 200 mètres",
    texten: "100 - 200 meters"
  },
  {
    value: "200-400m",
    textfr: "200 - 400 mètres",
    texten: "200 - 400 meters"
  },
  {
    value: "400-1000m",
    textfr: "400 - 1 000 mètres",
    texten: "400 - 1 000 meters"
  },
  {
    value: ">1000m",
    textfr: "> 1 000 mètres (0,5 mille nautique)",
    texten: "> 1 000 meters (0,5 nautical mile)"
  },
]

const Visibilite = [
  {
    value: null,
    textfr: "Sélectionnez une option...",
    texten: "Select an option..."
  },
  {
    value: "nd",
    textfr: "Non disponible",
    texten: "None Available"
  },
  {
    value: "Nulle",
    textfr: "Nulle : < 100 mètres",
    texten: "Very low : < 100 meters"
  },
  {
    value: "Limitée",
    textfr: "Limitée : 100 mètres - 2 milles nautiques",
    texten: "Limited : 100 meters - 2 nautical miles"
  },
  {
    value: "Bonne",
    textfr: "Bonne : > 2 milles nautiques",
    texten: "Good : > 2 nautical miles"
  },
]

const Vent = [
  {
    value: null,
    textfr: "Sélectionnez une option...",
    texten: "Select an option..."
  },
  {
    value: "nd",
    textfr: "Non disponible",
    texten: "None Available"
  },
  {
    value: "0-calme",
    textfr: "0. < 1 nœud (< 1 km/h)",
    texten: "0. < 1 knots (< 1 km/h)"
  },
  {
    value: "1-tres_legere_brise",
    textfr: "1. 1-3 nœuds (1-5 km/h)",
    texten: "1. 1-3 knots (1-5 km/h)"
  },
  {
    value: "2-legere_brise",
    textfr: "2. 4-6  nœuds (6-11 km/h)",
    texten: "2. 4-6  knots (6-11 km/h)"
  },
  {
    value: "3-petite_brise",
    textfr: "3. 7-10 nœuds (12-19 km/h)",
    texten: "3. 7-10 knots (12-19 km/h)"
  },
  {
    value: "4-jolie_brise",
    textfr: "4. 11-16 nœuds (20-29 km/h)",
    texten: "4. 11-16 knots (20-29 km/h)"
  },
  {
    value: "5-bonne_brise",
    textfr: "5. 17-21 nœuds (30-38 km/h)",
    texten: "5. 17-21 knots (30-38 km/h)"
  },
  {
    value: "6-vent_frais",
    textfr: "6. 22-27 nœuds (39-51 km/h)",
    texten: "6. 22-27 knots (39-51 km/h)"
  },
  {
    value: "7-grand_frais",
    textfr: "7. 28-33 nœuds (52-61 km/h)",
    texten: "7. 28-33 knots (52-61 km/h)"
  },
  {
    value: "8-coup_vent",
    textfr: "8. 34-40 nœuds (62-74km/h)",
    texten: "8. 34-40 knots (62-74km/h)"
  },
  {
    value: "9-fort_coup_vent",
    textfr: "9. 41-47 nœuds (75-86 km/h)",
    texten: "9. 41-47 knots (75-86 km/h)"
  },
  {
    value: "10-tempete",
    textfr: "10. 48-55 nœuds (87-101 km/h)",
    texten: "10. 48-55 knots (87-101 km/h)"
  },
  {
    value: "11-violente_tempete",
    textfr: "11. 56-63 nœuds (102-120 km/h)",
    texten: "11. 56-63 knots (102-120 km/h)"
  },
  {
    value: "12-ouragan",
    textfr: "12. > 64 nœuds (> 120 km/h)",
    texten: "12. > 64 knots (> 120 km/h)"
  },
]

const Plateforme = [
  {
    value: null,
    textfr: "Sélectionnez une option...",
    texten: "Select an option..."
  },
  {
    value: "rive",
    textfr: "Observation à partir de la rive",
    texten: "Shore-based observation"
  },
  {
    value: "prop-humaine",
    textfr: "Propulsion humaine (kayak, planche à pagaie, canot)",
    texten: "Human-powered craft (kayak, paddleboard, canoe)"
  },
  {
    value: "plaisancier-voile",
    textfr: "Plaisancier à voile",
    texten: "Sailboat"
  },
  {
    value: "plaisancier-moteur",
    textfr: "Plaisancier à moteur",
    texten: "Motorized pleasure craft"
  },
  {
    value: "bateau-peche",
    textfr: "Bateau de pêche commerciale",
    texten: "Commercial fishing boat",
  },
  {
    value: "croisiere-baleine",
    textfr: "Croisière aux baleines",
    texten: "Whale-watching cruise",
  },
  {
    value: "naviere-commercial",
    textfr: "Navire commercial",
    texten: "Commercial ship",
  },
  {
    value: "traversier",
    textfr: "Traversier",
    texten: "Ferry",
  },
  {
    value: "ope-maritime",
    textfr: "Opération maritime (garde-côtière, recherche)",
    texten: "Maritime operation (Coast Guard, research)",
  },
  {
    value: "excursion-en-mer",
    textfr: "Excursion en mer",
    texten: "Sea excursion"
  },
  {
    value: "croisiere-internationale",
    textfr: "Croisière internationale",
    texten: "International cruise",
  },
  {
    value: "croisiere-expedition",
    textfr: "Croisière d'expédition",
    texten: "Expedition cruise",
  },
  {
    value: "autre",
    textfr: "Autre ( Spécifiez dans la section des remarques )",
    texten: "Other ( Specify in remarks section )"
  },
]

const Especes = [
  {
    value: null,
    namefr: "Sélectionnez une option...",
    nameen: "Select an option..."
  },
  {
    categoryfr: "Baleines à fanons",
    categoryen: "Baleen whales",
    libs: [
      {
        value: "balmus",
        namefr: "BM - Rorqual bleu",
        nameen: "BM - Blue whale",
        scientific_namefr: "Balaenoptera musculus",
        scientific_nameen: "Balaenoptera musculus",
        arctiquefr: "",
        arctiqueen: "",
        descriptionfr: "• 20 à 28 mètres | 80 à 120 tonnes</br>• Souffle droit de plus de 6 mètres</br>• Corps moucheté de couleur gris-bleu</br>• Nageoire dorsale petite et triangulaire</br>• Montre parfois la queue en forme de « T »",
        descriptionen: "• 20-28 metres | 80-120 tonnes</br>• Straight spout that can exceed 6 metres in height</br>• Speckled, bluish-grey body</br>• Small, triangular dorsal fin</br>• Occasionally shows T-shaped tail",
        copyright_photofr: "© ROMM",
        copyright_photoen: "© MMON",
        image: "Rorqual bleu_ROMM.JPG",
      },
      {
        value: "balphy",
        namefr: "BP - Rorqual commun",
        nameen: "BP - Fin whale",
        scientific_namefr: "Balaenoptera physalus",
        scientific_nameen: "Balaenoptera physalus",
        arctiquefr: "",
        arctiqueen: "",
        descriptionfr: "• 18 à 24 mètres | 40 à 50 tonnes</br>• Souffle droit de 4 à 6 mètres</br>• Dos gris foncé, brun ou noir</br>• Nageoire dorsale souvent en forme de crochet</br>• Ne montre pas la queue",
        descriptionen: "• 18-24 metres | 40-50 tonnes</br>• Straight spout, 4 to 6 metres high</br>• Dark grey, brown or black back</br>• Dorsal fin often hook-shaped</br>• Does not show its tail",
        copyright_photofr: "© ROMM",
        copyright_photoen: "© MMON",
        image: "Rorqual commun_ROMM.JPG",
      },
      {
        value: "megnov",
        namefr: "MN - Rorqual à bosse",
        nameen: "MN - Humpback whale",
        scientific_namefr: "Megaptera novaeangliae",
        scientific_nameen: "Megaptera novaeangliae",
        arctiquefr: "",
        arctiqueen: "",
        descriptionfr: "• 11 à 16 mètres | 30 à 40 tonnes</br>• Souffle touffu pouvant atteindre 5 mètres</br>• Dos foncé ou noir, grandes pectorales blanches</br>• Petite nageoire dorsale posée sur une bosse</br>• Montre presque toujours la queue, face intérieure avec du blanc",
        descriptionen: "• 11-16 metres | 30-40 tonnes</br>• Bushy spout that can reach up to 5 metres</br>• Dark or black back; large white pectoral fins</br>• Small dorsal fin perched atop a hump</br>• Nearly always shows white underside of tail",
        copyright_photofr: "© C. Horvath, ROMM",
        copyright_photoen: "© C. Horvath, MMON",
        image: "Rorqual bosse_C. Horvath.JPG",
      },
      {
        value: "balacu",
        namefr: "BA - Petit rorqual",
        nameen: "BA - Minke whale",
        scientific_namefr: "Balaenoptera acutorostrata",
        scientific_nameen: "Balaenoptera acutorostrata",
        arctiquefr: "",
        arctiqueen: "",
        descriptionfr: "• 6 à 10 mètres | 6 à 10 tonnes</br>• Souffle très peu visible</br>• Dos gris foncé, noir ou brun avec une tache blanche sur les nageoires pectorales</br>• Nageoire dorsale en forme de crochet</br>• Ne montre pas la queue, fait des bonds ou des sauts hors de l’eau",
        descriptionen: "• 6-10 metres | 6-10 tonnes</br>• Inconspicuous spout</br>• Dark grey, black or brown back with a white spot on each pectoral fin</br>• Hook-shaped dorsal fin</br>• Does not show its tail; capable of breaching",
        copyright_photofr: "© ROMM",
        copyright_photoen: "© MMON",
        image: "Petit rorqual _ROMM.JPG",
      },
      {
        value: "balbor",
        namefr: "BB - Rorqual boréal",
        nameen: "BB - Sei whale",
        scientific_namefr: "Balaenoptera borealis",
        scientific_nameen: "Balaenoptera borealis",
        arctiquefr: "Espèce arctique",
        arctiqueen: "Arctic species",
        descriptionfr: "• 12 à 15 mètres | 20 à 30 tonnes</br>• Souffle pouvant atteindre 4,5 mètres</br>• Dos gris foncé, ventre est gris clair ou blanc</br>• Nageoire dorsale proéminente et courbée</br>• Ne montre pas la queue</br>• On voit le souffle et la nageoire dorsale en même temps, ce qui permet de le différencier des autres grands rorquals",
        descriptionen: "• 12-15 metres | 20-30 tonnes</br>• Spout up to 4.5 metres high</br>• Dark grey back; belly light grey or white</br>• Prominent, curved dorsal fin</br>• Does not show its tail</br>• The spout and the dorsal fin are seen at the same time, which helps distinguish this species from other large rorquals",
        copyright_photofr: "© Tim Marshall",
        copyright_photoen: "© Tim Marshall",
        image: "Rorqual boréal_ Tim Marshal.JPG",
      },
      {
        value: "escrob",
        namefr: "ER - Baleine grise",
        nameen: "ER - Gray Whale",
        scientific_namefr: "Eschrichtius robustus",
        scientific_nameen: "Eschrichtius robustus",
        arctiquefr: "Espèce arctique",
        arctiqueen: "Arctic species",
        descriptionfr: "• 11 à 15 mètres | 30 à 40 tonnes<br/>• Souffle en forme de cœur de 3 à 4 mètres<br/>• Corps gris tacheté de gris et de blanc<br/>• Petite bosse triangulaire à la place de la nageoire dorsale<br/>• Série de petites bosses le long de la dorsale jusqu’à la nageoire caudale<br/>• Montre la queue en plongeant",
        descriptionen: "• 11-15 metres | 30-40 tonnes<br/>• Heart-shaped spout 3 to 4 metres high<br/>• Body light to dark grey or grey-brown with white mottling<br/>• Small, low hump instead of dorsal fin<br/>• Fleshy bumps on tailstock<br/>• Tail shown during dives",
        copyright_photofr: "© ROMM",
        copyright_photoen: "© MMON",
        image: "Baleine grise_ROMM.JPG",
      },
      {
        value: "eubgla",
        namefr: "EG - Baleine noire de l'Atlantique Nord",
        nameen: "EG - North atlantic right whale",
        scientific_namefr: "Eubalaena glacialis",
        scientific_nameen: "Eubalaena glacialis",
        arctiquefr: "",
        arctiqueen: "",
        descriptionfr: "• 13 à 17 mètres | 30 et 70 tonnes</br>• Dos large, lisse et noir</br>• Absence de nageoire dorsale et de sillons ventraux</br>• Grosse tête avec des callosités blanches ou jaunes</br>• Souffle en forme de V</br>• Montre la queue noire en forme de Y",
        descriptionen: "• 13-17 metres | 30-70 tonnes</br>• Broad, smooth, black back</br>• No dorsal fin or ventral grooves</br>• Large head with white or yellow callosities</br>• V-shaped spout</br>• Y-shaped tail shown during dives",
        copyright_photofr: "© ROMM",
        copyright_photoen: "© MMON",
        image: "Baleine noire2_ROMM.JPG",
      },
      {
        value: "balmys",
        namefr: "BMY - Baleine boréale",
        nameen: "BMY - Bowhead whale",
        scientific_namefr: "Balaena mysticetus",
        scientific_nameen: "Balaena mysticetus",
        arctiquefr: "Espèce arctique",
        arctiqueen: "Arctic species",
        descriptionfr: "• 12 à 19 mètres | jusqu’à 100 tonnes</br>• Dos large, lisse et noir, menton blanc</br>• Absence de nageoire dorsale et de sillons ventraux</br>• Grosse tête noire et lisse – pas de callosités blanches ou jaunes</br>• Souffle en forme de « V »</br>• Montre la queue noire en forme de « Y »",
        descriptionen: "• 12-19 metres | up to 100 tonnes</br>• Broad, smooth and black back; white chin</br>• No dorsal fin or ventral grooves</br>• Large, smooth black head with no white or yellow callosities</br>• V-shaped spout</br>• Y-shaped tail shown during dives",
        copyright_photofr: "© Tim Stewart, WWF Canada",
        copyright_photoen: "© Tim Stewart, WWF Canada",
        image: "Baleine_boréale_Tim_Stewart_WWF_Canada.PNG",
      },
      {
        value: "balesp",
        namefr: "RSP - Rorqual sp.",
        nameen: "RSP - Rorqual sp.",
        scientific_namefr: "Pas de nom scientifique disponible",
        scientific_nameen: "No scientific name available",
        arctiquefr: "",
        arctiqueen: "",
        descriptionfr: "Impossible de déterminer l’espèce de rorqual",
        descriptionen: "Impossible to determine the species of whale",
        copyright_photofr: "",
        copyright_photoen: "",
        image: "rorqual sp.PNG",
      },
    ],
  },
  {
    categoryfr: "Baleines à dents",
    categoryen: "Toothed whales",
    libs: [
      {
        value: "delleu",
        namefr: "DL - Béluga",
        nameen: "DL - Beluga whale",
        scientific_namefr: "Delphinapterus leucas",
        scientific_nameen: "Delphinapterus leucas",
        arctiquefr: "",
        arctiqueen: "",
        descriptionfr: "• 3 à 4,5 mètres | 0,7 à 2 tonnes</br>• Les adultes sont blancs, les juvéniles sont gris et les nouveau-nés sont brun-gris</br>• Absence de nageoire dorsale, petite crête dorsale</br>• Montre parfois la queue lors de la plongée</br>• Souvent observé en groupe",
        descriptionen: "• 3-4.5 metres | 0.7-2 tonnes</br>• Adults are white, juveniles grey and newborns brownish-grey</br>• Dorsal fin absent; small dorsal ridge</br>• Occasionally shows its tail when it dives</br>• Often observed in groups ",
        copyright_photofr: "© ROMM",
        copyright_photoen: "© MMON",
        image: "Béluga.JPG",
      },
      {
        value: "phopho",
        namefr: "PP - Marsouin commun",
        nameen: "PP - Harbour porpoise",
        scientific_namefr: "Phocoena phocoena",
        scientific_nameen: "Phocoena phocoena",
        arctiquefr: "",
        arctiqueen: "",
        descriptionfr: "• 1,3 à 2 mètres | 50 à 70 kilogrammes</br>• Dos noir, flancs grisâtres et ventre blanc</br>• Petite nageoire triangulaire située près du milieu du dos</br>• Discret, il ne fait pas de cabrioles hors de l'eau ni d'éclaboussures",
        descriptionen: "• 1.3-2 metres | 50-70 kilograms</br>• Black back, greyish flanks and white belly</br>• Small, triangular dorsal fin in middle of back</br>• Discrete: does not splash or leap out of the water",
        copyright_photofr: "© ROMM",
        copyright_photoen: "© MMON",
        image: "Marsouin (1).JPG",
      },
      {
        value: "phymac",
        namefr: "PM- Cachalot macrocéphale",
        nameen: "PM - Sperm whale",
        scientific_namefr: "Physeter macrocephalus",
        scientific_nameen: "Physeter macrocephalus",
        arctiquefr: "",
        arctiqueen: "",
        descriptionfr: "• 11 à 18 mètres | jusqu’à 50 tonnes</br>• Souffle puissant et oblique </br>• Corps gris foncé ou brun, grosse tête carrée</br>• Nageoire dorsale petite et triangulaire, suivie d’une crête bosselée</br>• Longues séquences respiratoires d'environ 30 respirations à la surface de l’eau</br>• Montre la queue lors de la plongée",
        descriptionen: "• 11-18 metres | up to 50 tonnes</br>• Powerful, angled spout</br>• Dark grey or brown body; enormous, squarish head</br>• Small, triangular dorsal fin followed by a bumpy ridge</br>• Long breathing sequences of 30 or so inhalations at the surface</br>• Shows its tail when it dives",
        copyright_photofr: "© Groupe de recherche sur les cétacés",
        copyright_photoen: "© Groupe de recherche sur les cétacés",
        image: "Cachalot_Groupe de recherche sur les cétacés (8).JPG",
      },
      {
        value: "orcorc",
        namefr: "OO- Épaulard",
        nameen: "OO - Killer whale",
        scientific_namefr: "Orcinus orca",
        scientific_nameen: "Orcinus orca",
        arctiquefr: "",
        arctiqueen: "",
        descriptionfr: "• 6 à 9 mètres | 4 à 7 tonnes</br>• Souffle de 2 mètres de haut, peu visible</br>• Dos noir, avec un motif gris pâle derrière la nageoire dorsale</br>• Tache blanche présente derrière les yeux, le menton et le ventre sont blancs</br>• Nageoire dorsale triangulaire, pointue et très haute chez les mâles adultes",
        descriptionen: "• 6-9 metres | 4-7 tonnes</br>• Inconspicuous spout of up to 2 metres</br>• Black back with a light grey pattern behind the dorsal fin</br>• White patch behind the eyes; white chin and belly</br>• Triangular dorsal fin, high and pointy in adult males",
        copyright_photofr: "© Joël Detcheverry",
        copyright_photoen: "© Joël Detcheverry",
        image: "Épaulard Joel Detcheverry (2).JPG",
      },
      {
        value: "glomel",
        namefr: "GM - Globicéphale noir de l'Atlantique",
        nameen: "GM - Long-finned pilot whale",
        scientific_namefr: "Globicephala melas",
        scientific_nameen: "Globicephala melas",
        arctiquefr: "",
        arctiqueen: "",
        descriptionfr: "• 4 à 6 mètres | 1,5 à 3 tonnes</br>• Le dos est complètement noir ou brun foncé</br>• Nageoire dorsale charnue et fortement incurvée vers l’arrière</br>• Tête est en forme de globe, sans bec proéminent</br>• Souvent aperçu en très grand groupe de 20 à 50 individus",
        descriptionen: "• 4-6 metres | 1.5-3 tonnes</br>• Back completely black or dark brown</br>• Prominent and distinctly back-sweeping dorsal fin</br>• Head is globe-shaped and lacks a prominent beak</br>• Often spotted in sizable groups numbering between 20 and 50 individuals",
        copyright_photofr: "© Hilary Moors-Murphy",
        copyright_photoen: "© Hilary Moors-Murphy",
        image: "Globicephale_noir_Hilary_Moors_Murphy.JPG",
      },
      {
        value: "hypamp",
        namefr: "HA - Baleine à bec commune",
        nameen: "HA - Northern bottlenose whale",
        scientific_namefr: "Hyperoodon ampullatus",
        scientific_nameen: "Hyperoodon ampullatus",
        arctiquefr: "",
        arctiqueen: "",
        descriptionfr: "• 6 et 10 mètres | 3 à 7 tonnes</br>• Souffle en forme de ballon, rarement visible, peut atteindre 2 mètres</br>• Dos brun cannelle foncé</br>• Melon sur la tête et bec proéminent</br>• Nageoire dorsale en forme de petit crochet</br>• Souvent observée au large, en petit groupe",
        descriptionen: "• 6-10 metres | 3-7 tonnes</br>• Balloon-shaped spout up to 2 metres high, though rarely visible</br>• Dark cinnamon-brown back</br>• Prominent melon and beak</br>• Dorsal fin shaped like a small hook</br>• Often observed far offshore in small groups ",
        copyright_photofr: "© Hilary Moors-Murphy",
        copyright_photoen: "© Hilary Mo ors-Murphy",
        image: "Baleine à becH._Moors_Murphy.JPG",
      },
      {
        value: "monmon",
        namefr: "MM - Narval",
        nameen: "MM - Narwhal",
        scientific_namefr: "Monodon monoceros",
        scientific_nameen: "Monodon monoceros",
        arctiquefr: "Espèce arctique",
        arctiqueen: "Arctic species",
        descriptionfr: "• 4 à 6 mètres | 0,9 à 1,6 tonne</br>• Pas de nageoire dorsale, petite crête dorsale </br>• Dos tacheté de gris et de blanc, ventre blanc </br>• Observé souvent en groupe </br>• Chez la plupart des mâles, la dent de gauche pousse en spirale pour former une défense d’une longueur pouvant atteindre plus de 3 mètres</br><i>**Un narval est observé dans l’estuaire du Saint-Laurent avec un groupe de bélugas depuis 2016</i>",
        descriptionen: "• 4-6 metres | 0.9-1.6 tonnes</br>• Dorsal fin absent; small dorsal ridge</br>• Grey and white speckled back; belly white</br>• Often observed in groups</br>• In most males, the left tooth grows in a spiral to form a tusk that can reach over 3 metres long</br>**A narwhal has been observed in the St. Lawrence Estuary with a group of belugas since 2016",
        copyright_photofr: "© Paul Nicklen, National Geographic",
        copyright_photoen: "© Paul Nicklen, National Geographic",
        image: "Paul Nicklen, National Geographic.JPG",
      },
      {
        value: "lagacu",
        namefr: "LA - Dauphin à flancs blancs",
        nameen: "LA - Atlantic white-sided dolphin",
        scientific_namefr: "Lagenorhynchus acutus",
        scientific_nameen: "Lagenorhynchus acutus",
        arctiquefr: "",
        arctiqueen: "",
        descriptionfr: "• 2 à 2,7 mètres | 180 à 230 kilogrammes </br>• Dos noir, ventre blanc</br>• Présence d’une bande blanche et d’une tache jaune, sous la nageoire dorsale</br>• Grande nageoire dorsale en forme de crochet</br>• Déplacements rapides, avec des éclaboussures et parfois des sauts hors de l’eau</br>• Souvent aperçu en grand groupe",
        descriptionen: "• 2-2.7 metres | 180-230 kilograms</br>• Black back, white belly</br>• Presence of a white band and a yellow patch below the dorsal fin</br>• Large hook-shaped dorsal fin</br>• Fast swimmer, splashing and sometimes leaping out of the water</br>• Often seen in large groups ",
        copyright_photofr: "© ROMM",
        copyright_photoen: "© MMON",
        image: "Dauphin flancs blancs_ROMM (9).JPG",
      },
      {
        value: "lagalb",
        namefr: "LAL - Dauphin à nez blanc",
        nameen: "LAL - White-beaked dolphin",
        scientific_namefr: "Lagenorhynchus albirostris",
        scientific_nameen: "Lagenorhynchus albirostris",
        arctiquefr: "",
        arctiqueen: "",
        descriptionfr: "• 2 à 3 mètres | 135 à 275 kilogrammes</br>• Nez blanc, dos noir avec une selle blanche derrière la nageoire dorsale</br>• Grande nageoire dorsale en forme de crochet</br>• Déplacements rapides, avec des éclaboussures et parfois des sauts hors de l’eau</br>• Souvent aperçu en grand groupe",
        descriptionen: "• 2-3 metres | 135-275 kilograms</br>• White beak, black back with a white saddle behind the dorsal fin</br>• Large hook-shaped dorsal fin</br>• Fast swimmer, splashing and sometimes leaping out of the water</br>• Often seen in large groups ",
        copyright_photofr: "© Claude Nozères",
        copyright_photoen: "© Claude Nozères",
        image: "Dauphin nez blanc_Claude Nozères (2).JPG",
      },
      {
        value: "deldel",
        namefr: "DD - Dauphin commun à bec court",
        nameen: "DD - Short-beaked common dolphin",
        scientific_namefr: "Delphinus delphis",
        scientific_nameen: "Delphinus delphis",
        arctiquefr: "",
        arctiqueen: "",
        descriptionfr: "• 1,8 à 2,6 mètres | 75 à 115 kilogrammes</br>• Dos gris bleuté au noir. Deux grandes taches claires sur les flancs</br>• Grande nageoire dorsale en forme de crochet</br>• Déplacements rapides, avec des éclaboussures et parfois des sauts hors de l’eau</br>• Souvent aperçu en grand groupe",
        descriptionen: "• 1.8-2.6 metres | 75-115 kilograms</br>• Back ranges from bluish-grey to black; flanks characterized by two large light-coloured patches</br>• Large hook-shaped dorsal fin</br>• Fast swimmer, splashing and sometimes leaping out of the water</br>• Often seen in large groups",
        copyright_photofr: "© NOAA",
        copyright_photoen: "© NOAA",
        image: "Dauphin commun NOAA (9).JPG",
      },
      {
        value: "delsp",
        namefr: "DSP - Dauphin sp.",
        nameen: "DSP - Dolphin sp.",
        scientific_namefr: "Pas de nom scientifique disponible",
        scientific_nameen: "No scientific name available",
        arctiquefr: "",
        arctiqueen: "",
        descriptionfr: "Impossible de déterminer l’espèce de dauphin",
        descriptionen: "Impossible to determine the species of dolphin",
        copyright_photofr: "",
        copyright_photoen: "",
        image: "dauophin sp.JPG",
      },
    ],
  },
  {
    categoryfr: "Cétacé sp.",
    categoryen: "Cetacean sp.",
    libs: [
      {
        value: "cetsp",
        namefr: "CSP - Cétacé sp.",
        nameen: "CSP - Cetacean sp.",
        scientific_namefr: "Cétacé sp.",
        scientific_nameen: "Cetacean sp.",
        arctiquefr: "",
        arctiqueen: "",
        descriptionfr: "Impossible de déterminer l’espèce de baleine",
        descriptionen: "Impossible to determine the species of whale",
        copyright_photofr: "",
        copyright_photoen: "",
        image: "Cétacés sp.JPG",
      },
    ],
  },
  {
    categoryfr: "Phoques",
    categoryen: "Seals",
    libs: [
      {
        value: "halgry",
        namefr: "HG - Phoque gris",
        nameen: "HG - Grey seal",
        scientific_namefr: "Halichoerus grypus",
        scientific_nameen: "Halichoerus grypus",
        arctiquefr: "",
        arctiqueen: "",
        descriptionfr: "• 2 à 2,3 mètres | 150 à 350 kilogrammes</br>• Dos brun foncé, gris ou noir avec taches claires sur le cou</br>• Femelle plus petite et pâle</br>• Le museau est long et droit</br>• Profil de tête de cheval",
        descriptionen: "• 2-2.3 metres | 150-350 kilograms</br>• Dark brown, grey or black back with light spots on the neck; female smaller and paler</br>• Long, straight snout (horse-like profile)</br>",
        copyright_photofr: "© ROMM",
        copyright_photoen: "© MMON",
        image: "Phoque gris ROMM.JPG",
      },
      {
        value: "phovit",
        namefr: "PV - Phoque commun",
        nameen: "PV - Harbor seal",
        scientific_namefr: "Phoca vitulina",
        scientific_nameen: "Phoca vitulina",
        arctiquefr: "",
        arctiqueen: "",
        descriptionfr: "• 1,5 à 1,8 mètre | 100 kilogrammes</br>• Dos tacheté gris clair à gris foncé ou brun</br>• Le ventre est plus clair</br>• Tête ronde, museau court</br>• Profil de tête de chien",
        descriptionen: "• 1.5-1.8 metres | 100 kilograms</br>• Back variable shades of grey or brown, spotted; belly lighter</br>• Round head, short snout (dog-like profile)",
        copyright_photofr: "© ROMM",
        copyright_photoen: "© MMON",
        image: "Phoque commun ROMM ).JPG",
      },
      {
        value: "phogro",
        namefr: "PG - Phoque du Groenland",
        nameen: "PG - Harp seal",
        scientific_namefr: "Pagophilus groenlandicus",
        scientific_nameen: "Pagophilus groenlandicus",
        arctiquefr: "",
        arctiqueen: "",
        descriptionfr: "• 1,6 mètre | 130 à 150 kilogrammes</br>• Dos blanc argenté</br>• Tache noire en forme de fer à cheval chez l’adulte</br>• Petite tête noire, nez pointu</br>• Se regroupe en grand troupeau  sur les glaces lors de la mise bas ou de la mue",
        descriptionen: "• 1.6 metres | 130-150 kilograms</br>• Silvery back; black, horseshoe-shaped band in adults</br>• Small black head, pointy snout</br>• Congregates in large herds on ice to give birth or moult",
        copyright_photofr: "© J-P Sylvestre",
        copyright_photoen: "© J-P Sylvestre",
        image: "Phoque Groenland J-P Sylvestre (2).JPG",
      },
      {
        value: "cyscri",
        namefr: "CC - Phoque à capuchon",
        nameen: "CC - Hooded seal",
        scientific_namefr: "Cystophora cristata",
        scientific_nameen: "Cystophora cristata",
        arctiquefr: "",
        arctiqueen: "",
        descriptionfr: "• 2,2 à 2,6 mètres | 160 à 300 kilogrammes</br>• Dos argenté recouvert de taches noires</br>• Tête noire jusque derrière les yeux</br>• Poche noire suspendue sur le museau que le mâle peut gonfler, ressemblant à un capuchon</br>• Le septum nasal peut aussi sortir pour former un ballon rouge au niveau des narines du mâle</br>• La femelle est plus petite et n’a pas de capuchon",
        descriptionen: "• 2.2-2.6 metres | 160-300 kilograms</br>• Silvery back covered with black spots</br>• Black head as far back as the eyes</br>• Males feature a black, hood-like, inflatable bladder that hangs from their snout</br>• They can also inflate a red balloon-like sac from their nostrils</br>• Females are smaller and lack hood",
        copyright_photofr: "© J-P Sylvestre",
        copyright_photoen: "© J-P Sylvestre",
        image: "Phoque-à-capuchon-septum-JP.JPG",
      },
      {
        value: "eribar",
        namefr: "EB - Phoque barbu",
        nameen: "EB - Bearded seal",
        scientific_namefr: "Erignatus barbatus",
        scientific_nameen: "Erignatus barbatus",
        arctiquefr: "",
        arctiqueen: "",
        descriptionfr: "• 2,5 mètres | 300 à 400 kilogrammes</br>• Dos gris, bleu gris ou brun chocolat</br>• Tête de couleur brunâtre à rougeâtre</br>• Gros museau arrondi, moustaches très apparentes, longues et denses",
        descriptionen: "• 2.5 metres | 300-400 kilograms</br>• Grey, bluish-grey or chocolate-coloured back</br>• Head brownish to reddish in colour</br>• Large, rounded snout; long, dense and conspicuous whiskers ",
        copyright_photofr: "© NOAA",
        copyright_photoen: "© NOAA",
        image: "Phoque barbu_Budd Christman, NOAA.JPG",
      },
      {
        value: "pushis",
        namefr: "PH - Phoque annelé",
        nameen: "PH - Ringed seal",
        scientific_namefr: "Phoca hispida",
        scientific_nameen: "Phoca hispida",
        arctiquefr: "",
        arctiqueen: "",
        descriptionfr: "• 1,4 mètre | 50 à 68 kilogrammes</br>• Petit phoque très présent en Arctique</br>• Dos gris avec des taches foncées encerclées d’anneaux gris pâle</br>• Museau court et arrondi, grands yeux rapprochés",
        descriptionen: "• 1.4 metres | 50-68 kilograms</br>• Small, common seal of the Arctic</br>• Grey back with dark spots encircled by light grey rings</br>• Short, rounded snout; large eyes close together",
        copyright_photofr: "© NOAA",
        copyright_photoen: "© NOAA",
        image: "Phoque annelé_NOAA_1.JPG",
      },
      {
        value: "phosp",
        namefr: "PSP - Phoque sp.",
        nameen: "PSP - Seal sp.",
        scientific_namefr: "Pas de nom scientifique disponible",
        scientific_nameen: "No scientific name available",
        arctiquefr: "",
        arctiqueen: "",
        descriptionfr: "Impossible de déterminer l’espèce de phoque",
        descriptionen: "Impossible to determine the species of seal",
        copyright_photofr: "",
        copyright_photoen: "",
        image: "Phoque sp.JPG",
      },
    ],
  },
  {
    categoryfr: "Autres espèces",
    categoryen: "Other species",
    libs: [
      {
        value: "ursmar",
        namefr: "TM - Ours blanc",
        nameen: "TM - Polar bear",
        scientific_namefr: "Ursus maritimus",
        scientific_nameen: "Ursus maritimus",
        arctiquefr: "",
        arctiqueen: "",
        descriptionfr: "• 2,4 à 2,6 mètres | 400 à 600 kilogrammes</br>• Fourrure de couleur blanc crème à jaune</br>• Corps et cou allongés, tête étroite et petites oreilles</br>• Espèce principalement vue en Arctique",
        descriptionen: "• 2.4-2.6 metres | 400-600 kilograms</br>• Fur creamy white to yellowish</br>• Long body and neck, narrow head and small ears</br>• Mainly present in the Arctic",
        copyright_photofr: "© Ansgar Walk",
        copyright_photoen: "© Ansgar Walk",
        image: "Ours_Ansgar Walk.JPG",
      },
      {
        value: "odoros",
        namefr: "OR - Morse de l'Atlantique",
        nameen: "OR - Walrus",
        scientific_namefr: "Odobenus rosmarus",
        scientific_nameen: "Odobenus rosmarus",
        arctiquefr: "",
        arctiqueen: "",
        descriptionfr: "• 2,7 à 3 mètres | 560 à 900 kilogrammes</br>• Dos brun foncé</br>• Tête munie de longues canines ou défenses. Celles des mâles sont plus longues et plus grosses que les femelles",
        descriptionen: "• 2.7-3 metres | 560-900 kilograms</br>• Dark brown back</br>• Presence of canines or tusks, which are larger and longer in males",
        copyright_photofr: "© Ansgar Walk",
        copyright_photoen: "© Ansgar Walk",
        image: "Morse_Ansgar Walkjpg.JPG",
      },
      {
        value: "dercor",
        namefr: "DECO - Tortue luth",
        nameen: "DECO - Leatherback turtle",
        scientific_namefr: "Dermochelys coriacea",
        scientific_nameen: "Dermochelys coriacea",
        arctiquefr: "",
        arctiqueen: "",
        descriptionfr: "• 2 mètres | 900 kilogrammes</br>• Carapace formée d'un épais cartilage recouvert d'une peau coriace ayant l'aspect du cuir avec sept crêtes longitudinales</br>• Tache rose sur le dessus de la tête",
        descriptionen: "• 2 metres | 900 kilograms</br>• Shell composed of thick cartilage covered with tough, leathery skin and seven longitudinal ridges</br>• Pink spot on top of head ",
        copyright_photofr: "© Joël Detcheverry",
        copyright_photoen: "© Joël Detcheverry",
        image: "STortue.JPG",
      },
      {
        value: "cetmax",
        namefr: "CM - Requin pèlerin",
        nameen: "CM - Basking shark",
        scientific_namefr: "Cetorhinus maximus",
        scientific_nameen: "Cetorhinus maximus",
        arctiquefr: "",
        arctiqueen: "",
        descriptionfr: "• 6 à 10 mètres | 5 200 kilogrammes</br>• Nageoire dorsale triangulaire et arrondie</br>• Bout de la queue pointue parfois visible en même temps que la nageoire dorsale</br>• Nage lentement à la surface",
        descriptionen: "• 6-10 metres | 5,200 kilograms</br>• Triangular, rounded dorsal fin</br>• Pointy-tipped tail occasionally visible at the same time as the dorsal fin</br>• Swims slowly near the surface",
        copyright_photofr: "© ROMM",
        copyright_photoen: "© MMON",
        image: "Requin pelerin, ROMM (2).JPG",
      },
      {
        value: "molmol",
        namefr: "MOLA - Poisson-lune",
        nameen: "MOLA - Sunfish",
        scientific_namefr: "Mola mola",
        scientific_nameen: "Mola mola",
        arctiquefr: "",
        arctiqueen: "",
        descriptionfr: "• 1,8 à 3 mètres | 1 000 à 2 000 kilogrammes</br>• Grosse tête, corps arrondi et plat</br>• Dos argenté, parfois tacheté, ventre plus clair</br>• Nageoires dorsales et anales très longues</br>• Queue absente",
        descriptionen: "• 1.8-3 metres | 1,000-2,000 kilograms</br>• Large head; flat, rounded body</br>• Silvery back, sometimes spotted; lighter belly</br>• Very long dorsal and anal fins</br>• No tail ",
        copyright_photofr: "© wikipedia.org",
        copyright_photoen: "© wikipedia.org",
        image: "Poissonlune_mole3.JPG",
      },
      {
        value: "thuthy",
        namefr: "TT - Thon rouge de l’Atlantique",
        nameen: "TT - Atlantic bluefin tuna",
        scientific_namefr: "Thunnus thynnus",
        scientific_nameen: "Thunnus thynnus",
        arctiquefr: "",
        arctiqueen: "",
        descriptionfr: "• 3,3 mètres | 725 kilogrammes</br>• Dos bleu foncé ou noir, flancs bleu clair et ventre gris argenté.</br>• Deux nageoires dorsales</br>• Queue aux extrémités pointues</br>• Déplacement en grand nombre lors des migrations",
        descriptionen: "• 3.3 metres | 725 kilograms</br>• Dark blue or black back, light blue flanks and silver-grey belly</br>• Two dorsal fins</br>• Pointy-tipped tail</br>• Migrates in large groups",
        copyright_photofr: "© balfego.com",
        copyright_photoen: "© balfego.com",
        image: "thon rouge.JPG",
      },
      {
        value: "tessp",
        namefr: "Tortue sp.",
        nameen: "Turtle sp.",
        scientific_namefr: "Pas de nom scientifique disponible",
        scientific_nameen: "No scientific name available",
        arctiquefr: "",
        arctiqueen: "",
        descriptionfr: "Impossible de déterminer l’espèce de tortue",
        descriptionen: "Impossible to determine the species of turtle",
        copyright_photofr: "",
        copyright_photoen: "",
        image: "Tortue sp.JPG",
      },
      {
        value: "eussp",
        namefr: "Requin sp.",
        nameen: "Shark sp.",
        scientific_namefr: "Pas de nom scientifique disponible",
        scientific_nameen: "No scientific name available",
        arctiquefr: "",
        arctiqueen: "",
        descriptionfr: "Impossible de déterminer l’espèce de requin",
        descriptionen: "Impossible to determine the species of shark",
        copyright_photofr: "",
        copyright_photoen: "",
        image: "Requin sp.PNG",
      },
    ],
  },
]


const Timezone = [
  {
    value: "America/Chicago",
    textfr: "Heure centrale (États-Unis et Canada)",
    texten: "Central Time (US & Canada)",
  },
  {
    value: "America/Toronto",
    textfr: "Heure de l'Est (États-Unis et Canada)",
    texten: "Eastern Time (US & Canada)",
  },
  {
    value: "America/Halifax",
    textfr: "Heure de l'atlantique",
    texten: "Atlantic time",
  },
  {
    value: "America/St_Johns",
    textfr: "Newfoundland",
    texten: "Newfoundland",
  },
  {
    value: "Europe/Paris",
    textfr: "Brussels, Copenhagen, Madrid, Paris",
    texten: "Brussels, Copenhagen, Madrid, Paris",
  },
  {
    value: "-12:00",
    textfr: "UTC-12:00",
    texten: "UTC-12:00",
  },
  {
    value: "-11:00",
    textfr: "UTC-11:00",
    texten: "UTC-11:00",
  },
  {
    value: "-10:00",
    textfr: "UTC-10:00",
    texten: "UTC-10:00",
  },
  {
    value: "-09:00",
    textfr: "UTC-09:00",
    texten: "UTC-09:00",
  },
  {
    value: "-08:00",
    textfr: "UTC-08:00",
    texten: "UTC-08:00",
  },
  {
    value: "-07:00",
    textfr: "UTC-07:00",
    texten: "UTC-07:00",
  },
  {
    value: "-06:00",
    textfr: "UTC-06:00",
    texten: "UTC-06:00",
  },
  {
    value: "-05:00",
    textfr: "UTC-05:00",
    texten: "UTC-05:00",
  },
  {
    value: "-04:00",
    textfr: "UTC-04:00",
    texten: "UTC-04:00",
  },
  {
    value: "-03:00",
    textfr: "UTC-03:00",
    texten: "UTC-03:00",
  },
  {
    value: "-03:30",
    textfr: "UTC-03:30",
    texten: "UTC-03:30",
  },
  {
    value: "-02:00",
    textfr: "UTC-02:00",
    texten: "UTC-02:00",
  },
  {
    value: "-01:00",
    textfr: "UTC-01:00",
    texten: "UTC-01:00",
  },
  {
    value: "+00:00",
    textfr: "UTC",
    texten: "UTC",
  },
  {
    value: "+01:00",
    textfr: "UTC+01:00",
    texten: "UTC+01:00",
  },
  {
    value: "+02:00",
    textfr: "UTC+02:00",
    texten: "UTC+02:00",
  },
  {
    value: "+03:00",
    textfr: "UTC+03:00",
    texten: "UTC+03:00",
  },
  {
    value: "+03:30",
    textfr: "UTC+03:30",
    texten: "UTC+03:30",
  },
  {
    value: "+04:00",
    textfr: "UTC+04:00",
    texten: "UTC+04:00",
  },
  {
    value: "+05:00",
    textfr: "UTC+05:00",
    texten: "UTC+05:00",
  },
  {
    value: "+05:30",
    textfr: "UTC+05:30",
    texten: "UTC+05:30",
  },
  {
    value: "+07:00",
    textfr: "UTC+07:00",
    texten: "UTC+07:00",
  },
  {
    value: "+08:00",
    textfr: "UTC+08:00",
    texten: "UTC+08:00",
  },
  {
    value: "+09:00",
    textfr: "UTC+09:00",
    texten: "UTC+09:00",
  },
  {
    value: "+09:30",
    textfr: "UTC+09:30",
    texten: "UTC+09:30",
  },
  {
    value: "+10:00",
    textfr: "UTC+10:00",
    texten: "UTC+10:00",
  },
  {
    value: "+11:00",
    textfr: "UTC+11:00",
    texten: "UTC+11:00",
  },
  {
    value: "+12:00",
    textfr: "UTC+12:00",
    texten: "UTC+12:00",
  },

]

function getConstantName(dictionnary, value, nameKeyPrefix, locale) {
  return dictionnary.find((elt) => elt.value == value)[
    `${nameKeyPrefix}${locale}`
  ];
}

export { Membres, Certitude, Distance, Visibilite, Vent, Plateforme, Especes, Timezone, getConstantName };