import moment from "moment";


function formatUTCDateToLocalTimezone(dateUTC) {
    const dateStart = moment(dateUTC, [
        "YYYY-MM-DDTHH:mm:ssZ",
    ])
        .local()
        .format();
    return (
        dateStart.substring(0, 10) +
        " " +
        dateStart.substring(11, 19) +
        " UTC" +
        dateStart.substring(19)
    )
}

export { formatUTCDateToLocalTimezone }
