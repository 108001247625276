<template>
  <span>
    <span class="pill" v-if="$store.state.pwa.isOnline">
      <img class="icon" src="/assets/full-connection.svg" />
      <span>{{ $t("connection-badge-connected") }}</span>
    </span>
    <span class="pill" v-else>
      <img class="icon" src="/assets/no-connection.svg" />
      <span>{{ $t("connection-badge-not-connected") }}</span>
    </span>
  </span>
</template>

<script>
export default {
  props: [],
  data() {
    return {};
  },
};
</script>

<style scoped>
.icon {
  width: 25px;
  margin-right: 5px;
}

.pill {
  display: inline-block;
  font-size: 75%;
  font-weight: 700;
  line-height: 1;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: 0.25rem;
  transition: color 0.15s ease-in-out, background-color 0.15s;
  padding-right: 1.5em;
  padding-left: 1.3em;
  border-radius: 10rem;
  color: #373737;
  background-color: #dedede;
  font-family: Montserrat;
  padding-top: 0.4em;
  padding-bottom: 0.4em;
}

.pill > * {
  vertical-align: middle;
}
</style>
