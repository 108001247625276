import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

import { BootstrapVue, BootstrapVueIcons } from 'bootstrap-vue'

// Import Bootstrap and BootstrapVue CSS files (order is important)
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'

import './assets/all-fontawesome.css'

import Vuelidate from 'vuelidate'

import VueI18n from 'vue-i18n'
import translationsEn from './assets/i18n/en.json'
import translationsFr from './assets/i18n/fr.json'
import Multiselect from 'vue-multiselect'

import 'vue-multiselect/dist/vue-multiselect.min.css'

import VueLayers from 'vuelayers'

import { setEnvironment } from '@/commons/ogsl-auth.js'
import './registerServiceWorker'

setEnvironment(process.env.VUE_APP_OGSL_AUTH_ENVIRONMENT)

Vue.use(VueI18n)
Vue.use(Vuelidate)
// Install BootstrapVue
Vue.use(BootstrapVue)
// Optionally install the BootstrapVue icon components plugin
Vue.use(BootstrapVue)
Vue.use(BootstrapVueIcons)
Vue.component('multiselect', Multiselect)
Vue.use(VueLayers)

const languages = ['fr', 'en']
const i18n = new VueI18n({
  locale: navigator.language && languages.includes(navigator.language.substring(0, 2)) ? navigator.language.substring(0, 2) : languages[0],
  messages: { en: translationsEn, fr: translationsFr }
})

Vue.config.productionTip = false

new Vue({
  router,
  store,
  i18n,
  render: h => h(App, Multiselect)
}).$mount('#app')
