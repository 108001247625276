<template>
  <div>
    <b-form-group id="input-group-date" label="Date" label-for="input-date">
      <div class="icon-positionning">
        <b-form-datepicker
          id="input-date"
          v-model="timeForm.date"
          required
          class="b-components-size"
          :locale="$i18n.locale"
          :max="dateMax"
          :min="dateMin"
        ></b-form-datepicker>
      </div>
    </b-form-group>
    <b-form-group
      id="input-group-time"
      :label="$t('hour')"
      label-for="input-time"
    >
      <b-input-group class="mb-3 b-components-size">
        <b-form-input
          id="input-time"
          v-model="timeForm.time"
          type="text"
          placeholder="HH:mm:ss"
          required
        ></b-form-input>
        <b-form-select
          id="input-timezone"
          v-model="timeForm.tzSelection"
          :options="timezoneOptions"
          :text-field="textSelection"
        ></b-form-select>
        <b-input-group-append>
          <b-form-timepicker
            v-model="timeForm.time"
            button-only
            right
            show-seconds
            aria-controls="time-input"
          ></b-form-timepicker>
        </b-input-group-append>
        <span id="utcDate">UTC : {{ dateUTC }}</span>
      </b-input-group>
      <div class="error" v-if="!$v.timeForm.time.required">
        {{ $t("field-required") }}
      </div>
      <div class="error" v-if="dateUTC == 'Invalid date'">
        {{ $t("invalid_time") }}
      </div>
    </b-form-group>
  </div>
</template>

<script>
import { Timezone } from "@/constants";

import { required } from "vuelidate/lib/validators";
import moment from "moment";
import momentTimezone from "moment-timezone";

// @ is an alias to /src
export default {
  name: "TimeFormFields",
  components: {},
  props: ["defaultDate"],
  data() {
    const defautTz = Timezone.find((elt) => elt.value == moment.tz.guess());

    return {
      timezoneOptions: Timezone,
      dateMax: "",
      dateMin: "",
      timeForm: {
        date: "",
        time: "",
        tzSelection: defautTz
          ? defautTz.value
          : moment().format("Z").substring(0, 6),
      },
    };
  },
  validations() {
    return {
      timeForm: {
        time: {
          required,
        },
      },
    };
  },

  mounted() {
    this.timeForm.date = moment().format("YYYY-MM-DD");
    this.dateMax = moment().format("YYYY-MM-DD");
    this.timeForm.time = moment().format("HH:mm:ss");
  },
  methods: {},
  computed: {
    textSelection() {
      return `text${this.$i18n.locale}`;
    },
    dateUTC() {
      if (!this.timeForm.tzSelection) return "Invalid date";

      let out = null;

      if (
        this.timeForm.tzSelection.startsWith("+") ||
        this.timeForm.tzSelection.startsWith("-")
      ) {
        out = moment
          .parseZone(
            this.timeForm.date +
              "T" +
              this.timeForm.time +
              this.timeForm.tzSelection
          )
          .utc()
          .format();
      } else {
        out = momentTimezone
          .tz(
            this.timeForm.date + " " + this.timeForm.time,
            this.timeForm.tzSelection
          )
          .utc()
          .format();
      }

      if (out != "Invalid date") {
        out = out.slice(0, -1) + ".000Z";
      }
      return out;
    },
  },
  watch: {
    dateUTC: function (newValue) {
      this.$emit("dateUTC-changed", newValue);
    },
    defaultDate: function (newValue) {
      if (!newValue) return;

      this.timeForm.date = moment(newValue, ["YYYY-MM-DDTHH:mm:ssZ"])
        .local()
        .format("YYYY-MM-DD");
    },
  },
};
</script>

<style scoped>
#utcDate {
  flex: 0 1 100%;
}

.error {
  color: red;
}
</style>
