<template>
  <b-col id="imgCopyright" sm="6">
    <div style="padding: 56.25% 0 0 0; position: relative">
      <iframe
        :src="url"
        style="position: absolute; top: 0; left: 0; width: 100%; height: 100%"
        frameborder="0"
        allow="autoplay; fullscreen; picture-in-picture"
        allowfullscreen
      ></iframe>
    </div>
  </b-col>
</template>

<script>
// @ is an alias to /src
export default {
  name: "HomeVideo",
  props: ["url"],
  components: {},
  data() {
    return {};
  },
};
</script>

<style scoped>
.buttons-container,
.photos_observateurs_container {
  display: flex;
  flex-direction: column;
  width: 100%;
  text-align: center;
}
.span-space {
  margin-top: 10px;
  font-size: 13pt;
}
.span-space .btn {
  font-size: 13pt;
}

.bold {
  font-weight: bold;
}

#titreButtonChoice,
#buttonChoice,
#imgCopyright {
  margin-bottom: 20px;
}
</style>
