<template>
  <div>
    <ObservationForm :isOffline="true" :isMember="false" />
    <ReconnectionModal id="modal-reconnection" />
  </div>
</template>

<script>
import ObservationForm from "@/components/ObservationForm";
import ReconnectionModal from "@/components/ReconnectionModal.vue";

// @ is an alias to /src
export default {
  name: "ObservationOfflineCitizen",
  components: {
    ObservationForm,
    ReconnectionModal,
  },
  data() {
    return {};
  },
  mounted() {},
  methods: {},
  computed: {},
};
</script>

<style scoped>
</style>
