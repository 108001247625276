<template>
  <div>
    <EffortCourantSummary
      style="max-width: 1140px; margin: 0 auto"
      v-if="currentEffortData"
      :currentEffortData="currentEffortData"
    />
    <ObservationForm
      :isOffline="true"
      :isMember="true"
      :currentEffortData="currentEffortData"
    />
    <ReconnectionModal id="modal-reconnection" />
  </div>
</template>

<script>
import ObservationForm from "@/components/ObservationForm";
import ReconnectionModal from "@/components/ReconnectionModal.vue";
import { getCurrentSession } from "@/storage/session-management";
import EffortCourantSummary from "@/components/EffortCourantSummary";

// @ is an alias to /src
export default {
  name: "ObservationOfflineMember",
  components: {
    ObservationForm,
    ReconnectionModal,
    EffortCourantSummary,
  },
  data() {
    return {
      currentEffortData: null,
    };
  },
  async mounted() {
    this.currentEffortData = await getCurrentSession();
  },
  methods: {},
  computed: {},
};
</script>

<style scoped>
</style>
