<template>
  <div>
    <div
      style="text-align: center; padding-top: 30px; font-size: 13pt"
      v-if="showPublicationLink"
    >
      Vous avez des observation(s) et/ou séance(s) en attente de publication
      <div>
        <b-button
          variant="primary"
          @click="$router.push('/observations-en-attente')"
          >Aller à la page de publication</b-button
        >
      </div>
    </div>
    <HomeContentOnline v-if="$store.state.pwa.isOnline" />
    <HomeContentOffline v-else />
  </div>
</template>

<script>
import HomeContentOffline from "@/components/HomeContentOffline.vue";
import HomeContentOnline from "@/components/HomeContentOnline.vue";
import { db } from "@/storage/indexedDB";
import { liveQuery } from "dexie";

// @ is an alias to /src
export default {
  name: "Home",
  components: {
    HomeContentOnline,
    HomeContentOffline,
  },
  data() {
    return { unpublishedObservations: [], sessions: [] };
  },
  mounted() {
    liveQuery(() => db.observations.toArray()).subscribe({
      next: (result) => (this.unpublishedObservations = result),
      error: (error) => console.error(error),
    });
    liveQuery(() => db.sessions.toArray()).subscribe({
      next: (result) => (this.sessions = result),
      error: (error) => console.error(error),
    });
  },
  methods: {},
  computed: {
    showPublicationLink() {
      const hasUnpublishedObservations =
        this.unpublishedObservations.length > 0;
      const hasUnpublishedSessions =
        this.sessions.filter((session) => !session.sessionObject.id).length > 0;
      return hasUnpublishedObservations || hasUnpublishedSessions;
    },
  },
};
</script>

<style scoped>
</style>
