<template>
  <div>
    <b-card no-body class="mb-1">
      <b-card-header class="p-1" header-tag="header" role="tab">
        <b-button
          block
          v-b-toggle:[id]
          variant="info"
          class="accordion-color"
          :disabled="disabled"
          >{{ sectionTitle }}</b-button
        >
      </b-card-header>
      <b-collapse :id="id" visible role="tabpanel" :accordion="accordionGroup">
        <b-card-body>
          <b-card-text>
            <slot></slot>
          </b-card-text>
        </b-card-body>
      </b-collapse>
    </b-card>
  </div>
</template>

<script>
// @ is an alias to /src
export default {
  name: "ObservationFormSection",
  components: {},
  props: ["id", "sectionTitle", "disabled", "accordionGroup"],
  methods: {},
  computed: {},
};
</script>

<style scoped>
.accordion-color {
  background-color: #169997 !important;
  border-color: #169997 !important;
}
</style>
