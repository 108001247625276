// import axios from 'axios'

const state = {
  ogslUserData: null
}

const getters = {
  ogslUserData(state) {
    return state.ogslUserData
  },
  userName(state) {
    return (state.ogslUserData ? state.ogslUserData.name : null)
  }
}
const actions = {}

const mutations = {
  setOgslUserData(state, data) {
    state.ogslUserData = data
  }
}

export default {
  state: state,
  getters,
  actions,
  mutations: mutations
}
