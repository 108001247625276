import axios from 'axios'
import { getCookie } from 'tiny-cookie'

const state = {
  isSendObservationInProgress: false,
  idEffort: null
}

const getters = {
  isSendObservationInProgress (state) {
    return state.isSendObservationInProgress
  },
  idEffort (state) {
    return state.idEffort
  }
}

function pushImageToMediadatabase(formData) {
  return axios({
    url: '/api/images',
    method: 'post',
    baseURL: process.env.VUE_APP_MEDIADATABASE_BASE_URL,
    headers: {
      'API-KEY': 'd4bea5c9-5cb4-40e2-a391-638c0ef1bfa2'
    },
    data: formData
  })
}

function pushObservationToBiohub(observation) {
  let sessionIdData = null

  if (getCookie('sessionId')) {
    sessionIdData = getCookie('sessionId')
  }
  return axios({
    url: '/api/eventDTOs',
    method: 'post',
    baseURL: process.env.VUE_APP_BIOHUB_BASE_URL,
    contentType: 'application/json; charset=utf-8',
    headers: {
      'API-KEY': 'a9951b55-5212-46e7-ac8e-440005264df3',
      'X-AUTH-TOKEN': sessionIdData
    },
    data: observation
  })
}

function pushImageAndObservation(observation, resolve, reject) {
  const fileInput = document.getElementById('photo_sans_effort')

  if (fileInput.files.length === 0) {
    pushObservationToBiohub(observation).then(response => {
      resolve(response)
    }).catch(err => {
      reject(err)
    })
  } else {
    const file = fileInput.files[0]
    const formData = new FormData()
    formData.append('file', file)
    pushImageToMediadatabase(formData).then(reponse => {
      const currentimage = reponse.data
      observation.simplifiedOccurrences[0].occurrenceMedias = [{ mediaId: currentimage.accessKey }]
      pushObservationToBiohub(observation).then(response => {
        resolve(response)
      }).catch(err => {
        reject(err)
      })
    })
}
}

const actions = {
  postObservation (context, observation) {

    if (state.idEffort != null) {
      observation.parentEventId = state.idEffort
    }

    return new Promise((resolve, reject) => {
      if (state.idEffort != null) {
        axios({
          url: '/api/eventDTOs/getEventDTOFromFilters',
          method: 'get',
          baseURL: process.env.VUE_APP_BIOHUB_BASE_URL,
          contentType: 'application/json; charset=utf-8',
          headers: {
            'API-KEY': 'a9951b55-5212-46e7-ac8e-440005264df3',
            'X-AUTH-TOKEN': getCookie('sessionId')
          },
          params: {
            currentuser: true,
            parentonly: true,
            hasoccurrence: false,
            sortbyhubevent: true,
            max: 5,
            offset: 0
          }
        }).then(response => {
          // console.log(response)
          let effortToujoursOuvert = true
          response.data.forEach(element => {
            const extradataData = JSON.parse(element.extradata.data)
            if (extradataData.effortclosed === false) {
              effortToujoursOuvert = false
            }
          })

          if (effortToujoursOuvert === true) {
            state.messageErrorEffort = true
            throw state.messageErrorEffort
          }

          pushImageAndObservation(observation, resolve, reject)
        }).catch(err => {
          reject(err)
        })
      } else {
        pushImageAndObservation(observation, resolve, reject)
      }
    })
  }
}

const mutations = {
  isSendObservationInProgress (state, value) {
    state.isSendObservationInProgress = value
  },
  idEffort (state, value) {
    state.idEffort = value
  }
}

export default {
  state: state,
  getters,
  actions,
  mutations: mutations
}
