import axios from 'axios'
import { getCookie } from 'tiny-cookie'

const state = {
  endEffort: true,
  hubIdEffort: null,
  mmsiEffort: null,
  initialEffort: null,
  plateformeEffort: null,
  nbPassengersEffort: null,
  shipEffort: null,
  partnerEffort: null,
  startDateEffort: null,
  startIdEffort: null,
  messageErrorEffort: null
}

const getters = {
  endEffort(state) {
    return state.endEffort
  },
  hubIdEffort(state) {
    return state.hubIdEffort
  },
  mmsiEffort(state) {
    return state.mmsiEffort
  },
  initialEffort(state) {
    return state.initialEffort
  },
  plateformeEffort(state) {
    return state.plateformeEffort
  },
  nbPassengersEffort(state) {
    return state.nbPassengersEffort
  },
  shipEffort(state) {
    return state.shipEffort
  },
  partnerEffort(state) {
    return state.partnerEffort
  },
  startDateEffort(state) {
    return state.startDateEffort
  },
  startIdEffort(state) {
    return state.startIdEffort
  },
  messageErrorEffort(state) {
    return state.messageErrorEffort
  }
}

const actions = {
  postStartEffort(context, observation) {
    var sessionIdData = getCookie('sessionId')

    return new Promise((resolve, reject) => {
      axios({
        url: '/api/eventDTOs/getEventDTOFromFilters',
        method: 'get',
        baseURL: process.env.VUE_APP_BIOHUB_BASE_URL,
        contentType: 'application/json; charset=utf-8',
        headers: {
          'API-KEY': 'a9951b55-5212-46e7-ac8e-440005264df3',
          'X-AUTH-TOKEN': getCookie('sessionId')
        },
        params: {
          currentuser: true,
          parentonly: true,
          hasoccurrence: false,
          sortbyhubevent: true,
          max: 5,
          offset: 0
        }
      }).then(response => {
        // console.log(response)
        response.data.forEach(element => {
          const extradataData = JSON.parse(element.extradata.data)
          if (extradataData.effortclosed === false) {
            state.messageErrorEffort = true
            throw state.messageErrorEffort
          }
        })
        axios({
          url: '/api/eventDTOs',
          method: 'post',
          baseURL: process.env.VUE_APP_BIOHUB_BASE_URL,
          contentType: 'application/json; charset=utf-8',
          headers: {
            'API-KEY': 'a9951b55-5212-46e7-ac8e-440005264df3',
            'X-AUTH-TOKEN': sessionIdData
          },
          data: observation
        }).then(response => {
          this.commit('startIdEffort', response.data.id)
          resolve(response)
        }).catch(err => {
          reject(err)
        })
      }).catch(err => {
        reject(err)
      })
    })
  },
  postEndEffort(context, observation) {
    var hubIdData = state.hubIdEffort
    var observationData = observation
    var sessionIdData = getCookie('sessionId')

    return new Promise((resolve, reject) => {
      axios({
        url: '/api/eventDTOs/getEventDTOFromHubId',
        method: 'get',
        baseURL: process.env.VUE_APP_BIOHUB_BASE_URL,
        contentType: 'application/json; charset=utf-8',
        headers: {
          'API-KEY': 'a9951b55-5212-46e7-ac8e-440005264df3'
        },
        params: {
          hubId: hubIdData
        }
      }).then((response) => {
        const updateEffort = response.data
        const extradataData = JSON.parse(updateEffort.extradata.data)

        if (extradataData.effortclosed === false) {
          extradataData.effortclosed = observationData.extradata.data.closedeffort
          updateEffort.endDate = observationData.endDate
          extradataData.endlatitude = observationData.extradata.data.endlatitude
          extradataData.endlongitude = observationData.extradata.data.endlongitude
          updateEffort.extradata.data = JSON.stringify(extradataData)
          axios({
            url: '/api/eventDTOs/PartialEventUpdate',
            method: 'put',
            baseURL: process.env.VUE_APP_BIOHUB_BASE_URL,
            contentType: 'application/json; charset=utf-8',
            headers: {
              'API-KEY': 'a9951b55-5212-46e7-ac8e-440005264df3',
              'X-AUTH-TOKEN': sessionIdData
            },
            data: updateEffort
          }).then(response => {
            resolve(response)
          }).catch(err => {
            reject(err)
          })
        } else {
          state.messageErrorEffort = true
          throw state.messageErrorEffort
        }
      }).catch(err => {
        reject(err)
      })
    })
  }
}

const mutations = {
  endEffort(state, value) {
    state.endEffort = value
  },
  hubIdEffort(state, value) {
    state.hubIdEffort = value
  },
  mmsiEffort(state, value) {
    state.mmsiEffort = value
  },
  initialEffort(state, value) {
    state.initialEffort = value
  },
  plateformeEffort(state, value) {
    state.plateformeEffort = value
  },
  nbPassengersEffort(state, value) {
    state.nbPassengersEffort = value
  },
  shipEffort(state, value) {
    state.shipEffort = value
  },
  partnerEffort(state, value) {
    state.partnerEffort = value
  },
  startDateEffort(state, value) {
    state.startDateEffort = value
  },
  startIdEffort(state, value) {
    state.startIdEffort = value
  },
  messageErrorEffort(state, value) {
    state.messageErrorEffort = value
  }
}

export default {
  state: state,
  getters,
  actions,
  mutations: mutations
}
