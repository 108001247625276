<template>
  <div>
    <h1 class="big-title" style="text-align: center" v-html="$t('welcome-title')"></h1>
    <EffortCourantSummary
      v-if="currentEffortData"
      :currentEffortData="currentEffortData"
    />
    <HomeButtonsContainer>
      <template v-slot:column1>
        <div>OBSERVATIONS CITOYENNES HORS LIGNE</div>
        <b-button
          @click="$router.push('/nouvelle-observation-citoyenne-hors-ligne')"
          variant="primary"
          >{{ $t("home-content-offline-citizen-button") }}
        </b-button>
      </template>
      <template v-slot:column2>
        <div>OBSERVATIONS MEMBRE HORS LIGNE</div>
        <div>
          <b-button
            v-if="currentEffortData"
            @click="$router.push('/fin-effort')"
            class="mb-2"
            variant="danger"
          >
            {{ $t("end-effort") }}
          </b-button>
          <b-button
            v-else
            @click="$router.push('/debut-effort')"
            class="mb-2"
            variant="danger"
          >
            {{ $t("start-effort") }}
          </b-button>
        </div>
        <div>
          <b-button
            @click="$router.push('/nouvelle-observation-membre-hors-ligne')"
            variant="primary"
            >{{ $t("home-content-offline-member-button") }}
          </b-button>
        </div>
      </template>
    </HomeButtonsContainer>

    <p style="text-align: center">
      {{ $t("home-content-offline-text-publish-when-connected") }}
    </p>
  </div>
</template>

<script>
import HomeButtonsContainer from "@/components/HomeButtonsContainer.vue";
import { getCurrentSession } from "@/storage/session-management";
import EffortCourantSummary from "@/components/EffortCourantSummary.vue";

// @ is an alias to /src
export default {
  name: "HomeContentOffline",
  components: { HomeButtonsContainer, EffortCourantSummary },
  data() {
    return {
      currentEffortData: null,
    };
  },
  async mounted() {
    this.currentEffortData = await getCurrentSession();
  },
  methods: {},
  computed: {},
};
</script>

<style scoped>
</style>
