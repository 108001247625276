import Vue from 'vue'
import Vuex from 'vuex'
import auth from './modules/auth.js'
import observation from './modules/observation.js'
import effort from './modules/effort.js'
import pwa from './modules/pwa.js'

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    auth,
    observation,
    effort,
    pwa
  }
})
