import store from "@/store"
import { db } from "@/storage/indexedDB";
import {
    getCurrentSession as getCurrentOnlineSession,
    publishNewObservationSession,
    updateObservationSession,
    getObservationSession as getOnlineObservationSession
} from "@/storage/slgoServices";
import { getCookie } from "tiny-cookie";


async function removeUnnecessaryEndedSessionFromOfflineDb() {
    if (!store.state.pwa.isOnline)
        return

    const sessions = await db.sessions.toArray()
    const openPublishedSession = sessions.find((session) => session.sessionObject.id && !session.sessionObject.endDate)

    if (!openPublishedSession)
        return

    const observations = await db.observations.toArray()
    const observation = observations.find(
        (observation) => observation.observationObject.offlineSessionId = openPublishedSession.id)

    if (observation)
        return

    const onlineSession = await getOnlineObservationSession(openPublishedSession.sessionObject.hubId)
    const extradataData = JSON.parse(onlineSession.extradata.data)

    if (!extradataData.effortclosed)
        return

    await db.sessions.delete(openPublishedSession.id)
}

async function getCurrentSession() {
    removeUnnecessaryEndedSessionFromOfflineDb()

    const sessions = await db.sessions.toArray()
    const openOfflineSession = sessions.find((session) => !session.sessionObject.endDate)
    let onlineSession = null
    if ((!openOfflineSession || !openOfflineSession.sessionObject.id) && store.state.pwa.isOnline) {
        onlineSession = await getCurrentOnlineSession(getCookie('sessionId'))
        if (onlineSession) return onlineSession
    } else if (openOfflineSession) {
        return await getOfflineSession(openOfflineSession.id)
    }

    return null

}

async function getOfflineSession(id) {
    const session = await db.sessions.get(parseInt(id))
    const data = session.sessionObject
    const extradataData = JSON.parse(data.extradata.data)
    return {
        offlineSessionId: session.id, id: session.sessionObject.id, startDate: data.date,
        hubId: session.sessionObject.hubId,
        plateformeSelected: extradataData.plateform,
        organizationCode: data.organizationCode, ...extradataData
    }
}

async function startObservationSession(session) {
    let out = {}
    if (store.state.pwa.isOnline) {
        out['onlineResult'] = await publishNewObservationSession(session, getCookie('sessionId'))
        session.id = out['onlineResult'].data.id
    }

    out['offlineResult'] = await db.sessions.add({
        timestamp: Date.now(),
        sessionObject: session
    });
}

async function endObservationSession(currentSessionData, endSessionData) {
    let out = {}

    if (currentSessionData.id && store.state.pwa.isOnline) {
        try {
            out['onlineResponse'] = await updateObservationSession(currentSessionData.hubId, endSessionData, getCookie('sessionId'))
        } catch (error) {
            console.log(error)
        }

        const offlineObservations = await db.observations.toArray()

        if (
            currentSessionData.offlineSessionId &&
            !offlineObservations.find(
                (observation) =>
                    observation.observationObject.offlineSessionId == currentSessionData.offlineSessionId
            )
        )
            out['offlineResult'] = await db.sessions.delete(currentSessionData.offlineSessionId);
    }

    if (currentSessionData.offlineSessionId) {

        const session = await db.sessions.get(currentSessionData.offlineSessionId)

        if (session) {
            const extradataData = JSON.parse(session.sessionObject.extradata.data)

            extradataData.effortclosed = endSessionData.extradata.data.closedeffort
            session.sessionObject.endDate = endSessionData.endDate
            extradataData.endlatitude = endSessionData.extradata.data.endlatitude
            extradataData.endlongitude = endSessionData.extradata.data.endlongitude
            session.sessionObject.extradata.data = JSON.stringify(extradataData)

            out['offlineResult'] = await db.sessions.update(currentSessionData.offlineSessionId, {
                timestamp: Date.now(),
                sessionObject: session.sessionObject,
            })
        }

    }
    return out
}

export { getCurrentSession, startObservationSession, endObservationSession, getOfflineSession }
