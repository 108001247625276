<template>
  <div role="tablist" class="observation-page">
    <ObservationFormSection
      id="section-whenwhere"
      :sectionTitle="$t('when-where')"
      accordionGroup="observationForm"
    >
      <ObservationFormWhenWhere
        @go-next-step="goToObservationStep"
        ref="formWhenWhere"
        :useOfflineMap="isOffline"
        :isMember="isMember"
        :staticData="currentEffortData"
        :hideVisibilityAndWind="Boolean(currentEffortData)"
      />
    </ObservationFormSection>
    <ObservationFormSection
      id="section-observation"
      :sectionTitle="$t('observation')"
      accordionGroup="observationForm"
      :disabled="observationSectionDisabled"
    >
      <ObservationFormObservation
        @go-next-step="goToReviewStep"
        ref="formObservation"
        :addPhotoFields="!isOffline"
        :showSpeciesPicture="true"
        :isMember="isMember"
        :plateformeSelected="plateformeSelected"
        :staticData="currentEffortData"
      />
    </ObservationFormSection>
    <b-modal
      id="modalRecap"
      scrollable
      no-close-on-backdrop
      hide-header-close
      :title="$t('title_summary')"
      v-model="showModalRecap"
      v-if="showModalRecap"
    >
      <div>
        <p>
          <b>{{ $t("information_check") }}</b>
        </p>
        <div v-if="memberSummary">
          <b>{{ $t("partner") }}</b> : {{ memberSummary }}
        </div>
        <div v-if="plateformeSummary">
          <b>{{ $t("plateforme") }}</b> : {{ plateformeSummary }}
        </div>
        <div v-if="formWhenWhereData().ship">
          <b>{{ $t("ship") }}</b> : {{ formWhenWhereData().ship }}
        </div>
        <div v-if="formWhenWhereData().nbPassengers">
          <b>{{ $t("nb_passengers") }}</b> :
          {{ formWhenWhereData().nbPassengers }}
        </div>
        <div v-if="formWhenWhereData().mmsi">
          <b>{{ $t("mmsi") }}</b> : {{ formWhenWhereData().mmsi }}
        </div>
        <div v-if="formWhenWhereData().initial">
          <b>{{ $t("initial") }}</b> : {{ formWhenWhereData().initial }}
        </div>
        <div>
          <b>Date</b> :
          {{ $refs.formWhenWhere.dateUTC }}
        </div>
        <div>
          <b>{{ $t("visibility") }}</b> :
          {{ visibiliteSummary }}
        </div>
        <div>
          <b>{{ $t("wind") }}</b> :
          {{ windSummary }}
        </div>
        <div>
          <b>{{ $t("latitude") }}</b> :
          {{ formWhenWhereData().latitude }}
        </div>
        <div>
          <b>{{ $t("longitude") }}</b> :
          {{ formWhenWhereData().longitude }}
        </div>
        <div>
          <vl-map
            :load-tiles-while-animating="true"
            :load-tiles-while-interacting="true"
            data-projection="EPSG:4326"
            style="height: 200px; border-style: groove"
          >
            <vl-view
              :zoom="6"
              :center="[
                parseFloat(formWhenWhereData().longitude),
                parseFloat(formWhenWhereData().latitude),
              ]"
              :rotation="0"
            >
            </vl-view>
            <vl-layer-vector :z-index="1">
              <vl-source-vector
                :features="[]"
                ident="the-source"
              ></vl-source-vector>
            </vl-layer-vector>
            <vl-feature>
              <vl-geom-point
                :coordinates="[
                  parseFloat(formWhenWhereData().longitude),
                  parseFloat(formWhenWhereData().latitude),
                ]"
              >
              </vl-geom-point>
              <vl-style-box>
                <vl-style-circle :radius="5">
                  <vl-style-fill color="black"></vl-style-fill>
                  <!-- <vl-style-stroke color="red"></vl-style-stroke> -->
                </vl-style-circle>
              </vl-style-box>
            </vl-feature>

            <vl-layer-tile :z-index="0" id="osm">
              <vl-source-osm
                v-if="isOffline"
                url="/map-tiles/{z}/{x}/{y}.jpg"
              ></vl-source-osm>
              <vl-source-osm v-else></vl-source-osm>
            </vl-layer-tile>
          </vl-map>
        </div>
        <div>
          <b>{{ $t("species") }}</b> : {{ speciesSummary }}
        </div>
        <div>
          <b>{{ $t("certainty-level") }}</b> : {{ certaintySummary }}
        </div>
        <div>
          <b>{{ $t("nb-individuals") }}</b> :
          {{ formObservationData().nbIndividuals }}
        </div>
        <div>
          <b>{{ $t("distance") }}</b> : {{ distanceSummary }}
        </div>
        <div v-if="formObservationData().durationOfObservation">
          <b>{{ $t("durationOfObservation") }}</b> :
          {{ formObservationData().durationOfObservation }}
        </div>
        <div v-if="formObservationData().incident">
          <b>{{ $t("incident") }}</b> :
          {{ formObservationData().incident }}
        </div>
        <div>
          <b>{{ $t("remarks") }}</b> :
          {{ formObservationData().remarks }}
        </div>
        <div v-if="formObservationData().photo">
          <b>Photo</b> :
          <img style="width: 100%" :src="formObservationData().photo" />
        </div>
      </div>
      <div slot="modal-footer">
        <b-btn
          data-cy="submit-button"
          variant="success"
          class="mt-2 mb-2"
          @click="handleClickSummitObservation"
        >
          {{ $t("send-observation") }}
        </b-btn>
        <b-btn
          class="mt-2 ml-2 mb-2 button-color"
          @click="handleClickAdjustObservation"
        >
          {{ $t("adjustObservation") }}
        </b-btn>
      </div>
    </b-modal>
    <div class="align-right">
      <b-button @click="$router.push('/')" class="mt-2 ml-2 mb-2 button-color">
        {{ $t("cancel") }}
      </b-button>
    </div>
  </div>
</template>

<script>
import ObservationFormSection from "@/components/ObservationFormSection";
import ObservationFormWhenWhere from "@/components/ObservationFormWhenWhere";
import ObservationFormObservation from "@/components/ObservationFormObservation";
import {
  Visibilite,
  Vent,
  Certitude,
  getConstantName,
  Distance,
  Plateforme,
} from "@/constants";
import { db } from "@/storage/indexedDB";
import { v4 as uuidv4 } from "uuid";
import {
  pushObservationToBiohub,
  pushImageToMediadatabase,
} from "@/storage/slgoServices";

import { getCookie } from "tiny-cookie";
import { getCurrentSession } from "@/storage/slgoServices";

export default {
  name: "ObservationForm",
  components: {
    ObservationFormSection,
    ObservationFormWhenWhere,
    ObservationFormObservation,
  },
  props: ["isOffline", "isMember", "currentEffortData"],
  data() {
    return {
      observationSectionDisabled: true,
      showModalRecap: false,
      plateformeSelected: null,
    };
  },
  async mounted() {
    this.$root.$on("bv::collapse::state", (collapseId, isJustShown) => {
      if (!["section-observation", "section-whenwhere"].includes(collapseId))
        return;

      if (isJustShown) {
        if (collapseId == "section-observation") {
          this.observationSectionDisabled = false;
        } else this.observationSectionDisabled = true;
      }
    });
  },
  methods: {
    createObservationObject() {
      return {
        guid: uuidv4(),
        whenWhere: {
          ...this.$refs.formWhenWhere.formWhenWhere,
          ...{ dateUTC: this.$refs.formWhenWhere.dateUTC },
        },
        observation: this.$refs.formObservation.formObservation,
      };
    },
    async handleClickSummitObservation() {
      this.$store.commit("isSendObservationInProgress", true);
      if (this.isOffline) {
        await this.storeObservationLocally();
      } else {
        await this.publishObservation();
      }
      this.$store.commit("isSendObservationInProgress", false);
    },
    async publishObservation() {
      const observationObject = this.createObservationObject();

      if (this.currentEffortData) {
        //assert current effort is still open
        const currentEffort = await getCurrentSession(getCookie("sessionId"));

        let errortText = null;

        if (!currentEffort) {
          errortText = this.$t("errorEffortObservation");
        } else if (currentEffort.id != this.currentEffortData.id) {
          errortText = "L'effort courant a changé essayez nouveau";
        }

        if (errortText) {
          await this.$router.push("/");

          this.$bvToast.toast(this.$t("errorEffortObservation"), {
            title: this.$t("error"),
            variant: "danger",
            solid: true,
          });
        }
        //link observation to effort
        observationObject.parentEventId = currentEffort.id;
      }

      if (observationObject.observation.photo) {
        try {
          const imageResponse = await pushImageToMediadatabase(
            observationObject.observation.photoFile
          );
          observationObject.mediaId = imageResponse.data.accessKey;
        } catch (error) {
          this.$bvToast.toast(this.$t("observationError"), {
            title: this.$t("error"),
            variant: "danger",
            solid: true,
          });
          return;
        }
      }

      try {
        await pushObservationToBiohub(
          observationObject.guid,
          observationObject,
          this.$store.getters.userName,
          getCookie("sessionId")
        );
      } catch (error) {
        this.$bvToast.toast(this.$t("observationError"), {
          title: this.$t("error"),
          variant: "danger",
          solid: true,
        });
        return;
      }
      await this.$router.push("/");

      this.$bvToast.toast(this.$t("observationSuccess"), {
        title: this.$t("success"),
        variant: "success",
        solid: true,
      });
    },
    async storeObservationLocally() {
      const observationObject = this.createObservationObject();
      if (this.currentEffortData) {
        observationObject.offlineSessionId =
          this.currentEffortData.offlineSessionId;

        observationObject.parentEventId = this.currentEffortData.id;
      }

      try {
        await db.observations.add({
          timestamp: Date.now(),
          observationObject: observationObject,
        });
      } catch (error) {
        this.$bvToast.toast(this.$t("observationError"), {
          title: this.$t("error"),
          variant: "danger",
          solid: true,
        });
        return;
      }
      await this.$router.push("/");

      this.$bvToast.toast(this.$t("observationStoredLocaly"), {
        title: this.$t("success"),
        variant: "success",
        solid: true,
      });
    },
    handleClickAdjustObservation() {
      this.showModalRecap = false;
      this.$root.$emit("bv::toggle::collapse", "section-whenwhere");
      this.$el.scrollIntoView({ behavior: "smooth", block: "start" });
    },
    goToObservationStep() {
      this.$refs.formWhenWhere.cleanFormData();
      this.plateformeSelected = this.formWhenWhereData().plateformeSelected;
      this.$root.$emit("bv::toggle::collapse", "section-observation");
      this.$el.scrollIntoView({ behavior: "smooth", block: "start" });
    },
    goToReviewStep() {
      this.$refs.formWhenWhere.cleanFormData();
      this.$refs.formObservation.cleanFormData();
      this.showModalRecap = true;
      this.$el.scrollIntoView({ behavior: "smooth", block: "start" });
    },
    formWhenWhereData() {
      return this.$refs.formWhenWhere.formWhenWhere;
    },
    formObservationData() {
      return this.$refs.formObservation.formObservation;
    },
  },
  computed: {
    plateformeSummary() {
      return getConstantName(
        Plateforme,
        this.$refs.formWhenWhere.formWhenWhere.plateformeSelected,
        "text",
        this.$i18n.locale
      );
    },
    windSummary() {
      return getConstantName(
        Vent,
        this.$refs.formWhenWhere.formWhenWhere.wind,
        "text",
        this.$i18n.locale
      );
    },
    visibiliteSummary() {
      return getConstantName(
        Visibilite,
        this.$refs.formWhenWhere.formWhenWhere.visibility,
        "text",
        this.$i18n.locale
      );
    },

    memberSummary() {
      if (!this.$refs.formWhenWhere.formWhenWhere.memberSelected) return null;

      return this.$refs.formWhenWhere.formWhenWhere.memberSelected[
        `name${this.$i18n.locale}`
      ];
    },
    speciesSummary() {
      return this.$refs.formObservation.formObservation.speciesSelected[
        `name${this.$i18n.locale}`
      ];
    },
    certaintySummary() {
      return getConstantName(
        Certitude,
        this.$refs.formObservation.formObservation.certaintySelected,
        "text",
        this.$i18n.locale
      );
    },
    distanceSummary() {
      return getConstantName(
        Distance,
        this.$refs.formObservation.formObservation.distanceSelected,
        "text",
        this.$i18n.locale
      );
    },
  },
};
</script>

<style scoped>
.observation-page {
  flex-grow: 1;
  flex-shrink: 0;
}

.align-right {
  display: flex;
  justify-content: flex-end;
}

.button-color {
  background-color: #2147af;
  border-color: #2147af;
}

@media screen and (min-width: 1140px) {
  .observation-page {
    width: 1140px;
    margin: 0 auto;
  }
}

@media screen and (max-width: 1139px) {
  .observation-page {
    width: 100%;
  }
}

@media screen and (min-width: 768px) {
  .observation-page {
    padding: 1.25rem;
  }
}

@media screen and (max-width: 767px) {
  .observation-page,
  .observation-page .card-body {
    padding: 0.5rem;
  }
}
</style>
