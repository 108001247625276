import { getCookie, removeCookie } from 'tiny-cookie'
import axios from 'axios'

const _events = require('events')
const eventEmitter = new _events.EventEmitter()

const EVENT_USER_DATA_CHANGED = 'user_data_changed'

const _URLS = {
  preprod: {
    userData: 'https://user-service.preprod.ogsl.ca/user/users/getUserInfo',
    loginPage: 'https://static.preprod.ogsl.ca/auth/index.html',
    logout: 'https://user-service.preprod.ogsl.ca/user/authentication/deleteSession'
  },
  production: {
    userData: 'https://user-service.prod.ogsl.ca/user/users/getUserInfo',
    loginPage: 'https://ogsl.ca/auth/index.html',
    logout: 'https://user-service.prod.ogsl.ca/user/authentication/deleteSession'
  }
}

let _environment = ''

const setEnvironment = function (env) {
  _environment = env
}

const getUserData = function () {
  if (!getCookie('sessionId')) return null

  axios({
    method: 'get',
    url: _URLS[_environment].userData,
    headers: { 'X-AUTH-TOKEN': getCookie('sessionId') }
  }).then((response) => {
    eventEmitter.emit(EVENT_USER_DATA_CHANGED, response.data)
  }).catch((error) => {
    if (error.response.status === 401) removeCookie('sessionId')
  })
}

let _isListening = false

const handleMessage = function (event) {
  if (event.source === window) return

  document.cookie = event.data.replace(/;Domain=.*;/, ';')
  getUserData()
  window.removeEventListener('message', handleMessage, false)
  _isListening = false
}

const openLoginWindow = function (local) {
  if (!_isListening) {
    window.addEventListener('message', handleMessage, false)
    _isListening = true
  }
  window.open(
    _URLS[_environment].loginPage + '?lg=' + local,
    'targetWindow',
    'toolbar=no,location=no,status=no,menubar=no,scrollbars=yes,resizable=yes,width=780,height=500'
  )
}
const logout = function () {
  return axios({
    method: 'get',
    url: _URLS[_environment].logout,
    headers: { 'X-AUTH-TOKEN': getCookie('sessionId') }
  }).then(() => {
    removeCookie('sessionId')
    eventEmitter.emit(EVENT_USER_DATA_CHANGED, null)
  })
}

export {
  setEnvironment,
  openLoginWindow,
  logout,
  getUserData,
  eventEmitter,
  EVENT_USER_DATA_CHANGED
}
