import axios from 'axios'


function convertObservationDataToBiohubFormat(guid, observationData, userName) {

    const out = {
        remarks: "",
        dateFormatCode: "minute",
        samplingProtocolCode: "visual",
        samplingEffort: "",
        dwcSamplingProtocol: "",
        extradata: {
            data: "",
            simplifiedGroups: [
                {
                    groupCode: "romm_saisie",
                },
            ],
        },
        simplifiedOccurrences: []
    }

    out.hubId = guid;
    if (observationData.parentEventId) out.parentEventId = observationData.parentEventId

    out.location = {
        latitude: parseFloat(observationData.whenWhere.latitude),
        longitude: parseFloat(observationData.whenWhere.longitude),
        isFixed: false,
    }
    out.date = observationData.whenWhere.dateUTC;

    const extradataData = {}

    if (observationData.offlineObservation) extradataData.offlineObservation = observationData.offlineObservation;
    if (userName) extradataData.userName = userName;
    if (observationData.whenWhere.nbPassengers) {
        out.samplingEffort = `There are ${observationData.whenWhere.nbPassengers} passengers`
        extradataData.nbPassengers = observationData.whenWhere.nbPassengers
    }
    if (observationData.whenWhere.ship) extradataData.ship = observationData.whenWhere.ship;
    if (observationData.whenWhere.mmsi) extradataData.mmsi = observationData.whenWhere.mmsi;
    if (observationData.whenWhere.organizationCode) out.organizationCode = observationData.whenWhere.organizationCode

    out.dwcSamplingProtocol = `On the platform ${observationData.whenWhere.plateformeSelected}`
    extradataData.plateform = observationData.whenWhere.plateformeSelected

    // if (observationData.observation.typeOfObservationSelected) {
    //     out.dwcSamplingProtocol = `${out.dwcSamplingProtocol} which is ${observationData.observation.typeOfObservationSelected}`
    //     extradataData.typeOfObservation = observationData.observation.typeOfObservationSelected
    // }

    if (observationData.whenWhere.initial)
        extradataData.initial = observationData.whenWhere.initial

    if (observationData.whenWhere.memberSelected) {
        out.organizationCode = observationData.whenWhere.memberSelected.value
        out.datasetCode = "ROMM-MM-OBS-WB"
    } else {
        out.datasetCode = "ROMM-MM-CRWD-WB"
    }

    if (observationData.observation.incident) out.remarks = observationData.observation.incident;
    out.extradata.data = JSON.stringify(extradataData)


    const occurenceExtradataData = {}

    occurenceExtradataData.plateform = observationData.whenWhere.plateformeSelected

    occurenceExtradataData.wind = observationData.whenWhere.wind
    occurenceExtradataData.visibility = observationData.whenWhere.visibility

    occurenceExtradataData.distance = observationData.observation.distanceSelected
    occurenceExtradataData.certainty = observationData.observation.certaintySelected
 
    const mainOccurence = {
        taxonCode: "",
        remarks: "",
        simplifiedMeasurements:
            [
                {
                    value: (1).toFixed(1),
                    measurementTypeCode: "presence"
                },
                {
                    value: observationData.observation.nbIndividuals,
                    measurementTypeCode: "individualCount"
                },
            ],
        extradata: {
            data: "",
        }
    }

    mainOccurence.taxonCode = observationData.observation.speciesSelected.value;
    mainOccurence.remarks = observationData.observation.remarks;
    if (observationData.mediaId) mainOccurence.occurrenceMedias = [{ mediaId: observationData.mediaId }]

    const durationOfObservationMeasurement = {
        value: observationData.observation.durationOfObservation,
        measurementTypeCode: "time",
        measurementUnitCode: "minute"
    }
    if (observationData.observation.durationOfObservation) mainOccurence.simplifiedMeasurements.push(durationOfObservationMeasurement)
    mainOccurence.extradata.data = JSON.stringify(occurenceExtradataData)
    out.simplifiedOccurrences.push(mainOccurence)

    return out
}

async function pushObservationToBiohub(guid, observationData, userName, userSessionIdData) {

    const biohubObservation = convertObservationDataToBiohubFormat(guid, observationData, userName)

    return await axios({
        url: '/api/eventDTOs',
        method: 'post',
        baseURL: process.env.VUE_APP_BIOHUB_BASE_URL,
        contentType: 'application/json; charset=utf-8',
        headers: {
            'API-KEY': 'a9951b55-5212-46e7-ac8e-440005264df3',
            'X-AUTH-TOKEN': userSessionIdData
        },
        data: biohubObservation
    })
}

async function pushImageToMediadatabase(file) {
    const formData = new FormData()
    formData.append('file', file)
    return axios({
        url: '/api/images',
        method: 'post',
        baseURL: process.env.VUE_APP_MEDIADATABASE_BASE_URL,
        headers: {
            'API-KEY': 'd4bea5c9-5cb4-40e2-a391-638c0ef1bfa2'
        },
        data: formData
    })
}

async function getCurrentSession(userSessionId) {
    if (!userSessionId) return null

    const response = await axios({
        url: '/api/eventDTOs/getEventDTOFromFilters',
        method: 'get',
        baseURL: process.env.VUE_APP_BIOHUB_BASE_URL,
        contentType: 'application/json; charset=utf-8',
        headers: {
            'API-KEY': 'a9951b55-5212-46e7-ac8e-440005264df3',
            'X-AUTH-TOKEN': userSessionId
        },
        params: {
            currentuser: true,
            parentonly: true,
            hasoccurrence: false,
            sortbyhubevent: true,
            max: 5,
            offset: 0
        }
    })

    for (const event of response.data) {
        const extradataData = JSON.parse(event.extradata.data)
        if (extradataData.effortclosed) continue

        return { id: event.id, hubId: event.hubId, startDate: event.startDate, plateformeSelected: extradataData.plateform, organizationCode: event.organizationCode, ...extradataData }
    }
    return null
}

async function getObservationSession(hubId) {
    const response = await axios({
        url: '/api/eventDTOs/getEventDTOFromHubId',
        method: 'get',
        baseURL: process.env.VUE_APP_BIOHUB_BASE_URL,
        contentType: 'application/json; charset=utf-8',
        headers: {
            'API-KEY': 'a9951b55-5212-46e7-ac8e-440005264df3'
        },
        params: {
            hubId: hubId
        }
    })

    return response.data
}

async function updateObservationSession(hubId, endSessionData, userSessionId) {
    const updateEffort = await getObservationSession(hubId, userSessionId)

    const extradataData = JSON.parse(updateEffort.extradata.data)

    if (extradataData.effortclosed) throw new Error("errorEffortEnd")

    extradataData.effortclosed = endSessionData.extradata.data.closedeffort
    updateEffort.endDate = endSessionData.endDate
    extradataData.endlatitude = endSessionData.extradata.data.endlatitude
    extradataData.endlongitude = endSessionData.extradata.data.endlongitude
    updateEffort.extradata.data = JSON.stringify(extradataData)

    return await axios({
        url: '/api/eventDTOs/PartialEventUpdate',
        method: 'put',
        baseURL: process.env.VUE_APP_BIOHUB_BASE_URL,
        contentType: 'application/json; charset=utf-8',
        headers: {
            'API-KEY': 'a9951b55-5212-46e7-ac8e-440005264df3',
            'X-AUTH-TOKEN': userSessionId
        },
        data: updateEffort
    })
}

async function publishNewObservationSession(startObservationSessionData, userSessionId) {
    const newSesssionExtradataData = JSON.parse(startObservationSessionData.extradata.data)
    if (newSesssionExtradataData.effortclosed === false) {

        const response = await axios({
            url: '/api/eventDTOs/getEventDTOFromFilters',
            method: 'get',
            baseURL: process.env.VUE_APP_BIOHUB_BASE_URL,
            contentType: 'application/json; charset=utf-8',
            headers: {
                'API-KEY': 'a9951b55-5212-46e7-ac8e-440005264df3',
                'X-AUTH-TOKEN': userSessionId
            },
            params: {
                currentuser: true,
                parentonly: true,
                hasoccurrence: false,
                sortbyhubevent: true,
                max: 5,
                offset: 0
            }
        })

        response.data.forEach(element => {
            const extradataData = JSON.parse(element.extradata.data)
            if (extradataData.effortclosed === false) {
                throw new Error("session-already-opened")
            }
        })
    }
    return axios({
        url: '/api/eventDTOs',
        method: 'post',
        baseURL: process.env.VUE_APP_BIOHUB_BASE_URL,
        contentType: 'application/json; charset=utf-8',
        headers: {
            'API-KEY': 'a9951b55-5212-46e7-ac8e-440005264df3',
            'X-AUTH-TOKEN': userSessionId
        },
        data: startObservationSessionData
    })
}


export {
    convertObservationDataToBiohubFormat, pushObservationToBiohub, pushImageToMediadatabase, getCurrentSession,
    updateObservationSession, publishNewObservationSession, getObservationSession
}