<template>
  <b-form>
    <label>
      {{ $t("species") }}
      <b-icon
        id="info-species-icon"
        icon="info-circle"
        variant="info"
        class="icon"
      ></b-icon>
    </label>
    <p
      id="textEspeceFormation"
      v-html="
        isMember
          ? $t('espece-formation-observateur')
          : $t('espece-formation-citoyen')
      "
    ></p>
    <b-form-group>
      <multiselect
        data-cy="species-multiselect"
        v-model="formObservation.speciesSelected"
        :options="especeOptions"
        group-values="libs"
        :group-label="categorySelection"
        :placeholder="$t('species')"
        track-by="value"
        :label="nameSelection"
        :show-labels="false"
        :searchable="false"
      >
      </multiselect>
      <div class="error" v-if="!$v.formObservation.speciesSelected.required">
        {{ $t("field-required") }}
      </div>
    </b-form-group>
    <b-row id="species_desc_img" v-if="formObservation.speciesSelected">
      <b-col md="6" v-if="showSpeciesPicture">
        <b-img
          :src="`${publicPath}assets/images/species/${formObservation.speciesSelected.image}`"
        ></b-img>
        <div id="copyright_photo">
          {{
            formObservation.speciesSelected[`copyright_photo${$i18n.locale}`]
          }}
        </div>
      </b-col>
      <b-col md="6">
        <div>
          <strong>{{ $t("scientific-name") }} : </strong>
        </div>
        <div>
          <i>{{
            formObservation.speciesSelected[`scientific_name${$i18n.locale}`]
          }}</i>
        </div>
        <div v-if="formObservation.speciesSelected[`arctique${$i18n.locale}`]">
          <strong>{{
            formObservation.speciesSelected[`arctique${$i18n.locale}`]
          }}</strong>
        </div>
        <div>
          <strong>{{ $t("physical-description") }} : </strong>
        </div>
        <div
          v-html="formObservation.speciesSelected[`description${$i18n.locale}`]"
        ></div>
      </b-col>
    </b-row>
    <b-tooltip
      triggers="click"
      placement="top"
      ref="tooltipSpecies"
      target="info-species-icon"
    >
      {{ $t("tooltipSpecies") }}
    </b-tooltip>
    <label>
      {{ $t("certainty-level") }}
      <b-icon
        id="info-certainty-icon"
        icon="info-circle"
        variant="info"
        class="icon"
      ></b-icon>
    </label>
    <b-form-group>
      <b-form-select
        id="input-certainty"
        v-model="formObservation.certaintySelected"
        :options="certitudeOptions"
        :text-field="textSelection"
        required
        class="mr-3 b-components-size"
      ></b-form-select>
      <div class="error" v-if="!$v.formObservation.certaintySelected.required">
        {{ $t("field-required") }}
      </div>
    </b-form-group>
    <b-tooltip
      triggers="click"
      ref="tooltipCertainty"
      target="info-certainty-icon"
    >
      {{ $t("tooltipCertainty") }}
    </b-tooltip>
    <label>
      {{ $t("nb-individuals") }}
      <b-icon
        id="info-nb-individuals-icon"
        icon="info-circle"
        variant="info"
        class="icon"
      ></b-icon>
    </label>
    <b-form-group>
      <b-form-input
        type="number"
        min="0"
        step="1"
        @keydown="filterKey"
        id="input-nb-individuals"
        class="mr-3 b-components-size"
        required
        v-model="formObservation.nbIndividuals"
      ></b-form-input>
      <div class="error" v-if="!$v.formObservation.nbIndividuals.required">
        {{ $t("field-required") }}
      </div>
      <div class="error" v-if="$v.formObservation.nbIndividuals.$invalid">
        {{ $t("nbIndividu-error") }}
      </div>
      <div class="error" v-if="!$v.formObservation.nbIndividuals.minValue">
        {{ $t("nbIndividuvalue-error") }}
      </div>
    </b-form-group>
    <b-tooltip
      triggers="click"
      ref="tooltipNbInd"
      target="info-nb-individuals-icon"
    >
      {{ $t("tooltipNbIndividuals") }}
    </b-tooltip>
    <label>
      {{ $t("distance") }}
      <b-icon
        id="info-distance-icon"
        icon="info-circle"
        variant="info"
        class="icon"
      ></b-icon>
    </label>
    <b-form-group>
      <b-form-select
        id="input-distance"
        v-model="formObservation.distanceSelected"
        :options="distanceOptions"
        :text-field="textSelection"
        class="mr-3 b-components-size"
      >
      </b-form-select>
      <div class="error" v-if="!$v.formObservation.distanceSelected.required">
        {{ $t("field-required") }}
      </div>
    </b-form-group>
    <b-tooltip
      triggers="click"
      ref="tooltipDistance"
      target="info-distance-icon"
    >
      {{ $t("tooltipDistance") }}
    </b-tooltip>
    <div v-if="isMemberAndCroisiereBaleine">
      <label>
        {{ $t("durationOfObservation") }}
        <b-icon
          id="info-durationOfObservation-icon"
          icon="info-circle"
          variant="info"
          class="icon"
        ></b-icon>
      </label>
      <b-form-group>
        <b-form-input
          type="number"
          min="0"
          step="1"
          @keydown="filterKey"
          id="input-durationOfObservation"
          class="mr-3 b-components-size"
          v-model="formObservation.durationOfObservation"
        ></b-form-input>
        <b-tooltip
          triggers="click"
          placement="top"
          ref="tooltipdurationOfObservation"
          target="info-durationOfObservation-icon"
        >
          {{ $t("tooltipDurationOfObservation") }}
        </b-tooltip>
        <div
          class="error"
          v-if="$v.formObservation.durationOfObservation.$invalid"
        >
          {{ $t("nbIndividu-error") }}
        </div>
      </b-form-group>
      <label>
        {{ $t("incident") }}
        <b-icon
          id="info-incident-icon"
          icon="info-circle"
          variant="info"
          class="icon"
        ></b-icon>
      </label>
      <b-form-group>
        <b-form-select
          id="input-incident"
          v-model="hasIncident"
          :options="[
            { value: true, text: $t('global-yes') },
            { value: false, text: $t('global-no') },
          ]"
          class="mr-3 b-components-size"
        ></b-form-select>
        <b-tooltip
          triggers="click"
          placement="top"
          ref="tooltipincident"
          target="info-incident-icon"
        >
          {{ $t("tooltipIncident") }}
        </b-tooltip>
      </b-form-group>
      <b-form-group v-if="hasIncident">
        <b-form-textarea
          id="input-incident-text"
          v-model="formObservation.incident"
          rows="3"
          max-rows="10"
          class="mr-3 b-components-size"
        >
        </b-form-textarea>
        <div class="error" v-if="$v.formObservation.incident.$invalid">
          {{ $t("incident-error") }}
        </div>
      </b-form-group>
    </div>
    <label>
      {{ $t("remarks") }}
      <b-icon
        id="info-remarks-icon"
        icon="info-circle"
        variant="info"
        class="icon"
      ></b-icon>
    </label>
    <b-form-group>
      <b-form-textarea
        id="input-remarks"
        v-model="formObservation.remarks"
        :placeholder="$t('remarks-placeholder')"
        rows="3"
        max-rows="10"
        class="mr-3 b-components-size"
      >
      </b-form-textarea>
    </b-form-group>
    <b-tooltip triggers="click" ref="tooltipRemarks" target="info-remarks-icon">
      {{ $t("tooltipRemarks") }}
    </b-tooltip>
    <ObservationFormObservationPhoto
      v-if="addPhotoFields"
      @photo-file-changed="
        (photoFile) => (this.formObservation.photoFile = photoFile)
      "
      @photo-data-changed="
        (photoData) => (this.formObservation.photo = photoData)
      "
    />

    <b-button
      data-cy="observation-next-button"
      v-b-modal.modalRecap
      @click="handleClickNext"
      :disabled="$v.$invalid"
      variant="primary"
      class="button-color"
      >{{ $t("next") }}</b-button
    >
  </b-form>
</template>

<script>
import {
  required,
  minValue,
  numeric,
  requiredIf,
} from "vuelidate/lib/validators";
import { Certitude, Distance, Especes } from "@/constants";
import ObservationFormObservationPhoto from "@/components/ObservationFormObservationPhoto";

// @ is an alias to /src
export default {
  name: "ObservationFormObservation",
  components: { ObservationFormObservationPhoto },
  props: {
    addPhotoFields: {
      type: Boolean,
      default: true,
    },
    showSpeciesPicture: {
      type: Boolean,
      default: true,
    },
    isMember: {
      type: Boolean,
      default: false,
    },
    plateformeSelected: {
      type: String,
    },
  },
  data() {
    return {
      publicPath: process.env.BASE_URL,
      distanceOptions: Distance,
      certitudeOptions: Certitude,
      especeOptions: Especes,
      hasIncident: false,
      formObservation: {
        photo: null,
        photoFile: null,
        speciesSelected: null,
        certaintySelected: null,
        nbIndividuals: null,
        incident: "",
        remarks: "",
        durationOfObservation: null,
        distanceSelected: "nd",
      },
    };
  },
  validations() {
    return {
      formObservation: {
        incident: {
          required: requiredIf(() => this.hasIncident),
        },

        durationOfObservation: {
          numeric,
          minValue: minValue(0),
        },
        distanceSelected: {
          required,
        },
        speciesSelected: {
          required,
        },
        nbIndividuals: {
          required,
          numeric,
          minValue: minValue(1),
        },
        certaintySelected: {
          required,
        },
      },
    };
  },
  methods: {
    cleanFormData() {
      if (!this.isMemberAndCroisiereBaleine) {
        this.formObservation.durationOfObservation = null;
        this.formObservation.incident = "";
      }
      if (!this.addPhotoFields) {
        this.photo = null;
        this.photoFile = null;
      }
      if (!this.hasIncident) {
        this.formObservation.incident = "";
      }
    },
    handleClickNext() {
      this.$emit("go-next-step");
    },
    filterKey(e) {
      const key = e.key;
      if (key === "." || key === "," || key === "-" || key === "+") {
        return e.preventDefault();
      }
      if (key === "e" || e.keyCode === 13) {
        return e.preventDefault();
      }
    },
  },
  computed: {
    isMemberAndCroisiereBaleine() {
      return this.plateformeSelected == "croisiere-baleine" && this.isMember;
    },
    categorySelection() {
      return `category${this.$i18n.locale}`;
    },
    nameSelection() {
      return `name${this.$i18n.locale}`;
    },
    textSelection() {
      return `text${this.$i18n.locale}`;
    },
  },
  watch: {
    isMemberAndCroisiereBaleine: function (newValue) {
      if (newValue) {
        this.formObservation.certaintySelected ||= 1;
      }
    },
  },
};
</script>

<style scoped>
.multiselect >>> .multiselect__option {
  padding-left: 30px;
}

.multiselect >>> .multiselect__option--disabled {
  padding-left: 12px;
  color: black !important;
  font-weight: bold;
}

.multiselect {
  width: 100%;
  display: inline-block;
  margin-right: 1rem !important;
}
.button-color {
  background-color: #2147af;
  border-color: #2147af;
}
.error {
  color: red;
}

#species_desc_img,
#preview_photo {
  width: 100%;
}

#species_desc_img img,
#preview_photo img {
  width: 100%;
}

#preview_photo p,
#textEspeceFormation {
  margin-bottom: 10px !important;
}
</style>
