<template>
  <div>
    <label>
      {{ $t("location") }}
      <b-icon
        id="info-location-icon"
        icon="info-circle"
        variant="info"
        class="icon"
      ></b-icon>
    </label>
    <b-row>
      <b-col md="6">
        <b-form-group id="input-group-location-choice">
          <b-form-radio-group
            id="btn-radios-1"
            v-model="locationChoiceValue"
            :options="locationChoiceOptions"
            button-variant="outline-primary"
            buttons
            name="radios-btn-default"
          >
          </b-form-radio-group>
          <b-button
            @click="getGeoLocation()"
            variant="primary"
            class="geolocation-button ml-3 mr-3 button-color"
            :disabled="!geolocationButtonEnabled"
            >{{
              geolocationButtonEnabled
                ? $t("locateMe")
                : "La localisation automatique n'est pas disponible"
            }}
            <b-icon id="geolocation-icon" icon="geo-alt"></b-icon
          ></b-button>
        </b-form-group>
        <div>
          <p id="locateInMapText" v-html="$t('locateInMap')"></p>
        </div>
        <b-form-group
          id="input-group-location-dd"
          v-if="locationChoiceValue == 'DD'"
        >
          <label>
            {{ $t("latitude") }}
          </label>
          <b-input-group>
            <b-form-input
              id="latitude"
              v-model="formWhenWhereLocation.latitude"
              :placeholder="$t('latitude')"
              required
              @update="setPointMap"
              @input="getDD2DMS(formWhenWhereLocation.latitude, 'lat')"
              type="number"
              min="0.0000000000000"
              max="90.0000000000000"
              step="0.0000000000001"
            >
            </b-form-input>
          </b-input-group>
          <label>
            {{ $t("longitude") }}
          </label>
          <b-input-group>
            <b-form-input
              id="longitude"
              v-model="formWhenWhereLocation.longitude"
              :placeholder="$t('longitude')"
              required
              @update="setPointMap"
              @input="getDD2DMS(formWhenWhereLocation.longitude, 'lon')"
              type="number"
              min="-180.0000000000000"
              max="0.0000000000000"
              step="0.0000000000001"
            >
            </b-form-input>
          </b-input-group>
          <div
            class="error"
            v-if="
              !$v.formWhenWhereLocation.latitude.required ||
              !$v.formWhenWhereLocation.longitude.required
            "
          >
            {{ $t("fields-required") }}
          </div>
        </b-form-group>

        <b-form-group
          id="input-group-location-dms"
          v-if="locationChoiceValue == 'DMS'"
        >
          <label>
            {{ $t("latitude") }}
          </label>
          <b-input-group>
            <b-form-radio-group
              id="btn-radios-2"
              v-model="directionNordSudValue"
              :options="directionNordSudOptions"
              button-variant="outline-info"
              buttons
              name="radios-btn-default"
              @change="inverseDirection(formWhenWhereLocation.latitude, 'lat')"
            >
            </b-form-radio-group>
            <b-form-input
              v-model="latitudeDegres"
              class="form-control sexagesimal"
              id="latitudeDegres"
              type="number"
              min="0"
              oninput="validity.valid||(value='');"
              @input="
                getDMS2DD(latitudeDegres, latitudeMinutes, latitudeSecondes)
              "
            ></b-form-input>
            <label for="latitudeDegres">&deg;</label>
            <b-form-input
              v-model="latitudeMinutes"
              class="form-control sexagesimal"
              id="latitudeMinutes"
              type="number"
              min="0"
              oninput="validity.valid||(value='');"
              @input="
                getDMS2DD(latitudeDegres, latitudeMinutes, latitudeSecondes)
              "
            ></b-form-input>
            <label for="latitudeMinutes">'</label>
            <b-form-input
              v-model="latitudeSecondes"
              class="form-control sexagesimalsec"
              id="latitudeSecondes"
              type="number"
              min="0"
              oninput="validity.valid||(value='');"
              @input="
                getDMS2DD(latitudeDegres, latitudeMinutes, latitudeSecondes)
              "
            ></b-form-input>
            <label for="latitudeSecondes">''</label>
          </b-input-group>
          <label>
            {{ $t("longitude") }}
          </label>
          <b-input-group>
            <b-form-radio-group
              id="btn-radios-3"
              v-model="directionEstOuestValue"
              :options="directionEstOuestOptions"
              button-variant="outline-info"
              buttons
              name="radios-btn-default"
              @change="inverseDirection(formWhenWhereLocation.longitude, 'lon')"
            >
            </b-form-radio-group>
            <b-form-input
              v-model="longitudeDegres"
              class="form-control sexagesimal"
              id="longitudeDegres"
              type="number"
              min="0"
              oninput="validity.valid||(value='');"
              @input="
                getDMS2DD(longitudeDegres, longitudeMinutes, longitudeSecondes)
              "
            ></b-form-input>
            <label for="longitudeDegres">&deg;</label>
            <b-form-input
              v-model="longitudeMinutes"
              class="form-control sexagesimal"
              id="longitudeMinutes"
              type="number"
              min="0"
              oninput="validity.valid||(value='');"
              @input="
                getDMS2DD(longitudeDegres, longitudeMinutes, longitudeSecondes)
              "
            ></b-form-input>
            <label for="longitudeMinutes">'</label>
            <b-form-input
              v-model="longitudeSecondes"
              class="form-control sexagesimalsec"
              id="longitudeSecondes"
              type="number"
              min="0"
              oninput="validity.valid||(value='');"
              @input="
                getDMS2DD(longitudeDegres, longitudeMinutes, longitudeSecondes)
              "
            ></b-form-input>
            <label for="longitudeSecondes">''</label>
          </b-input-group>
          <div
            class="error"
            v-if="
              !$v.formWhenWhereLocation.latitude.required ||
              !$v.formWhenWhereLocation.longitude.required
            "
          >
            {{ $t("fields-required") }}
          </div>
        </b-form-group>
        <b-tooltip
          triggers="click"
          ref="tooltipLocalisation"
          target="info-location-icon"
        >
          {{ $t("tooltipLocalisation") }}
        </b-tooltip>
        <div
          class="error"
          v-if="
            !$v.formWhenWhereLocation.latitude.maxValue ||
            !$v.formWhenWhereLocation.latitude.minValue
          "
        >
          {{ $t("latitude-error") }}
        </div>
        <div
          class="error"
          v-if="
            !$v.formWhenWhereLocation.longitude.maxValue ||
            !$v.formWhenWhereLocation.longitude.minValue
          "
        >
          {{ $t("longitude-error") }}
        </div>
      </b-col>
      <b-col md="6">
        <vl-map
          :load-tiles-while-animating="true"
          :load-tiles-while-interacting="true"
          data-projection="EPSG:4326"
          style="height: 400px; border-style: groove"
          @click="clickOnMap"
        >
          <vl-view :zoom="6" :center.sync="center"></vl-view>

          <vl-feature v-if="coordinatesPoint">
            <vl-geom-point :coordinates="coordinatesPoint"> </vl-geom-point>
            <vl-style-box>
              <vl-style-circle :radius="5">
                <vl-style-fill color="black"></vl-style-fill>
                <!-- <vl-style-stroke color="red"></vl-style-stroke> -->
              </vl-style-circle>
            </vl-style-box>
          </vl-feature>

          <vl-layer-tile :z-index="0" id="osm">
            <vl-source-osm
              v-if="useOfflineMap"
              url="/map-tiles/{z}/{x}/{y}.jpg"
            ></vl-source-osm>
            <vl-source-osm v-else></vl-source-osm>
          </vl-layer-tile>
        </vl-map>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { required, minValue, maxValue } from "vuelidate/lib/validators";

// @ is an alias to /src
export default {
  name: "ObservationFormWhenWhereRowLocation",
  components: {},
  props: ["useOfflineMap"],
  data() {
    return {
      locationChoiceValue: "DMS",
      formWhenWhereLocation: {
        latitude: "",
        longitude: "",
      },
      latitudeDegres: "",
      latitudeMinutes: "",
      latitudeSecondes: "",
      longitudeDegres: "",
      longitudeMinutes: "",
      longitudeSecondes: "",
      directionNordSudValue: "nord",
      directionEstOuestValue: "ouest",
      coordinatesPoint: "",
      center: [-65, 48],
      geolocationButtonEnabled: false,
    };
  },
  validations() {
    return {
      formWhenWhereLocation: {
        latitude: {
          required,
          maxValue: maxValue(90),
          minValue: minValue(0),
        },
        longitude: {
          required,
          maxValue: maxValue(0),
          minValue: minValue(-180),
        },
      },
    };
  },
  mounted() {
    navigator.geolocation.getCurrentPosition(
      () => {
        this.geolocationButtonEnabled = true;
      },
      null,
      { timeout: 10000 }
    );
  },
  methods: {
    clickOnMap(e) {
      this.formWhenWhereLocation.longitude = e.coordinate[0];
      this.formWhenWhereLocation.latitude = e.coordinate[1];
      this.coordinatesPoint = e.coordinate;
      this.getDD2DMS(e.coordinate[1], "lat");
      this.getDD2DMS(e.coordinate[0], "lon");
    },
    setPointMap() {
      if (
        this.formWhenWhereLocation.latitude !== "" &&
        this.formWhenWhereLocation.longitude !== ""
      ) {
        this.coordinatesPoint = [
          parseFloat(this.formWhenWhereLocation.longitude),
          parseFloat(this.formWhenWhereLocation.latitude),
        ];
        this.center = [
          parseFloat(this.formWhenWhereLocation.longitude),
          parseFloat(this.formWhenWhereLocation.latitude),
        ];
      } else {
        this.coordinatesPoint = "";
      }
    },
    getGeoLocation() {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          this.formWhenWhereLocation.longitude =
            position.coords.longitude.toFixed(13);
          this.formWhenWhereLocation.latitude =
            position.coords.latitude.toFixed(13);
          this.coordinatesPoint = [
            parseFloat(this.formWhenWhereLocation.longitude),
            parseFloat(this.formWhenWhereLocation.latitude),
          ];
          this.getDD2DMS(this.formWhenWhereLocation.latitude, "lat");
          this.getDD2DMS(this.formWhenWhereLocation.longitude, "lon");
          this.center = [
            parseFloat(this.formWhenWhereLocation.longitude),
            parseFloat(this.formWhenWhereLocation.latitude),
          ];
        },
        () => {
          this.geolocationButtonEnabled = false;
          this.$bvToast.toast(
            "Impossible d'obtenir la position automatiquement",
            {
              title: "Attention",
              variant: "warning",
              solid: true,
            }
          );
        },
        { timeout: 10000 }
      );
    },
    inverseDirection(dms, type) {
      if (
        this.formWhenWhereLocation.latitude !== "" &&
        this.formWhenWhereLocation.longitude !== ""
      ) {
        if (type === "lat") {
          this.formWhenWhereLocation.latitude = dms * -1;
          this.coordinatesPoint = [
            parseFloat(this.formWhenWhereLocation.longitude),
            parseFloat(this.formWhenWhereLocation.latitude),
          ];
          this.center = [
            parseFloat(this.formWhenWhereLocation.longitude),
            parseFloat(this.formWhenWhereLocation.latitude),
          ];
        } else if (type === "lon") {
          this.formWhenWhereLocation.longitude = dms * -1;
          this.coordinatesPoint = [
            parseFloat(this.formWhenWhereLocation.longitude),
            parseFloat(this.formWhenWhereLocation.latitude),
          ];
          this.center = [
            parseFloat(this.formWhenWhereLocation.longitude),
            parseFloat(this.formWhenWhereLocation.latitude),
          ];
        }
      }
    },
    getDD2DMS(dms, type) {
      let sign = 1;
      const Abs = Math.abs(Math.round(dms * 1000000));
      let degree, minutes, secounds, direction;
      if (dms < 0) {
        sign = -1;
      }
      // Math.round is used to eliminate the small error caused by rounding in the computer:
      // e.g. 0.2 is not the same as 0.20000000000284
      // Error checks
      if (type === "lat" && Abs > 90 * 1000000) {
        // alert(" Degrees Latitude must be in the range of -90. to 90. ");
        return false;
      } else if (type === "lon" && Abs > 180 * 1000000) {
        // alert(" Degrees Longitude must be in the range of -180 to 180. ");
        return false;
      }

      degree = Math.floor(Abs / 1000000);
      minutes = Math.floor((Abs / 1000000 - degree) * 60);
      secounds = (
        (Math.floor(((Abs / 1000000 - degree) * 60 - minutes) * 100000) * 60) /
        100000
      ).toFixed();
      degree = degree * sign;
      if (type === "lat") direction = degree < 0 ? "sud" : "nord";
      if (type === "lon") direction = degree < 0 ? "ouest" : "est";
      // else return value
      if (type === "lat") {
        this.directionNordSudValue = direction;
        this.latitudeDegres = degree * sign;
        this.latitudeMinutes = minutes;
        this.latitudeSecondes = secounds;
      }
      if (type === "lon") {
        this.directionEstOuestValue = direction;
        this.longitudeDegres = degree * sign;
        this.longitudeMinutes = minutes;
        this.longitudeSecondes = secounds;
      }
    },
    getDMS2DD(degree, minutes, seconds) {
      if (
        this.latitudeDegres !== "" &&
        this.latitudeMinutes !== "" &&
        this.latitudeSecondes !== ""
      ) {
        degree = parseFloat(this.latitudeDegres);
        minutes = parseFloat(this.latitudeMinutes);
        seconds = parseFloat(this.latitudeSecondes);
        const direction = this.directionNordSudValue;
        let dd = degree + minutes / 60 + seconds / (60 * 60);
        // alert(dd)
        if (direction === "sud" || direction === "ouest") {
          dd = -1 * dd;
        } // Don't do anything for N or E
        this.formWhenWhereLocation.latitude = dd;
      }
      if (
        this.longitudeDegres !== "" &&
        this.longitudeMinutes !== "" &&
        this.longitudeSecondes !== ""
      ) {
        degree = parseFloat(this.longitudeDegres);
        minutes = parseFloat(this.longitudeMinutes);
        seconds = parseFloat(this.longitudeSecondes);
        const direction = this.directionEstOuestValue;
        let dd = degree + minutes / 60 + seconds / (60 * 60);
        // alert(dd)
        if (direction === "sud" || direction === "ouest") {
          dd = -1 * dd;
        } // Don't do anything for N or E
        this.formWhenWhereLocation.longitude = dd;
      }

      this.setPointMap();
    },
  },
  computed: {
    directionNordSudOptions() {
      return [
        { text: this.$i18n.t("direction-choice1"), value: "nord" },
        { text: this.$i18n.t("direction-choice2"), value: "sud" },
      ];
    },
    directionEstOuestOptions() {
      return [
        { text: this.$i18n.t("direction-choice3"), value: "est" },
        { text: this.$i18n.t("direction-choice4"), value: "ouest" },
      ];
    },
    locationChoiceOptions() {
      return [
        { text: this.$t("location-choice1"), value: "DMS" },
        { text: this.$t("location-choice2"), value: "DD" },
      ];
    },
  },
  watch: {
    formWhenWhereLocation: {
      handler: function (newValue) {
        this.$emit("location-changed", newValue.latitude, newValue.longitude);
      },
      deep: true,
    },
  },
};
</script>

<style scoped>
#locateInMapText {
  margin-bottom: 5px !important;
}

#input-group-localisation {
  display: flex;
}
#btn-radios-2,
#btn-radios-3 {
  padding-right: 5px;
}
#btn-radios-1,
.geolocation-button {
  padding-bottom: 5px;
}
.geolocation-button {
  vertical-align: inherit;
}

.button-color {
  background-color: #2147af;
  border-color: #2147af;
}
.error {
  color: red;
}

@media screen and (max-width: 446px) {
  #btn-radios-1 {
    display: flex;
  }

  .geolocation-button {
    width: 100%;
    margin-left: 0 !important;
  }
}

@media screen and (max-width: 990px) {
  .geolocation-button {
    width: 100%;
    margin-left: 0 !important;
  }

  #btn-radios-2,
  #btn-radios-3 {
    padding-right: 0px;
    padding-bottom: 5px;
    width: 100%;
  }

  #btn-radios-2 label.btn.btn-outline-info.active,
  #btn-radios-3 label.btn.btn-outline-info {
    width: 50% !important;
  }
}

@media screen and (min-width: 991px) {
  .geolocation-button {
    width: 439.28px;
    margin-left: 0 !important;
  }
}

@media screen and (max-width: 880px) and (min-width: 768px) {
  #btn-radios-1 {
    display: flex;
  }

  .geolocation-button {
    width: 100%;
    margin-left: 0 !important;
  }
}

@media screen and (max-width: 767px) and (min-width: 446px) {
  #btn-radios-1 {
    display: flex;
  }
}
</style>
